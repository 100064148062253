import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard, Marketing, SingleCardBook } from './layouts';
import appInfo from './app-info';
import {
  LoginForm,
  ResetPasswordForm,
  ChangePasswordForm,
  ChangePasswordForm2,
  CreateAccountForm,
  ConfirmAccountForm,
  MarketingNavbar
} from './components';

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path='/login'
        element={
          <SingleCard title={appInfo.title} logo="/logo512.png">
            <LoginForm />
          </SingleCard>
        }
      />
      <Route
        path='/create-account'
        element={
          <SingleCard title="Sign Up">
            <CreateAccountForm />
          </SingleCard>
        }
      />

      <Route
        path='/confirm-account'
        element={
          <SingleCard title="Confirm Account">
            <ConfirmAccountForm />
          </SingleCard>
        }
      />

      <Route
        path='/reset-password'
        element={
          <SingleCard
            title="Reset Password"
            description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      />
      <Route
        path='/change-password'
        element={
          <SingleCard title="Change Password">
            <ChangePasswordForm />
          </SingleCard>
        }
      />
      <Route
        path='/change-password2'
        element={
          <SingleCard title="Change Password">
            <ChangePasswordForm2 />
          </SingleCard>
        }
      />
      <Route
        path='/about/:id'
        element={
          <Marketing title="GetCarRide.com">
          </Marketing>
        }
      />
      <Route
        path='/about/*'
        element={
          <Marketing title="GetCarRide.com">
          </Marketing>
        }
      />
      <Route
        path='/book/:id/:appointmentId'
        element={
          <SingleCardBook/>
        }
      />
      <Route
        path='/book/:id'
        element={
          <SingleCardBook/>
        }
      />
      <Route
        path='/book/*'
        element={
          <SingleCardBook/>
        }
      />
      <Route path='*' element={<Navigate to={'/about'} />}></Route>

      {/*<Route
        path='/legal/tos'
        element={<SingleCardWide> <TOSPage/> </SingleCardWide>}
      />
      <Route
        path='/legal/pp'
        element={<SingleCardWide> <PPPage/> </SingleCardWide>}
      />*/}
    </Routes>

  );
}
