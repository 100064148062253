import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'devextreme/data/odata/store';
import FileUploader from 'devextreme-react/file-uploader';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  SearchPanel,
  Editing,
  Button as DGButton,
  Lookup,
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import {
  Form,
  Label,
  SimpleItem,
  EmptyItem,
  GroupItem,
  ButtonItem,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';
import { getResources, updateResource, deleteResource } from '../../api/api'
import { addPlusOnePrefix } from '../../utils/miscUtil'
import { uploadFileChunk } from '../../api/s3'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

export default function Drivers() {
  const { user, refreshSession } = useAuth();
  const [customersList, setCustomersList] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const fileExtensions = ['.jpg', '.jpeg', '.png'];
  const dataGridRef = useRef(null);

  useEffect(() => {
    if (user.signInUserSession) {
      getResources(user, onGetResourcesSuccess, onError);
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);


  const onError = useCallback((error) => {
    console.log('error occured')
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onSaveSuccess = useCallback((data) => {
    setCurrentCustomer(null);
    setCustomersList(structuredClone(customersList))
  }, [customersList])

  const onGetResourcesSuccess = useCallback((data) => {
    let lst = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].resource_type === 'customer') {
        lst.push(data[i])
      }
    }
    setCustomersList(lst);
  }, [])


  const insertRow = e => {
    const obj = {}
    obj.resource_id = crypto.randomUUID()
    obj.resource_details = e.data.resource_details
    obj.resource_type = 'customer'

    const modifiedPhoneNo = addPlusOnePrefix(obj.resource_details.phone)
    if(modifiedPhoneNo) {
      obj.resource_details.phone = modifiedPhoneNo
    }

    updateResource(user, obj, onSaveSuccess, onError);
  }

  const updateRow = e => {
    const modifiedPhoneNo = addPlusOnePrefix(e.data.resource_details.phone)
    if(modifiedPhoneNo) {
      e.data.resource_details.phone = modifiedPhoneNo
    }

    updateResource(user, e.data, onSaveSuccess, onError);
  }

  const removeRow = e => {
    deleteResource(user, e.key, () => setCurrentCustomer(null), onError)
  }

  const phoneEditorOptions = { mask: '+0 (000) 000-0000' }

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center content-block">
        <h2 className={''}>My Customers</h2>
        <Button
          type="default"
          text="Add Customer"
          onClick={() => {
            dataGridRef.current?.instance.addRow()
          }}
        />
      </div>

      <DataGrid
        ref={dataGridRef}
        className={'dx-card wide-card'}
        dataSource={customersList}
        showBorders={false}
        focusedRowEnabled={false}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onRowInserted={insertRow}
        onRowUpdated={updateRow}
        onRowRemoved={removeRow}
      >

        <Editing
          mode="row"
          allowUpdating={true}
          // allowDeleting={true} - requested by the customer
          // allowAdding={true}
        />

        <Paging defaultPageSize={50} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        <SearchPanel
          visible={true}
          width="250"
          placeholder="Search..."
        />

        <Column
          dataField={'resource_details.name'}
          caption={'Name'}
          hidingPriority={3}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={'resource_details.email'}
          caption={'Email'}
          hidingPriority={1}
        />
        <Column
          dataField={'resource_details.phone'}
          caption={'Phone'}
          hidingPriority={0}
          editorOptions={phoneEditorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField={'resource_details.notes'}
          editorType="dxTextArea"
          caption={'Internal notes'}
          hidingPriority={0}
        />
      </DataGrid>

    </React.Fragment>
  )
}
