import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { changePasswordAuth } from '../../api/auth';
import { useAuth } from '../../contexts/auth';

export default function ChangePasswordForm2() {
  const navigate = useNavigate();
  const { signInNewAccount } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ password: '' });

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { password } = formData.current;
    setLoading(true);

    try {
      const result = await changePasswordAuth(password);
      if (result.isOk) {
        const result2 = await signInNewAccount();
        if (result2.isOk) {
          setLoading(false);
          navigate('/home');
        } else {
          setLoading(false);
          notify(result.message, 'error', 2000);
        }
      } else {
        setLoading(false);
        notify(result.message, 'error', 2000);
      }
    } catch(error) {
        notify(error, 'error', 2000);
        setLoading(false);
    }

  }, [navigate, signInNewAccount]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Continue'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' };
