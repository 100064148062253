import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/auth';
import { getResources, getAppointments, inviteDriversToAppointment } from '../../api/api'
import {
    DataGrid, Editing, Column, Sorting, Button
} from 'devextreme-react/data-grid';
// import { Button } from 'devextreme-react/button';
import devices from 'devextreme/core/devices';
import notify from 'devextreme/ui/notify';

const isPhone = devices.current().deviceType === 'phone'


const Tasks = (props) => {
    const { user, refreshSession } = useAuth();
    const [pendingAppts, setPendingAppts] = useState([])
    const [pendingAirportRoundtripAppts, setPendingAirportRoundtripAppts] = useState([])
    const [noVehicleAppts, setNoVehicleAppts] = useState([])
    const [noDriverAppts, setNoDriverAppts] = useState([])
    const [availableAppts, setAvailableAppts] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()


    useEffect(() => {
        if (user.signInUserSession) {
          getResources(user, onGetResourcesSuccess, err => console.log(err));
        }
      }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

    const onGetResourcesSuccess = (r) => {
      console.log(r);
        getAppointments(user, r, onGetAppointmentsSuccess, err => console.log(err));
    }

    const onGetAppointmentsSuccess = useCallback( (data, resources) => {
        data = data.filter(item => new Date(item.pickupDate) > new Date()) // exclude past appointmnts
        data = data.filter(item => item.status !== 'canceled' && item.status !== 'rejected') // exclude canceled and rejected appointments
        for (let i = 0; i < resources.length; i++) {
          if (resources[i].resource_type === 'customer') {
            for (let j = 0; j<data.length; j++ ){
              if (data[j].customer_id) {
                if (data[j].customer_id === resources[i].resource_id) {
                  data[j].customer_data = resources[i]['resource_details'];
                }
              }
            }
          }
        }
        console.log(data);
        const availableRides = data.filter(item => item.status === 'available')
        if (props.showAirportRoundTripTasks) {
          setPendingAppts(data.filter(item => ((item.status === 'pending') && (!item.ride_details.notes?.includes("Airport roundtrip")))))
          setPendingAirportRoundtripAppts(data.filter(item => ((item.status === 'pending') && item.ride_details.notes?.includes("Airport roundtrip"))))
        } else {
          setPendingAppts(data.filter(item => (item.status === 'pending')))
        }
        const noVehicleAppts = data.filter(item => item.vehicle_id === '00000000-0000-0000-0000-000000000000' && item.status !== 'pending')
        const noDriverAppts = data.filter(item => !item.driver_id && item.status !== 'pending')
        setNoVehicleAppts(noVehicleAppts)
        setNoDriverAppts(noDriverAppts)
        setAvailableAppts(availableRides)
        setLoading(false)
    }, [props])

    const navigateToScheduler = useCallback( apptId => {
        navigate('/schedule?apptId='+apptId)
    }, [])


    const renderDataGrid = useCallback( (data, driver=false) => {
        return <>
            <DataGrid
                dataSource={data}
                readOnly={true}
                // onRowClick={navigateToScheduler}
                showColumnHeaders={false}
                showSortIndexes={true}
                allowColumnResizing={true}
                columnHidingEnabled={true}
                width='100%'
                columnAutoWidth={true}
            >
                <Sorting mode="single" />
                <Column
                    dataField="pickupDate"
                    caption="Start Date"
                    dataType="datetime"
                    defaultSortOrder="asc"
                    visibleIndex={0}
                    width="180px"
                />
                <Column
                    dataField="ride_details.pickup_address"
                    caption="Pickup Address"
                    visibleIndex={1}
                    hidingPriority={3}
                    width="250px"
                />
                <Column
                    dataField="ride_details.end_address"
                    caption="Dropoff Address"
                    visibleIndex={2}
                    hidingPriority={2}
                    width="250px"
                />
                <Column
                    dataField="customer_data.name"
                    caption="Customer"
                    visibleIndex={3}
                    hidingPriority={1}
                    width="250px"
                />
                <Column
                    type="buttons"
                    alignment="right"
                >
                    <Button
                        hint="Invite drivers"
                        icon={'fa fa-share fs-6 me-2'}
                        visible={driver}
                        onClick={ e => {
                            inviteDriversToAppointment(user, e.row.key.appointment_id,
                                data => { notify("Invitation sent", 'success', 3000); },
                                err => {
                                    notify("Something went wrong. Please refresh and try again.", 'error', 3000);
                                    console.log(err);
                                }
                            )
                        }}
                    >
                        {/* { !isPhone && <span className='fw-medium mx-1'>INVITE</span> } */}
                    </Button>
                    <Button
                        hint="Manage appointment"
                        icon="edit"
                        onClick={ e => navigateToScheduler(e.row.key.appointment_id) }
                    >
                        {/* { isPhone ? <i class="fas fa-pen mx-1"></i> : <span className='fw-medium mx-1'>MANAGE</span> } */}
                    </Button>

                </Column>

        </DataGrid>
        </>
    }, [])

    if(!loading) {
        return (
            <div className="content-block mt-5">
                <h2>Tasks</h2>
                <h3 className="fs-6 mt-4">Pending Rides</h3>
                <p>It requires your attention because it is submitted. You need to mark it as available in order for the customer to be able to proceed</p>
                { renderDataGrid(pendingAppts) }

                {props.showAirportRoundTripTasks && <>
                <h3 className="fs-6 mt-4">Airport Round Trip Pending Rides</h3>
                { renderDataGrid(pendingAirportRoundtripAppts) }
                </>
                }
                <h3 className="fs-6 mt-4">Missing Vehicle</h3>
                { renderDataGrid(noVehicleAppts) }

                <h3 className="fs-6 mt-4">Missing Driver</h3>
                { renderDataGrid(noDriverAppts, true) }



                <h3 className="fs-6 mt-4">Unconfirmed Rides</h3>
                { renderDataGrid(availableAppts) }
            </div>
        )
    } else {
        return <div className="content-block mt-5"><h2>Tasks (loading...)</h2></div>
    }
}


export default Tasks
