import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import Form, { Item, EmailRule, RequiredRule, RangeRule} from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { ProgressBar } from 'devextreme-react/progress-bar';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { getPublicAppointmentById, updatePublicAppointmentById, appointmentStatusValues, getPricesForAppointment } from "../../api/api";
import { removeSeconds } from "../../utils/miscUtil";
import { fetchRoutes, TRACKING_ID } from "../../api/googleApi";
import { customizeNewDate, addPlusOnePrefix } from "../../utils/miscUtil";
import { confirm } from "devextreme/ui/dialog"
import { LoadIndicator } from 'devextreme-react/load-indicator';
import RideQuestionnaire from '../ride-questionnaire/RideQuestionnaire';
import './BookRide.scss'
import ReactGA from 'react-ga4';


export default function BookRide(props) {
  const [step, setStep] = useState(0)
  const [flight_type, setFlightType] = useState(null)
  const [flight_round_trip_step, setFlightRoundTripStep] = useState(0)
  const [formData, setFormData] = useState({})
  const [prices, setPrices] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [sessionToken, setSessionToken] = useState();
  const [directionsService, setDirectionsService] = useState(null);
  const [predictionResults] = useState([])
  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState()
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const params = useParams()
  const map = useMap();
  const places = useMapsLibrary('places');
  const routes = useMapsLibrary('routes');
  const formRef = useRef()
  const form_ride_full_departure_time_flight_null_ref = useRef()
  const form_ride_full_arrival_time_flight_null_ref = useRef()
  const form_ride_full_departure_time_flight_ref = useRef()
  const form_ride_full_arrival_time_flight_ref = useRef()
  const form_ride_full_roundtrip_flight_null_ref = useRef()
  const nextBtnRef = useRef()

  const steps = ["personal_info", "ride_questionnaire", "ride_details", "quote", "summary", "payment"]
  const minCalendarTime = customizeNewDate(2)

  const ownerId = props.profileData?.owner_id;
  const isDriver = searchParams.get('driver')
  let appointmentId = params.appointmentId

  const pending = appointmentStatusValues.find(element => element.id==='pending')
  const available = appointmentStatusValues.find(element => element.id==='available')
  const rejected = appointmentStatusValues.find(element => element.id==='rejected')
  const canceled = appointmentStatusValues.find(element => element.id==='canceled')
  const paid = appointmentStatusValues.find(element => element.id==='payed')
  const unpaid = appointmentStatusValues.find(element => element.id==='unpayed')
  const completed = appointmentStatusValues.find(element => element.id==='completed')

  const cashPricesEnabled = props.profileData?.scheduling_settings.cash_prices_enabled

  useEffect( () => {
    if(props.profileData && appointmentId) {
      getPublicAppointmentById(
        props.profileData['owner_id'],
        appointmentId,
        data => {
          data.startDate = new Date(data.startDate)
          data.pickupDate = new Date(data.pickupDate)
          data.endDate = new Date(data.endDate)
          if (data.appointment_type === 'ride_full_roundtrip') {
            data.destinationDate = new Date(data.destinationDate)
            data.returnDate = new Date(data.returnDate)
          }

          console.log('data: ', data)
          if(!data) return
          setFormData(data)

          if(data?.status === pending.id) {
            setStep("summary")
          }
          if(data.status === available.id) {
            setStep("payment")
            getPricesForAppointment(data.owner_id, data, (data)=>{
              console.log(data);
              if (data.card_price || data.cash_price) {
                setPrices(data);
                return;
              }
            },
            (err)=>{
              console.log('err: ', err);
              setPrices(null);
            })
          }
          if(data.status === paid.id) {
            setStep(paid.id)
          }
          if(data.status === unpaid.id) {
            setStep(unpaid.id)
          }
          if(data.status === rejected.id) {
            setStep(rejected.id)
          }
          if(data.status === canceled.id) {
            setStep(canceled.id)
          }
          if(data.status === completed.id) {
            setStep(completed.id)
          }
        },
        err => {
          console.log('err: ', 'error step')
          setStep('error')
        }
      )
    }
  }, [props.profileData, appointmentId])

  useEffect(() => {
    if (!places || !routes) return;
    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());
    setDirectionsService(new routes.DirectionsService());
  }, [map, places, routes]);

  useEffect(() => {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({ hitType: "pageview", page: "/book", title: "Book a Ride" });
  }, [])

  useEffect( () => {
    handleGAaction(step)
  }, [step])

  const handleGAaction = useCallback(step => {
      if(step !== 0){
        // console.log('ReactGA.event: ', step.replace('_', ' '))
        ReactGA.event({
            category: 'Booking Step',
            action: 'Change',
            label: step,
        });
      }
  }, [step])


  const fetchPredictions = useCallback(
    async (inputValue) => {
      if (!autocompleteService || !inputValue) {
        while (predictionResults.length > 0) {
          predictionResults.pop();
        }
        return;
      }
      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);

      while (predictionResults.length > 0) {
        predictionResults.pop();
      }
      for (let i = 0; i < response.predictions.length; i++) {
        predictionResults.push(response.predictions[i]);
      }
    },
    [autocompleteService, sessionToken]
  );

  const saveBooking = (status=pending.id) => {
    if ((formData.appointment_type === "ride_full_roundtrip") && (flight_type)) {
      console.log(formData);
    } else {
      setLoadIndicatorVisible(true)
      formData.status = status;
      if (flight_round_trip_step !== 0) {
        if (!(formData['ride_details']['notes'])) {
          formData['ride_details']['notes'] = ""
        }
        formData['ride_details']['notes'] = formData['ride_details']['notes'] + "; Airport roundtrip booking"
      }

      console.log(formData);
      let apptId = null;
      formData.owner_id = ownerId
      if (formData.appointment_id) {
        apptId = formData.appointment_id
      }
      if (appointmentId) {
        apptId = appointmentId
      }
      if (apptId === null) {
        apptId = crypto.randomUUID()
      }
      formData.appointment_id = apptId
      formData.pricing={}
      if(prices){
        formData.pricing.quoted_card_price = prices.card_price
        formData.pricing.quoted_cash_price = prices.cash_price
      }
      formData.customer_data.phone = addPlusOnePrefix(formData.customer_data.phone)
      updatePublicAppointmentById(
        ownerId,
        formData.appointment_id,
        formData,
        (data)=>{
          if(status === pending.id) {
            setStep("summary")
          }
          if(status === canceled.id) {
            setStep(canceled.id)
          }
          if(status === completed.id) {
            setStep(completed.id)
          }
          if(!appointmentId) {
            navigate(`/book/${props.profileData?.company_profile}/${data.appointment_id}`)
          }
          setLoadIndicatorVisible(false)
        },
        (err)=>{
          setStep('error');
          console.log('err: ', err)
          setLoadIndicatorVisible(false)
        }
      )
    }

  }

  const onApptDeletion = () => {
    saveBooking('canceled')
  }

  const handleformValidation = useCallback( () => {
    let result = false
    if(step === "personal_info") {
      result = formRef.current?.instance.validate()
    }

    if (step === "ride_details")  {
      if (flight_type) {
        if (formData.appointment_type === "ride_full_arrival_time") {
          result = form_ride_full_arrival_time_flight_ref.current?.instance.validate()
        }
        if (formData.appointment_type === "ride_full_departure_time") {
          result = form_ride_full_departure_time_flight_ref.current?.instance.validate()
        }
      } else {
        if (formData.appointment_type === "ride_full_arrival_time") {
          result = form_ride_full_arrival_time_flight_null_ref.current?.instance.validate()
        }
        if (formData.appointment_type === "ride_full_departure_time") {
          result = form_ride_full_departure_time_flight_null_ref.current?.instance.validate()
        }
        if (formData.appointment_type === "ride_full_roundtrip") {
          result = form_ride_full_roundtrip_flight_null_ref.current?.instance.validate()
        }
      }

    }
    if (step === "quote") {
      nextStep();
      return
    }
    if(result.isValid){
      nextStep()
    }
  }, [step, formRef, flight_type, form_ride_full_arrival_time_flight_ref, form_ride_full_departure_time_flight_ref, form_ride_full_arrival_time_flight_null_ref, form_ride_full_departure_time_flight_null_ref])

  const nextStep = () => {
    if (step === "personal_info") {
      setStep("ride_questionnaire");
      return;
    }

    if(step === "ride_details") {
      const distanceVal = formRef.current.instance.option().formData.ride_details.dropoff_lenght
      const maxServiceRadius = props.profileData.scheduling_settings.max_service_radius
      setPrices(null);
      if(distanceVal < maxServiceRadius) {
        getPricesForAppointment(ownerId, formData, (data)=>{
          console.log(data);
          if (data.card_price || data.cash_price) {
            setPrices(data);
            setStep("quote");
            return;
          }
          setPrices(null);
          saveBooking()
        },
        (err)=>{
          console.log('err: ', err);
          setPrices(null);
          saveBooking()
        })
        return;
      }
    }

    if(step === "quote") {
      return saveBooking()
    }
    setStep(prevState => prevState+1);
  }

  const onRideTypeChanged = (data) => {
    if ((data.ride_type === "ride_full_roundtrip") && (data.flight_type !== null)) {
      formData.appointment_type = "ride_full_arrival_time"
      formData.flight_type = data.flight_type
      setFlightRoundTripStep(1);
      setFlightType(data.flight_type)
      setStep("ride_details")
    } else {
      formData.appointment_type = data.ride_type
      formData.flight_type = data.flight_type
      setStep("ride_details")
      setFlightRoundTripStep(0);
      setFlightType(data.flight_type)
    }
  }


  const renderButtons = useCallback( (disabled=false) => {
    if(step === "personal_info" || step ==="ride_details" || step ==="quote" || step ==='distanceExceeded'){
      return <div className="mt-5">
        <div className="fields-container d-flex justify-content-between">
          <Button
            type="normal"
            text="back"
            width="33%"
            height="40px"
            stylingMode="text"
            icon="spinprev"
            onClick={ () => {
              if(step === 'distanceExceeded'){
                return setStep("ride_details") // need to reenable fields on step 2
              }
              if(step === 'ride_details'){
                return setStep("ride_questionnaire") // need to reenable fields on step 2
              }
              if(step === 'quote'){
                return setStep("ride_details") // need to reenable fields on step 2
              }
              /*if(step !== minMaxSteps[0]) {
                setStep(prevState => prevState-1)
              }*/
            }}
            disabled={step === "personal_info" || loadIndicatorVisible}
          />
          <Button
            ref={nextBtnRef}
            type="default"
            width="33%"
            height="40px"
            onClick={handleformValidation}
            disabled={loadIndicatorVisible}
          >
            <LoadIndicator
              height={20}
              width={20}
              className="button-indicator text-success me-2"
              visible={loadIndicatorVisible}
            />
            <span className="dx-button-text">next</span>
          </Button>
        </div>
      </div>
    }

  }, [step, formRef, loadIndicatorVisible])

  const renderProgressBar = useCallback(() => {
    return (
      <ProgressBar
        id="book-ride-progress-bar"
        width="100%"
        minValue={0}
        maxValue={steps.length}
        className="mb-5"
        value={(steps.indexOf(step)+1)*100/steps.length}
        statusFormat={ () => {
          let template = ''
          switch(step){
            case "personal_info":
              template = 'Personal Info'
              break
            case "ride_questionnaire":
              template = 'Ride Type'
              break
            case "ride_details":
              template = 'Ride Details'
              break
            case "quote":
              template = 'Quote'
              break
            case "summary":
              template = 'Summary'
              break
            case "payment":
              template = 'Payment'
              break
            case paid.id:
              template = 'Done'
              break
            case unpaid.id:
              template = 'Done'
              break
          }
          //template += ` (${step} of ${minMaxSteps[1]})`
          return  template
        }}
      />
    )
  }, [step])

  const renderErrorStep = useCallback( () => {
    return (
      <div className="text-center">
        <i className="dx-icon-errorcircle fs-2 me-2 text-danger"></i><span className="text-danger fs-2">Error</span>
        <h4 className=""><span className="fs-5 text-muted">Something went wrong! Try again.</span><br></br>

        </h4>
        <Button
          icon=""
          type="normal"
          text="Try again"
          stylingMode="outlined"
          height="40px"
          className="mt-4"
          onClick={() => {
            window.location.reload(false)
          }}
        />
      </div>
    )
  }, [])

  const renderAmountDue = useCallback( () => {
    formData && console.log("does this runnnn", formData)
    if(formData.pricing?.amount_due || formData.pricing?.amount_due === 0 && isDriver) {
      return <p className="fs-6">Amount due: <b>${formData.pricing?.amount_due}</b></p>
    }
  }, [formData])

  const renderMarkRideCompletedBttn = useCallback( () => {
    if(isDriver) {
      return (
        <Button
          type="default"
          stylingMode="outlined"
          className="mt-2"
          disabled={loadIndicatorVisible}
          onClick={() => {
            let result = confirm("<i>Are you sure?</i>", "Confirm");
            result.then(dialogResult => {
              if(dialogResult) {
                saveBooking(completed.id)
              }
            }
            )
          }
        }
        >
          <LoadIndicator
              height={20}
              width={20}
              className="button-indicator text-success me-2"
              visible={loadIndicatorVisible}
          />
          <span className="dx-button-text">Mark Ride as Completed</span>
        </Button>
      )
    }
  }, [loadIndicatorVisible, formData])


  const renderFilledData = useCallback( () => {
    if(formData) {
      return <>
        <hr></hr>
          <div>
            { formData.customer_data && formData.customer_data.name && <>
              <p className="mb-0 text-muted">Name:</p> <p className="mb-3 fw-bold">{formData.customer_data.name}</p>
            </>}
            { formData.ride_details?.passengers && <>
              <p className="mb-0 text-muted">Passengers:</p> <p className="mb-3 fw-bold">{formData.ride_details?.passengers}</p>
            </>}
            { formData.ride_details?.notes && <>
              <p className="mb-0 text-muted">Special Requests:</p> <p className="mb-3 fw-bold">{formData.ride_details.notes}</p>
            </>}
            { formData.ride_details?.pickup_address && <>
              <p className="mb-0 text-muted">Pick-Up Address:</p> <p className="mb-3 fw-bold">{formData.ride_details.pickup_address}</p>
            </>}
            { formData.pickupDate && <>
              <p className="mb-0 text-muted">Pick-Up Date and Time:</p> <p className="mb-3 fw-bold">{removeSeconds(formData.pickupDate.toLocaleString())}</p>
            </>}
            { formData.ride_details?.destination_address && <>
              <p className="mb-0 text-muted">Destination Address:</p> <p className="mb-3 fw-bold">{formData.ride_details.destination_address}</p>
            </>}
            { formData.destinationDate && <>
              <p className="mb-0 text-muted">Destination Arrival Date and Time:</p> <p className="mb-3 fw-bold">{removeSeconds(formData.destinationDate.toLocaleString())}</p>
            </>}
            { formData.returnDate && <>
              <p className="mb-0 text-muted">Return Date and Time:</p> <p className="mb-3 fw-bold">{removeSeconds(formData.returnDate.toLocaleString())}</p>
            </>}
            { formData.ride_details?.end_address && <>
              <p className="mb-0 text-muted">Drop-off Address:</p> <p className="mb-3 fw-bold">{formData.ride_details.end_address}</p>
            </>}
            { formData.endDate && <>
              <p className="mb-0 text-muted">Drop-Off Date and TIme:</p> <p className="mb-3 fw-bold">{removeSeconds(formData.endDate.toLocaleString())}</p>
            </>}
          </div>
      </>
    }
  }, [formData])


  if(step===0){
    return <>
      <div>
        {props.profileData && <div className={'d-flex flex-column align-items-center'}>
          { props.profileData && props.profileData.image &&
            <div className={'form-avatar m-0'}>
              { props.profileData && props.profileData.image ?
                <img
                  alt={''}
                  src={props.profileData.image}
                /> :
                <img
                  alt={''}
                  src={props.profileData.image}
                />
              }
            </div>
          }

          <h4 className="my-3 text-center">{props.profileData.company_name?props.profileData.company_name:"Add Company Name"}</h4>

          <Button
            type="default"
            text={props.profileData.cta_text?props.profileData.cta_text:"Book a ride"}
            onClick={() => {
              if (props.profileData.scheduling_settings.cancelation_policy_text) {
                setStep("cancelation_policy")
              } else {
                setStep("personal_info")
              }
            }}
          />

        </div>}
      </div>

  </>
  }

  if(step==="cancelation_policy"){
    return <>
      <h5 className="mb-4 text-center">Cancelation Policy</h5>
      <p className="cancelation_policy dx-theme-accent-as-text-color">{props.profileData.scheduling_settings.cancelation_policy_text}</p>
      <Button
        icon=""
        type="default"
        text="Agree & Continue"
        height="40px"
        className="mt-4"
        onClick={() => {
          setStep("personal_info")
        }}
      />
    </>
  }

  return <>
    { renderProgressBar() }

    {step==="ride_questionnaire" &&
    <RideQuestionnaire
      airport_mode={props.profileData.scheduling_settings.advanced_airport_questions}
      round_trips_enabled={props.profileData.scheduling_settings.same_day_round_trips_enabled}
      onValueChanged={onRideTypeChanged}
      onBack={()=>{setStep("personal_info")}}
    />
    }
    {step==="quote" && prices && (
        <div>
          { prices['card_price'] && prices['cash_price'] && (prices['card_price'] !== prices['cash_price']) && cashPricesEnabled &&
            <div>
              <h5>Your ride will cost <strong>${prices['card_price']}</strong> when you
                pay by card and <strong>${prices['cash_price']}</strong> if you pay in person.
                Please note that there might be additional charges for significant waiting periods.
              </h5>
            </div>
          }
          { prices['card_price'] && prices['cash_price'] && (prices['card_price'] === prices['cash_price']) &&
            <div>
              <h5>Your ride will cost <strong>${prices['card_price']}</strong>. You can pay
                either by card or in person.
                Please note that there might be additional charges for significant waiting periods.
              </h5>
            </div>
          }
          { (
              (prices['card_price'] && !cashPricesEnabled) ||
              (prices['card_price'] && !prices['cash_price'] && cashPricesEnabled)
            ) &&
              <div>
                <h5>Your ride will cost <strong>${prices['card_price']}</strong>.
                  Please note that there might be additional charges for significant waiting periods.
                </h5>
              </div>
          }
          { (!prices['card_price']) && (prices['cash_price']) && cashPricesEnabled &&
            <div>
              <h5>Your ride will cost <strong>${prices['cash_price']}</strong>.
                Please note that there might be additional charges for significant waiting periods.
              </h5>
            </div>
          }
        </div>
      )
    }
    <Form
      id="form"
      ref={formRef}
      labelMode="floating"
      formData={formData}
    >

      {/* WHERE */}
      <Item
        dataField="customer_data.name"
        editorType="dxTextBox"
        label={{text: 'Name'}}
        isRequired={true}
        visible={step==="personal_info"}
        accessKey="customer_data.name"
        editorOptions={{
          accessKey: "customer_data.name",
        }}
      />
      <Item
        dataField="customer_data.email"
        editorType="dxTextBox"
        label={{text: 'Email'}}
        isRequired={true}
        visible={step==="personal_info"}
        id="customer_data.email"
        editorOptions={{
          activeStateEnabled: true,
        }}
      >
        <RequiredRule message="Email is required" />
        <EmailRule message="Email is invalid" />
      </Item>
      <Item
        dataField="customer_data.phone"
        editorType="dxTextBox"
        label={{text: 'Customer Phone'}}
        editorOptions={{
          mask: '+1 (X00) 000-0000',
          valueChangeEvent: 'keyup',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidMessage: 'The phone must have a correct USA phone format',
        }}
        isRequired={true}
        visible={step==="personal_info"}
        id="customer_data.phone"
      />
      <Item
        dataField="ride_details.passengers"
        editorType="dxNumberBox"
        editorOptions={{
          step: 0,
          min: 0,
          max: 50
        }}
        label={{text: 'Adult Passengers'}}
        visible={step==="personal_info"}
      >
        <RangeRule min={0} max={50} />
      </Item>
      <Item
        dataField="ride_details.childern"
        editorType="dxNumberBox"
        editorOptions={{
          step: 0,
          min: 0,
          max: 50
        }}
        label={{text: 'Children under 9'}}
        visible={step==="personal_info"}
      >
        <RangeRule min={0} max={50} />
      </Item>
      <Item
        dataField="ride_details.luggages"
        editorType="dxNumberBox"
        min={0}
        max={50}
        editorOptions={{
          step: 0,
          min: 0,
          max: 50
        }}
        label={{text: 'Luggage'}}
        visible={step==="personal_info"}
      >
        <RangeRule min={0} max={50} />
      </Item>
    </Form>

    {step === 'ride_details' &&
    formData.appointment_type === "ride_full_departure_time" &&
    flight_type === null &&
    <Form
      id="form_ride_full_departure_time_flight_null"
      ref={form_ride_full_departure_time_flight_null_ref}
      labelMode="floating"
      formData={formData}
    >
      <Item
        dataField="pickupDate"
        editorType="dxDateBox"
        label={{text: 'Pick-up Date and Time'}}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: false,
          onValueChanged() {
            fetchRoutes(form_ride_full_departure_time_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="endDate"
        editorType="dxDateBox"
        label= {{ text: 'Drop-off Date and Time' }}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: true,
        }}
      />
      <Item
        dataField="ride_details.pickup_address"
        editorType="dxAutocomplete"
        label={{ text: 'Pick-up Address' }}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_departure_time_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
        isRequired={true}
      />
      <Item
        dataField="ride_details.end_address"
        editorType="dxAutocomplete"
        label={{ text: 'Drop-off Address' }}
        isRequired={true}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_departure_time_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="ride_details.notes"
        editorType="dxTextArea"
        editorOptions={{
          placeholder: 'Add things like flight number, gate code, details about large items, etc.',
          disabled: !(formData.appointment_type)
        }}
        label={{text: 'Special Requests'}}
      />
    </Form>}

    {step === 'ride_details' &&
    formData.appointment_type === "ride_full_arrival_time" &&
    flight_type === null &&
    <Form
      id="form_ride_full_arrival_time_flight_null"
      ref={form_ride_full_arrival_time_flight_null_ref}
      labelMode="floating"
      formData={formData}
    >
      <Item
        dataField="pickupDate"
        editorType="dxDateBox"
        label={{text: 'Pick-up Date and Time'}}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: true,
        }}
      />
      <Item
        dataField="endDate"
        editorType="dxDateBox"
        label= {{ text: 'Drop-off Date and Time' }}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: false,
          onValueChanged() {
            fetchRoutes(form_ride_full_arrival_time_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="ride_details.pickup_address"
        editorType="dxAutocomplete"
        label={{ text: 'Pick-up Address' }}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_arrival_time_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
        isRequired={true}
      />
      <Item
        dataField="ride_details.end_address"
        editorType="dxAutocomplete"
        label={{ text: 'Drop-off Address' }}
        isRequired={true}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_arrival_time_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="ride_details.notes"
        editorType="dxTextArea"
        editorOptions={{
          placeholder: 'Add things like flight number, gate code, details about large items, etc.',
          disabled: !(formData.appointment_type)
        }}
        label={{text: 'Special Requests'}}
      />
    </Form>}

    {step === 'ride_details' &&
    formData.appointment_type === "ride_full_departure_time" &&
    flight_type !== null &&
    <Form
      id="form_ride_full_departure_time_flight_null"
      ref={form_ride_full_departure_time_flight_ref}
      labelMode="floating"
      formData={formData}
    >
      <Item
        dataField="pickupDate"
        editorType="dxDateBox"
        label={{text: 'Flight Arrival Date and Time'}}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: false,
          onValueChanged() {
            fetchRoutes(form_ride_full_departure_time_flight_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="endDate"
        editorType="dxDateBox"
        label= {{ text: 'Drop-off Date and Time' }}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: true,
        }}
      />
      <Item
        dataField="ride_details.pickup_address"
        editorType="dxAutocomplete"
        label={{ text: 'Airport Address' }}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_departure_time_flight_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
        isRequired={true}
      />
      <Item
        dataField="ride_details.end_address"
        editorType="dxAutocomplete"
        label={{ text: 'Drop-off Address' }}
        isRequired={true}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_departure_time_flight_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="ride_details.notes"
        editorType="dxTextArea"
        editorOptions={{
          placeholder: 'Add things like flight number, gate code, details about large items, etc.',
          disabled: !(formData.appointment_type)
        }}
        label={{text: 'Add things like flight number, etc.'}}
      />
    </Form>}

    {((step === 'ride_details' &&
    formData.appointment_type === "ride_full_arrival_time" &&
    flight_type !== null ) ||
    (step === 'ride_details' &&
    formData.appointment_type === "ride_full_roundtrip" &&
    flight_type !== null && flight_round_trip_step === 1)) &&
    <Form
      id="form_ride_full_arrival_time_flight"
      ref={form_ride_full_arrival_time_flight_ref}
      labelMode="floating"
      formData={formData}
    >
      <Item
        dataField="pickupDate"
        editorType="dxDateBox"
        label={{text: 'Pick-up Date and Time'}}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: true,
        }}
      />
      <Item
        dataField="flightDate"
        editorType="dxDateBox"
        label= {{ text: 'Flight Departure Date and Time' }}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: false,
          onValueChanged(args) {
            if (flight_type === 'domestic') {
              form_ride_full_arrival_time_flight_ref.current.instance.updateData('endDate', new Date(args.value - 120 * 60 * 1000));
            }
            if (flight_type === 'international') {
              form_ride_full_arrival_time_flight_ref.current.instance.updateData('endDate', new Date(args.value - 180 * 60 * 1000));
            }
            fetchRoutes(form_ride_full_arrival_time_flight_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="ride_details.pickup_address"
        editorType="dxAutocomplete"
        label={{ text: 'Pick-up Address' }}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_arrival_time_flight_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
        isRequired={true}
      />
      <Item
        dataField="ride_details.end_address"
        editorType="dxAutocomplete"
        label={{ text: 'Airport Address' }}
        isRequired={true}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_arrival_time_flight_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="ride_details.notes"
        editorType="dxTextArea"
        editorOptions={{
          placeholder: 'Add things like flight number, gate code, details about large items, etc.',
          disabled: !(formData.appointment_type)
        }}
        label={{text: 'Add things like flight number, etc.'}}
      />
    </Form>}

    {step === 'ride_details' &&
    formData.appointment_type === "ride_full_roundtrip" &&
    flight_type === null &&
    <Form
      id="form_ride_full_roundtrip_flight_null"
      ref={form_ride_full_roundtrip_flight_null_ref}
      labelMode="floating"
      formData={formData}
    >
      <Item
        dataField="pickupDate"
        editorType="dxDateBox"
        label={{text: 'Pick-up Date and Time'}}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: true,
        }}
      />
      <Item
        dataField="ride_details.pickup_address"
        editorType="dxAutocomplete"
        label={{ text: 'Pick-up Address' }}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged(data) {
            form_ride_full_roundtrip_flight_null_ref.current.instance.updateData('ride_details.end_address', data.value);
            fetchRoutes(form_ride_full_roundtrip_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
        isRequired={true}
      />
      <Item
        dataField="destinationDate"
        editorType="dxDateBox"
        label= {{ text: 'Destination Arrival Date and Time' }}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: false,
          onValueChanged() {
            fetchRoutes(form_ride_full_roundtrip_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="ride_details.destination_address"
        editorType="dxAutocomplete"
        label={{ text: 'Destination Address' }}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_roundtrip_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
        isRequired={true}
      />
      <Item
        dataField="returnDate"
        editorType="dxDateBox"
        label= {{ text: 'Return Date and Time' }}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: false,
          onValueChanged() {
            fetchRoutes(form_ride_full_roundtrip_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="endDate"
        editorType="dxDateBox"
        label= {{ text: 'Drop-off Date and Time' }}
        isRequired={true}
        editorOptions={{
          min: minCalendarTime,
          dateOutOfRangeMessage: 'Cannot add entries in the past, or close to current time',
          type: 'datetime',
          disabled: true,
        }}
      />
      <Item
        dataField="ride_details.end_address"
        editorType="dxAutocomplete"
        label={{ text: 'Drop-off Address' }}
        isRequired={true}
        editorOptions={{
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: predictionResults,
          disabled: !(formData.appointment_type),
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value);
          },
          onValueChanged() {
            fetchRoutes(form_ride_full_roundtrip_flight_null_ref.current.instance, props.profileData?.scheduling_settings, directionsService);
          }
        }}
      />
      <Item
        dataField="ride_details.notes"
        editorType="dxTextArea"
        editorOptions={{
          placeholder: 'Add things like gate code, details about large items, etc.',
          disabled: !(formData.appointment_type)
        }}
        label={{text: 'Special Requests'}}
      />
    </Form>}

    { step === "summary" && <>
        <div className="text-center">
          <h4 className="">
            {flight_type ?
              <>
              {(flight_round_trip_step === 1) && <span className="fs-5 text-muted">Your booking to airport is</span>}
              {(flight_round_trip_step === 2) && <span className="fs-5 text-muted">Your return trip booking is</span>}
              </>
              :
              <span className="fs-5 text-muted">{ isDriver ? 'The' : 'Your' } booking is</span>
            }

            <br></br>
            <span className="">{pending.text}</span>
          </h4>
          { !isDriver && <p>Upon availability confirmations you will be directed to the payment step.</p> }
        </div>
        { renderFilledData() }

        { !isDriver && !formData.driver_id && (

            <div className="fields-container d-flex justify-content-between mt-4">
              <Button
                icon="close"
                type="danger"
                text="Cancel ride"
                stylingMode="outlined"
                height="40px"
                onClick={() => {
                    let result = confirm("<i>Are you sure?</i>", "Confirm deletion");
                    result.then(dialogResult => {
                      if(dialogResult) {
                        onApptDeletion()
                      }
                    }
                    )
                  }
                }
                disabled={loadIndicatorVisible}
              />
              {(flight_round_trip_step === 1) ?
                <Button
                  icon="car"
                  type="default"
                  text={"Book return ride"}
                  height="40px"
                  onClick={() => {
                    formData.appointment_id = null
                    navigate(`/book/${props.profileData?.company_profile}`)
                    formData.appointment_type = "ride_full_departure_time"
                    formData.pickupDate = null
                    formData.endDate = null
                    let tmp = formData.ride_details.pickup_address
                    formData.ride_details.pickup_address = formData.ride_details.end_address
                    formData.ride_details.end_address = tmp
                    formData.ride_details.notes = ""
                    let d = Object.assign({}, formData);
                    setFormData(d);
                    setStep("ride_details");
                    setFlightRoundTripStep(2);
                  }}
                  disabled={loadIndicatorVisible}
                />:
                <Button
                  icon="car"
                  type="normal"
                  text={"Book another ride"}
                  height="40px"
                  onClick={() => {
                    let newBookingUrl
                    if(params.id) {
                      const url = window.location.href.split(params.id)
                      newBookingUrl = url[0]+params.id
                      console.log('new b url', newBookingUrl)
                      window.location.replace(newBookingUrl)
                    }
                    window.location.reload(false)
                  }}
                  disabled={loadIndicatorVisible}
                />
              }
            </div>
          )
        }
      </>
    }

    { step === "payment" && <>
          <div className="text-center">
            <h4 className=""><span className="fs-5 text-muted">{ isDriver ? 'The' : 'Your' } booking is</span><br></br>
              <span className="text-success"><i className="fa fa-check fs-2 me-2 text-success"></i>{available.text}</span>
            </h4>

            { prices && (prices['card_payment_link'] || prices['cash_payment_link']) && !isDriver && <p>Proceed to the payment page by clicking the button below</p>}
            {!prices && !isDriver && <p>The company will reach out to you to clarify payment methods</p>}
          </div>
          { renderFilledData() }
          {prices && prices['card_price'] && prices['card_payment_link'] && !isDriver && <Button
            type="default"
            text={"Pay $" + prices['card_price'] + " by Credit or Debit Card"}
            className="mt-5"
            height="40px"
            width="100%"
            onClick={() => window.open(`${prices['card_payment_link']}?client_reference_id=${appointmentId}`, '_self') }
          />}
          {prices && (prices['card_price'] && prices['card_payment_link']) && prices['cash_price'] && !isDriver && cashPricesEnabled &&
            <p className="mt-3 mb-0 text-center">or</p>
          }
          { prices && prices['cash_price'] && prices['cash_payment_link'] && !isDriver && cashPricesEnabled &&
            <Button
              type="default"
              text={"Confirm and pay $" + prices['cash_price'] + " in person"}
              stylingMode="outlined"
              width="100%"
              height="40px"
              className="mt-3 mx-auto"
              onClick={() => window.open(`${prices['cash_payment_link']}?client_reference_id=${appointmentId}`, '_self') }
            />
          }
          { prices && prices['cash_price'] && (!prices['cash_payment_link']) &&
            <p className="dx-button-text dx-theme-accent-as-text-color mt-1 text-center">{"$" + prices['cash_price'] + " is due in person"}</p>
          }
          { !isDriver && !formData.driver_id && <Button
            className="mt-3"
            icon="close"
            type="danger"
            text="Cancel ride"
            stylingMode="text"
            height="40px"
            onClick={() => {
                let result = confirm("<i>Are you sure?</i>", "Confirm deletion");
                result.then(dialogResult => {
                  if(dialogResult) {
                    onApptDeletion()
                  }
                }
                )
              }
            }
            disabled={loadIndicatorVisible}
          />}
        </>
    }

    { step === paid.id && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">{ isDriver ? 'The' : 'Your' } booking is</span><br></br>
            <span className="text-success"><i className="fa fa-check-circle fs-2 me-2"></i>{paid.text}</span>
          </h4>
          { isDriver ? <p>Once the ride is done mark ride as completed by clicking the button below</p> : <p>Enjoy your ride with us!</p> }
          { renderAmountDue() }
        </div>
        { renderFilledData() }
        { renderMarkRideCompletedBttn() }
      </>
    }

    { step === unpaid.id && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">{ isDriver ? 'The' : 'Your' } booking is</span><br></br>
            <span className="text-success"><i className="fa fa-check-circle fs-2 me-2"></i>{unpaid.text}</span>
          </h4>
          { isDriver ? <p>Once the ride is done mark ride as completed by clicking on the button below</p> : <p>Enjoy your ride with us!</p> }
          { renderAmountDue() }
        </div>
        { renderFilledData() }
        { renderMarkRideCompletedBttn() }
      </>
    }

    { step === 'rejected' && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">{ isDriver ? 'The' : 'Your' } booking is</span><br></br>
            <span className="text-danger"><i className="fas fa-ban fs-2 me-2"></i>{rejected.text}</span>
          </h4>
        </div>
        { renderFilledData() }
        { !isDriver && (
            <div className="fields-container d-flex justify-content-center mt-4">
              <Button
                icon="car"
                type="normal"
                text="Book another ride"
                height="40px"
                onClick={() => {
                  let newBookingUrl
                  if(params.id) {
                    const url = window.location.href.split(params.id)
                    newBookingUrl = url[0]+params.id
                    console.log('new b url', newBookingUrl)
                    window.location.replace(newBookingUrl)
                  }
                  window.location.reload(false)
                }}
                disabled={loadIndicatorVisible}
              />
            </div>
          )
        }
      </>
    }
    { step === 'canceled' && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">{ isDriver ? 'The' : 'Your' } booking is</span><br></br>
            <span className="text-warning"><i className="dx-icon-clearcircle fs-2 me-2"></i>{canceled.text}</span>
          </h4>
        </div>
        { renderFilledData() }

        { !isDriver && (
            <div className="fields-container d-flex justify-content-center mt-4">
              <Button
                icon="car"
                type="normal"
                text="Book another ride"
                height="40px"
                onClick={() => {
                  let newBookingUrl
                  if(params.id) {
                    const url = window.location.href.split(params.id)
                    newBookingUrl = url[0]+params.id
                    console.log('new b url', newBookingUrl)
                    window.location.replace(newBookingUrl)
                  }
                  window.location.reload(false)
                }}
                disabled={loadIndicatorVisible}
              />
            </div>
          )
        }
      </>
    }

    { step === completed.id && <>
        <div className="text-center">
          <h4 className=""><span className="fs-5 text-muted">{ isDriver ? 'The' : 'Your' } ride is</span><br></br>
          <span className="text-success"><i className="fa fa-check-circle fs-2 me-2"></i>{completed.text}</span>
          </h4>
        </div>
        { renderFilledData() }
      </>
    }

    { step === 'distanceExceeded' && <>
        <div className="text-center">
          <h4 className="">
            <span className="text-warning">Not Available!</span>
          </h4>
          <p>Because the length of the ride is too long, unfortunately we can not accept you booking at this time. Please contact us directly at:</p>
          <Button
            icon="email"
            stylingMode="text"
            text={props.profileData.email}
            onClick={()=> window.open(`mailto:${props.profileData.email}`, '_self')}
          />

        </div>
      </>
    }

    { step === 'error' && renderErrorStep() }

    {renderButtons()}
  </>

}
