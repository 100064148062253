export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'fas fa-home'
  },
  {
    text: 'Fleet',
    path: '/fleet',
    icon: 'fas fa-car'
  },
  {
    text: 'Drivers',
    path: '/drivers',
    icon: 'fas fa-id-card-alt'
  },
  {
    text: 'Customers',
    path: '/customers',
    icon: 'fas fa-users'
  },
  {
    text: 'Schedule',
    path: '/schedule',
    icon: 'fas fa-calendar-alt'
  },
  {
    text: 'Prices',
    path: '/prices',
    icon: 'fas fa-wallet'
  },
  {
    text: 'Settings',
    path: '/profile',
    icon: 'fas fa-cogs'
  },
  {
    text: 'Billing',
    path: '/billing',
    icon: 'far fa-credit-card'
  },
];

export const navigationAdmin = [
  {
    text: 'Admin Help',
    path: '/admin-help',
    icon: 'far fa-question-circle'
  },
];

export const navigationUnauthenticated = [
  {
    text: 'Features',
    path: '/about/features',
    icon: 'fas fa-sitemap'
  },
  {
    text: 'About Us',
    path: '/about/about-us',
    icon: 'fas fa-users'
  },
  {
    text: 'Pricing',
    path: '/about/price',
    icon: 'fas fa-file-invoice-dollar'
  },
  {
    text: 'Contact Us',
    path: '/about/contact-us',
    icon: 'fas fa-address-card'
  },
];
