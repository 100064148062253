import React from "react";
import { Button } from 'devextreme-react/button';
import './ScheduleDataCell.scss'
import {useNavigate} from 'react-router-dom'
import {appointmentTypes} from '../../api/api'


export default function ScheduleDataCell(props) {
    const userData = null;
    const navigate = useNavigate();

    const getCellCSSClass = (appointment_type, appointment_status) => {
      let css_val = appointment_type + " "

      if (appointment_status === 'pending')
        css_val = css_val + 'appointment_pending';

      if (appointment_status === 'rejected')
        css_val = appointment_type +'_rejected';

      if (appointment_status === 'canceled')
        css_val = appointment_type +'_rejected';

      if (appointment_status === 'completed')
        css_val = appointment_type + '_completed';

      if (appointment_type === 'unavailable')
        css_val = appointment_type

      return css_val
    }

    return (
      <>
        <div className={"d-flex flex-column justify-content-between h-100 ps-1 pt-1 "+getCellCSSClass(props.data.appointmentData.appointment_type, props.data.appointmentData.status)}
        >
          <div style={{textWrap: 'wrap'}}>{props.data.appointmentData.appointment_title}</div>
          <div className="d-flex flex-row">
            {['ride_full_arrival_time', 'ride_full_departure_time'].includes(props.data.appointmentData.appointment_type) && <div
              style={{
                backgroundColor: "#fff",
                backgroundImage: `url("${props.data.appointmentData.driver && props.data.appointmentData.driver.resource_details.picture?props.data.appointmentData.driver.resource_details.picture:'/DefaultAvatar.png'}")`,
                backgroundSize: 'cover',
                width: "30px",
                height: "30px",
                borderRadius: "30px",
              }}
            />}
            <div
              className="ms-1"
              style={{
                backgroundColor: "#fff",
                backgroundImage: `url("${props.data.appointmentData.vehicle && props.data.appointmentData.vehicle.resource_details.picture?props.data.appointmentData.vehicle.resource_details.picture:'/DefaultCar.png'}")`,
                backgroundSize: 'contain',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "30px",
                height: "30px",
                borderRadius: "2px",
              }}
            />
          </div>
        </div>

      {false && props.data.data.resource_type === "vehicle" &&
        <div className="d-flex flex-row align-items-center justify-content-start">
          <div className={'vehicle-card-image-xs'}>
            {(props.data.data.resource_details && props.data.data.resource_details.picture) ? <img
              alt={''}
              src={props.data.data.resource_details.picture}
            />:
            <img
              alt={''}
              src={'/DefaultCar.png'}
            />}
          </div>
          <p className="ps-1 m-0">{props.data.data.resource_details.name}</p>
        </div>
      }
      </>
    );
}
