import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import 'devextreme/data/odata/store';
import './Scheduler.scss'
import { ScheduleResourceCell, ScheduleDataCell, ScheduleDataTooltip } from '../../components';
import { Scheduler , Resource, Editing, View } from 'devextreme-react/scheduler';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { Template } from 'devextreme-react/core/template';
import ContextMenu, { ContextMenuTypes } from 'devextreme-react/context-menu';
import TextBox from 'devextreme-react/text-box';
import devices from 'devextreme/core/devices';
import notify from 'devextreme/ui/notify';
import {useMap, useMapsLibrary} from '@vis.gl/react-google-maps';
import { useAuth } from '../../contexts/auth';
import { confirm } from "devextreme/ui/dialog"
import { fetchRoutes } from '../../api/googleApi';
import { getAvailableResources, getAppointment } from '../../api/api'
import RideTypePill from '../../components/icons/RideTypePill'


const appointmentClassName = '.dx-scheduler-appointment';
const cellClassName = '.dx-scheduler-date-table-cell';

export default function WTDScheduler(props) {
  const schedulerRef = useRef(null);
  const contextMenuRef = useRef(null);
  const { user, refreshSession } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams()

  const map = useMap();
  const places = useMapsLibrary('places');
  const routes = useMapsLibrary('routes');
  const groups = ['vehicle_id'];

  const [sessionToken, setSessionToken] = useState();
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [currentSchedulerDate, setCurrentSchedulerDate] = useState(new Date())
  const [availableResources, setAvailableResources] = useState()

  let selectedCustomer = ''

  const contextMenuItems = [
    {
      text: 'Add Entry',
      icon: 'fas fa-plus',
      visible: true,
    },
    {
      text: 'Edit',
      icon: 'fas fa-pen',
      visible: true,
    },
    {
      text: 'Delete',
      icon: "fas fa-trash-alt",
      visible: true,
    },
    {
      text: 'Invite Drivers',
      icon: 'fas fa-share',
      visible: true,
    }
  ];

  const formRef = useRef(null);


  useEffect(() => {
    if (props.showAppointment) {
      if (props.showAppointment.id) {
        const apptId =props.showAppointment.id
        const appt = props.appointmentData.filter(item => item.appointment_id === apptId)[0]
        if(!appt) {
          notify("Entry data not found. Please try again.", 'error', 3000);
          return;
        }
        if(apptId && schedulerRef.current) {
          setCurrentSchedulerDate(new Date(appt.startDate)) // reset Scheduler currentDate based on popup startDate
          schedulerRef.current.instance.showAppointmentPopup(appt)
        }
      } else {
        schedulerRef.current?.instance.showAppointmentPopup();
      }
    }
  }, [props.showAppointment]);

  const inviteDriversToAppointment = (appointmentData) => {
    props.onInvite(appointmentData);
  };

  const onAppointmentContextMenu = useCallback((event) => {
    const { appointmentData, targetedAppointmentData } = event;
    const scheduler = schedulerRef.current?.instance;
    let ctxItems = contextMenuRef?.current.instance.option('items')
    for (let i = 0; i < ctxItems.length; i++) {
      ctxItems[i].visible = false;
    }
    for (let i = 0; i < ctxItems.length; i++) {
      if (ctxItems[i].text === "Edit") {
        ctxItems[i].onItemClick = () => scheduler?.showAppointmentPopup(appointmentData);
        ctxItems[i].visible = true;
      }
      if (ctxItems[i].text === "Delete") {
        ctxItems[i].onItemClick = () => scheduler?.deleteAppointment(appointmentData);
        ctxItems[i].visible = true;
      }
      if (ctxItems[i].text === "Invite Drivers") {
        ctxItems[i].onItemClick = () => inviteDriversToAppointment(appointmentData);
        ctxItems[i].visible = true;
      }
    }
    contextMenuRef?.current.instance.option('items', ctxItems);
    contextMenuRef?.current.instance.option('target', appointmentClassName);
  }, []);

  const onCellContextMenu = useCallback(
    (e) => {
      const scheduler = schedulerRef.current?.instance;
      let ctxItems = contextMenuRef?.current.instance.option('items')
      for (let i = 0; i < ctxItems.length; i++) {
        ctxItems[i].visible = false;
      }
      for (let i = 0; i < ctxItems.length; i++) {
        if (ctxItems[i].text === "Add Entry") {
          ctxItems[i].onItemClick = () => scheduler?.showAppointmentPopup({ startDate: e.cellData.startDate, vehicle_id: e.cellData.groups.vehicle_id }, true);
          ctxItems[i].visible = true;
        }
      }
      contextMenuRef?.current.instance.option('items', ctxItems);
      contextMenuRef?.current.instance.option('target', cellClassName);
    },
    [groups],
  );


  useEffect(() => {
    //if (!places || !map) return;
    if (!places || !routes) return;

    setAutocompleteService(new places.AutocompleteService());
    //setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());
    setDirectionsService(new routes.DirectionsService());

    //return () => setAutocompleteService(null);
  }, [map, places, routes]);

  const fetchPredictions = useCallback(
    async (inputValue, form, editor) => {
      if (!autocompleteService || !inputValue) {
        return;
      }
      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);
      form.getEditor(editor).option('dataSource', response.predictions);
    },
    [autocompleteService, sessionToken]
  );


  const fetchAvailableResources = useCallback( (user, apptData) => {
    if(!apptData.pickupDate) return
    getAvailableResources(
      user,
      apptData,
      r=> {
        console.log("got available resourses on appt form open", r)
        setAvailableResources(r)
      },
      err=>console.warn('did not get any available resources, ', err)
    )
  }, [])

  const fetchLatestAppointmentData = useCallback( (user, e) => {
    var resources = [];
    getAppointment(
      user,
      e.appointmentData.appointment_id,
      resources, (data, resources)=> {
        var missing_resource = false
        var resource_found = false
        for (let i = 0; i < props.vehicleList.length; i++) {
          if (data.vehicle_id) {
            if (data.vehicle_id === props.vehicleList[i].resource_id) {
              data.vehicle = props.vehicleList[i];
              resource_found = true;
              break;
            }
          } else {
            resource_found = true;
          }
        }
        if (resource_found === false) {
          missing_resource = true
        }
        resource_found = false
        for (let i = 0; i < props.driverList.length; i++) {
          if (data.driver_id) {
            if (data.driver_id === props.driverList[i].resource_id) {
              data.driver = props.driverList[i];
              resource_found = true;
              break;
            }
          } else {
            resource_found = true;
          }
        }
        if (resource_found === false) {
          missing_resource = true
        }
        resource_found = false
        for (let i = 0; i < props.customerList.length; i++) {
          if (data.customer_id) {
            if (data.customer_id === props.customerList[i].resource_id) {
              data.customer_data = props.customerList[i]['resource_details'];
              resource_found = true;
              break;
            }
          } else {
            resource_found = true;
          }
        }
        if (resource_found === false) {
          missing_resource = true
        }

        if (missing_resource) {
          // reloading everything
          //console.error("Missing resource");
          e.popup.hide();
          notify("Please refresh your screen", 'warning', 3000);
        } else {
          e.appointmentData = data
          e.form.updateData(data)
          updateForm(e);
        }

      },
      err=> {
        e.popup.hide();
        notify("Please refresh your screen", 'warning', 3000);
      }
    )
  }, [])

  const onContextMenuItemClick = (e) => {
    if(e.itemData.text === 'Delete') {
      let result = confirm("<i>Are you sure?</i>", "Confirm deletion");
      result.then(dialogResult => dialogResult && e.itemData.onItemClick?.(e) )
    } else {
      e.itemData.onItemClick?.(e)
    }
  };

  const AppointmentMenuTemplate = (props) => (
    <div>
      {props.data.color && (
        <div
          className="item-badge"
          style={{ backgroundColor: props.data.color }}
        />
      )}
      <div className="item-template-container">
        {props.data.icon && <span className={`${props.data.icon} me-3 dx-icon`}></span>}
        <span className="dx-menu-item-text">{props.data.text}</span>
        {false && props.data.items && <span className="dx-icon-spinright dx-icon"></span>}
      </div>
    </div>
  );



  const setForm = (form, entry_type) => {
    if (!(entry_type)) {
      return;
    }
    let items = form.option('items');
    for (let i = 0; i < items.length; i++) {
      if (items[i].dataField === "status") {
        items[i].visible = ['ride_full_arrival_time', 'ride_full_departure_time','ride_full_roundtrip'].includes(entry_type);
      }
      if (items[i].dataField === "vehicle_id") {
        items[i].visible = true;
      }
      if (items[i].dataField === "driver_id") {
        items[i].visible = ['ride_full_arrival_time', 'ride_full_departure_time', 'ride_full_roundtrip'].includes(entry_type);
      }
      if (items[i].dataField === "startDate") {
        items[i].visible = true;
        items[i].disabled = !(['unavailable'].includes(entry_type));

      }
      if (items[i].dataField === "pickupDate") {
        items[i].visible = ['ride_full_arrival_time', 'ride_full_departure_time', 'ride_full_roundtrip'].includes(entry_type);
        items[i].disabled = !(['ride_full_departure_time'].includes(entry_type));
      }
      if (items[i].dataField === "returnDate") {
        items[i].visible = (['ride_full_roundtrip'].includes(entry_type));
        items[i].disabled = !(['ride_full_roundtrip'].includes(entry_type));
      }
      if (items[i].dataField === "destinationDate") {
        items[i].visible = (['ride_full_roundtrip'].includes(entry_type));
        items[i].disabled = !(['ride_full_roundtrip'].includes(entry_type));
      }

      if (items[i].dataField === "endDate") {
        items[i].visible = true;
        items[i].disabled = !(['ride_full_arrival_time', 'unavailable'].includes(entry_type));
      }
      if (["customer_data.name", "customer_data.email","customer_data.phone", "customer_data.notes"].includes(items[i].dataField)) {
        items[i].visible = ['ride_full_arrival_time', 'ride_full_departure_time', 'ride_full_roundtrip'].includes(entry_type);
      }
      if (["ride_details.passengers", "ride_details.childern", "ride_details.luggages", "ride_details.notes"].includes(items[i].dataField)) {
        items[i].visible = ['ride_full_arrival_time', 'ride_full_departure_time', 'ride_full_roundtrip'].includes(entry_type);
      }
      if (["ride_details.start_address", "ride_details.pickup_address", "ride_details.end_address"].includes(items[i].dataField)) {
        items[i].visible = ['ride_full_arrival_time', 'ride_full_departure_time', 'ride_full_roundtrip'].includes(entry_type);
      }

      if (["ride_details.destination_address"].includes(items[i].dataField)) {
        items[i].visible = ['ride_full_roundtrip'].includes(entry_type);
      }
    }
    form.option('items', items);
    form.option('visible', true);
  }
  const onAppointmentSave = (e) => {
    props.onAppointmentSave(e.appointmentData);
  }

  const onAppointmentSaving = (e) => {
    let cd = new Date();
    let apptData = null;
    if (e.newData) {
      apptData = e.newData
    }
    if (e.appointmentData) {
      apptData = e.appointmentData
    }
    let { startDate, pickupDate, destinationDate, returnDate, endDate } = apptData

    let sd = new Date(startDate);
    let ed = new Date(endDate);
    let pd = new Date(pickupDate);
    let rd = new Date(returnDate);
    let dd = new Date(destinationDate);
    if ((sd < cd) || (ed < cd) || (pd < cd)) {
      notify("Cannot modify the entry because is in the past.", 'error', 3000);
      e.cancel = true;
      return;
    }
  }

  const onAppointmentDelete = (e) => {
    props.onAppointmentDelete(e.appointmentData);
  }

  const activeVehicles = props.vehicleList.filter(vehicle => vehicle.resource_details.active !== false)

  const onAppointmentFormOpening = (e) => {
    if(e.appointmentData) {
      if (e.appointmentData.appointment_id) {
        e.form.option('items', []);
        e.form.option('visible', false);
        //e.popup.hide();
        fetchLatestAppointmentData(user, e);
      } else {
        updateForm(e);
      }
      fetchAvailableResources(user, e.appointmentData)
    }
  }

  const updateForm = (e) => {
    console.log(e);
    let buttons = e.popup.option("toolbarItems");
    buttons[0].options.text="Save"
    //buttons[0].options.disabled=true
    e.popup.option("toolbarItems", buttons);
    let { startDate, pickupDate, destinationDate, returnDate, endDate } = e.appointmentData;
    let cd = new Date();
    let new_appointment = true;
    let has_vehicle = true;
    //cd.setHours(cd.getHours() + 1);
    if (!e.appointmentData.appointment_type) {
      let sd = new Date(startDate);
      if ((sd < cd) && (e.appointmentData.vehicle_id)) {
        notify("Cannot add entries in the past.", 'warning', 3000);
        e.cancel = true;
        return;
      }
      if (!e.appointmentData.vehicle_id) {
        sd = cd;
      }
      e.form.updateData('startDate',sd);
      e.form.updateData('endDate',sd);
      e.form.updateData('destinationDate',sd);
      e.form.updateData('returnDate',sd);
      e.form.updateData('pickupDate',sd);
      if (!(e.appointmentData.vehicle_id)) {
        e.form.updateData('vehicle_id', "00000000-0000-0000-0000-000000000000");
        e.appointmentData.vehicle_id = "00000000-0000-0000-0000-000000000000";
        has_vehicle = false;
      }
      for (let i = 0; i < props.vehicleList.length;i++ ) {
        if (props.vehicleList[i].resource_id === e.appointmentData.vehicle_id) {
          e.form.updateData('vehicle', props.vehicleList[i]);
        }
      }
      e.form.updateData('appointment_id',crypto.randomUUID());
      e.form.updateData('status','pending');
    } else {
      let sd = new Date(startDate);
      let ed = new Date(endDate);
      let rd = new Date(returnDate);
      let dd = new Date(destinationDate);
      let pd = new Date(pickupDate);
      if ((sd < cd) || (ed < cd) || (pd < cd)) {
        notify("Past entries are read-only.", 'warning', 3000);
        e.cancel = true;
        e.popup.hide();
        return;
      }
      //e.popup.show();
      e.form.updateData('startDate',new Date(startDate));
      e.form.updateData('endDate',new Date(endDate));
      e.form.updateData('pickupDate',new Date(pickupDate));
      e.form.updateData('returnDate',new Date(returnDate));
      e.form.updateData('destinationDate',new Date(destinationDate));

      new_appointment = false;
      if (e.appointmentData.vehicle_id === "00000000-0000-0000-0000-000000000000") {
        has_vehicle = false;
      }
    }
    if (!(e.appointmentData.ride_details)) {
      e.form.updateData('ride_details', {start_address: props.settings.base_address});
    }

    if (!(e.appointmentData.customer_data)) {
      e.form.updateData('customer_data', {});
    }
    e.form.option('colCount', 1);
    e.form.option('colCountByScreen', { xs: 1, sm: 1, md: 1, lg: 1});
    e.form.option('items', [
      {
        label: {
          text: 'Entry Type',
        },
        editorType: 'dxSelectBox',
        dataField: 'appointment_type',
        isRequired: true,
        editorOptions: {
          items: props.appointmentTypes,
          displayExpr: 'text',
          valueExpr: 'id',
          fieldTemplate: 'entry_type_select_field',
          itemTemplate: 'entry_type_select_item',
          onValueChanged(args) {
            setForm(e.form, args.value);
            e.form.updateData('appointment_type', args.value);
            for (let j = 0; j<props.appointmentTypes.length; j++ ){
              if (args.value === props.appointmentTypes[j].id) {
                e.form.updateData('appointment_title', props.appointmentTypes[j].text);
              }
            }
          },
        },
      },
      {
        label: {
          text: 'Status',
        },
        editorType: 'dxSelectBox',
        dataField: 'status',
        visible: false,
        editorOptions: {
          items: new_appointment?props.appointmentStatusValues.filter(val => { return (val.requires_vehicle === false) && (val.requires_existing_appointment === false); })
                                :(has_vehicle?props.appointmentStatusValues
                                             :props.appointmentStatusValues.filter(val => { return (val.requires_vehicle === false)})),
          displayExpr: 'text',
          valueExpr: 'id',
          onValueChanged(args) {
            /*setForm(e.form, args.value);
            e.form.updateData('appointment_type', args.value);
            for (let j = 0; j<props.appointmentTypes.length; j++ ){
              if (args.value === props.appointmentTypes[j].id) {
                e.form.updateData('appointment_title', props.appointmentTypes[j].text);
              }
            }*/
          },
        },
      },
      {
        dataField: 'startDate',
        label: { text: 'Start Date and Time' },
        editorType: 'dxDateBox',
        visible: false,
        isRequired: true,
        editorOptions: {
          min: cd,
          width: '100%',
          type: 'datetime',
          onValueChanged(args) {
            fetchRoutes(e.form, props.settings, directionsService);
            fetchAvailableResources(user, e.form.option('formData'))
          },
        },
      },
      {
        name: 'pickupDate',
        dataField: 'pickupDate',
        label: { text: 'Pick-up Date and Time' },
        editorType: 'dxDateBox',
        visible: false,
        isRequired: true,
        editorOptions: {
          min: cd,
          width: '100%',
          type: 'datetime',
          onValueChanged(args) {
            fetchRoutes(e.form, props.settings, directionsService);
            fetchAvailableResources(user, e.form.option('formData'))
          },
        },
      },
      {
        name: 'destinationDate',
        dataField: 'destinationDate',
        label: { text: 'Destination Arrival Date and Time' },
        editorType: 'dxDateBox',
        visible: false,
        isRequired: true,
        editorOptions: {
          min: cd,
          width: '100%',
          type: 'datetime',
          onValueChanged(args) {
            fetchRoutes(e.form, props.settings, directionsService);
          },
        },
      },
      {
        name: 'returnDate',
        dataField: 'returnDate',
        label: { text: 'Return Date and Time' },
        editorType: 'dxDateBox',
        visible: false,
        isRequired: true,
        editorOptions: {
          min: cd,
          width: '100%',
          type: 'datetime',
          onValueChanged(args) {
            fetchRoutes(e.form, props.settings, directionsService);
          },
        },
      },
      {
        name: 'endDate',
        dataField: 'endDate',
        label: { text: 'Drop-off Date and Time' },
        visible: false,
        editorType: 'dxDateBox',
        isRequired: true,
        editorOptions: {
          min: cd,
          width: '100%',
          type: 'datetime',
          onValueChanged(args) {
            fetchRoutes(e.form, props.settings, directionsService);
            fetchAvailableResources(user, e.form.option('formData'))
          },
        },
      },
      {
        label: {
          text: 'Start Address',
        },
        isRequired: true,
        visible: false,
        dataField: 'ride_details.start_address',
        editorType: 'dxAutocomplete',
        isRequired: true,
        editorOptions: {
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: [],
          onSelectionChanged(args) {
            if (args.selectedItem) {
              e.form.updateData('ride_details.start_address', args.selectedItem.description);
              fetchRoutes(e.form, props.settings, directionsService);
            }
          },
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value, e.form, 'ride_details.start_address');
          },
        },
      },
      {
        label: {
          text: 'Pick-up Address',
        },
        visible: false,
        dataField: 'ride_details.pickup_address',
        editorType: 'dxAutocomplete',
        isRequired: true,
        editorOptions: {
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: [],
          onSelectionChanged(args) {
            if (args.selectedItem) {
              e.form.updateData('ride_details.pickup_address', args.selectedItem.description);
              fetchRoutes(e.form, props.settings, directionsService);
            }
          },
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value, e.form, 'ride_details.pickup_address');
          },
        },
      },
      {
        label: {
          text: 'Destination Address',
        },
        visible: false,
        isRequired: true,
        dataField: 'ride_details.destination_address',
        editorType: 'dxAutocomplete',
        editorOptions: {
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: [],
          onSelectionChanged(args) {
            if (args.selectedItem) {
              e.form.updateData('ride_details.destination_address', args.selectedItem.description);
              fetchRoutes(e.form, props.settings, directionsService);
            }
          },
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value, e.form, 'ride_details.destination_address');
          },
        },
      },
      {
        label: {
          text: 'Drop-off Address',
        },
        visible: false,
        dataField: 'ride_details.end_address',
        editorType: 'dxAutocomplete',
        isRequired: true,
        editorOptions: {
          showDropDownButton: true,
          valueExpr: 'description',
          dataSource: [],
          onSelectionChanged(args) {
            if (args.selectedItem) {
              e.form.updateData('ride_details.end_address', args.selectedItem.description);
              fetchRoutes(e.form, props.settings, directionsService);
            }
          },
          onInput(args) {
            fetchPredictions(args.event.currentTarget.value, e.form, 'ride_details.end_address');
          },
        },
      },
      {
        label: {
          text: 'Vehicle',
        },
        visible: false,
        dataField: 'vehicle_id',
        editorType: 'dxSelectBox',
        isRequired: true,
        editorOptions: {
          items: activeVehicles,
          displayExpr: 'resource_details.name',
          defaultValue: 'resource_details.name',
          valueExpr: 'resource_id',
          fieldTemplate: 'vehicle_select_field',
          itemTemplate: 'vehicle_select_item',
          onValueChanged(args) {
            let vl = args.component.getDataSource().items();
            for (let i = 0; i < vl.length;i++ ) {
              if (vl[i].resource_id === args.value) {
                e.form.updateData('vehicle', vl[i]);
              }
            }
            e.form.updateData('vehicle_id', args.value);
            try {
              e.form.itemOption('driver_id', 'editorOptions', {disabled: args.value === "00000000-0000-0000-0000-000000000000"})
              if (args.value === "00000000-0000-0000-0000-000000000000") {
                e.form.updateData('driver_id', null);
                e.form.updateData('driver', null);
                if (new_appointment) {
                  e.form.getEditor('status').option('items', props.appointmentStatusValues.filter(val => { return (val.requires_vehicle === false) && (val.requires_existing_appointment === false); }));
                } else {
                  e.form.getEditor('status').option('items', props.appointmentStatusValues.filter(val => { return (val.requires_vehicle === false); }));
                }

              } else {
                if (new_appointment) {
                  e.form.getEditor('status').option('items', props.appointmentStatusValues.filter(val => { return (val.requires_existing_appointment === false); }));
                } else {
                  e.form.getEditor('status').option('items', props.appointmentStatusValues);
                }

              }
            } catch {
              console.warn("Form item not found");
            }

          },
        },
      },
      {
        label: {
          text: 'Driver',
        },
        visible: false,
        dataField: 'driver_id',
        editorType: 'dxSelectBox',
        editorOptions: {
          disabled: (e.appointmentData.vehicle_id === "00000000-0000-0000-0000-000000000000"),
          items: props.driverList,
          displayExpr: 'resource_details.name',
          defaultValue: 'resource_details.name',
          valueExpr: 'resource_id',
          fieldTemplate: 'driver_select_field',
          itemTemplate: 'driver_select_item',
          onValueChanged(args) {
            for (let i = 0; i < props.driverList.length; i++ ) {
              if (props.driverList[i].resource_id === args.value) {
                // Next if statemet is needed because if the driver is changed and
                // the new driver has no picture the picture from the previous driver
                // is not reset. It looks like updateData as a cumulative effect.
                if (!props.driverList[i].resource_details.picture) {
                  e.form.updateData('driver.resource_details.picture', null);
                }
                e.form.updateData('driver', props.driverList[i]);
              }
            }
            e.form.updateData('driver_id', args.value);
          },
        },
      },
      {
        label: {
          text: 'Customer Name',
        },
        visible: false,
        dataField: 'customer_data.name',
        editorType: 'dxAutocomplete',
        isRequired: true,
        editorOptions: {
          items: props.customerList,
          displayExpr: 'resource_details.name',
          defaultValue: 'resource_details.name',
          valueExpr: 'resource_details.name',
          showDropDownButton: true,
          onValueChanged(args) {
            //let customer_data = { name: args.value }
            e.form.updateData('customer_data.name', args.value);
          },
          onItemClick(args){
            selectedCustomer = {}
            selectedCustomer['customer_id'] = args.itemData.resource_id
            selectedCustomer['name'] = args.itemData.resource_details.name
            selectedCustomer['phone'] = args.itemData.resource_details.phone
            selectedCustomer['email'] = args.itemData.resource_details.email
            selectedCustomer['notes'] = args.itemData.resource_details.notes
            console.log('args.itemData.resource_details', args.itemData.resource_details)

            e.form.updateData('customer_id', selectedCustomer['customer_id'])
            e.form.updateData('customer_data.email', args.itemData.resource_details.email);
            e.form.updateData('customer_data.phone', args.itemData.resource_details.phone);
            e.form.updateData('customer_data.notes', args.itemData.resource_details.notes);

            e.form.getEditor('customer_data.email').option('readOnly', true)
            e.form.getEditor('customer_data.phone').option('readOnly', true)
            e.form.getEditor('customer_data.notes').option('readOnly', true)
          },
          onInput(args){
            if(args.event.currentTarget.value.trim() !== selectedCustomer['name']){
              e.form.updateData('customer_id', null)
              e.form.getEditor('customer_data.email').option('readOnly', false)
              e.form.getEditor('customer_data.phone').option('readOnly', false)
              e.form.getEditor('customer_data.notes').option('readOnly', false)

              e.form.updateData('customer_data.email', '');
              e.form.updateData('customer_data.phone', '');
              e.form.updateData('customer_data.notes', '');
            } else {
              e.form.updateData('customer_id', selectedCustomer['customer_id'])
              e.form.updateData('customer_data.email', selectedCustomer['email']);
              e.form.updateData('customer_data.phone', selectedCustomer['phone']);
              e.form.updateData('customer_data.notes', selectedCustomer['notes']);

              e.form.getEditor('customer_data.email').option('readOnly', true)
              e.form.getEditor('customer_data.phone').option('readOnly', true)
              e.form.getEditor('customer_data.notes').option('readOnly', true)
            }
          }
        },
      },
      {
        label: {
          text: 'Customer Email',
        },
        visible: false,
        dataField: 'customer_data.email',
        editorType: 'dxTextBox',
        isRequired: true,
        editorOptions: {
          onValueChanged(args) {
            e.form.updateData('customer_data.email', args.value);
          },
        },
      },
      {
        label: {
          text: 'Customer Phone',
        },
        visible: false,
        dataField: 'customer_data.phone',
        editorType: 'dxTextBox',
        isRequired: true,
        editorOptions: {
          mask: '+0 (000) 000-0000',
          onValueChanged(args) {
            e.form.updateData('customer_data.phone', args.value);
          },
        },
      },
      {
        label: {
          text: 'Customer Internal Notes',
        },
        visible: false,
        dataField: 'customer_data.notes',
        editorType: 'dxTextArea',
        editorOptions: {
          onValueChanged(args) {
            e.form.updateData('customer_data.notes', args.value);
          },
        },
      },
      {
        label: {
          text: 'Adult Passengers',
        },
        visible: false,
        dataField: 'ride_details.passengers',
        editorType: 'dxNumberBox',
        editorOptions: {
          min: 0,
          max: 50,
          step: 0,
          onValueChanged(args) {
            fetchAvailableResources(user, e.form.option('formData'))
          },
        },
      },
      {
        label: {
          text: 'Childern under 9',
        },
        visible: false,
        dataField: 'ride_details.childern',
        editorType: 'dxNumberBox',
        editorOptions: {
          min: 0,
          max: 50,
          step: 0,
        },
        onValueChanged(args) {
          fetchAvailableResources(user, e.form.option('formData'))
        },
      },
      {
        label: {
          text: 'Luggage',
        },
        visible: false,
        dataField: 'ride_details.luggages',
        editorType: 'dxNumberBox',
        editorOptions: {
          min: 0,
          max: 50,
          step: 0,
        },
        onValueChanged(args) {
          fetchAvailableResources(user, e.form.option('formData'))
        },
      },
      {
        label: {
          text: 'Special Requests',
        },
        visible: false,
        dataField: 'ride_details.notes',
        editorType: 'dxTextArea',
        editorOptions: {
          placeholder: 'Add things like flight number, gate code, details about large items, etc.',
          onValueChanged(args) {
            e.form.updateData('ride_details.notes', args.value);
          },
        },
      },
    ]);
    setForm(e.form, e.appointmentData.appointment_type);
  };


  const isResourceAvailable = (e, availableResources) => {
    if(!availableResources) return true
    if(e.data === null) return true
    const currentId = e.data.resource_id
    const resourcesObj = availableResources[e.data.resource_type+'s']
    let available = true
    if(currentId !== '00000000-0000-0000-0000-000000000000') {
      available = resourcesObj[currentId]
    }
    return available
  }



  const VehicleSelectField = useCallback((e) => {
    const available = isResourceAvailable(e, availableResources)
    return (
      <div className="d-flex flex-row align-items-center">
        {e.data && <div
          className="ms-3"
            style={{
              backgroundColor: "#fff",
              backgroundImage: `url("${e.data && e.data.resource_details.picture?e.data.resource_details.picture:'/DefaultCar.png'}")`,
              backgroundSize: 'contain',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "50px",
              height: "50px",
              marginTop: "3px",
              marginBottom: "3px",
              borderRadius: "2px",
            }}
        />}
        <TextBox
          className="driver-select-field-name"
          placeholder="Select a vehicle..."
          defaultValue={e.data && e.data.resource_details.name}
          readOnly={true}
        />
          { !available && <i className="fa fa-circle text-warning"></i> }
      </div>
    );
  }, [availableResources])

  const VehicleSelectItem = useCallback((e) => {
    const available = isResourceAvailable(e, availableResources)

    return (
      <div className="d-flex flex-row align-items-center">
        <div
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url("${e.data && e.data.resource_details.picture?e.data.resource_details.picture:'/DefaultCar.png'}")`,
            backgroundSize: 'contain',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "50px",
            height: "50px",
            marginTop: "3px",
            marginBottom: "3px",
            borderRadius: "2px",
          }}
        />
        <div className="d-flex align-items-center justify-content-between w-100">
          { e.data && <div className="ms-3">{e.data.resource_details.name}</div> }
          { !available && <i className="fa fa-circle text-warning"></i> }
        </div>
      </div>
    );
  }, [availableResources])


  const DriverSelectField = useCallback( (e) => {
    const available = isResourceAvailable(e, availableResources)
    return (
      <div className="d-flex flex-row align-items-center">
        {e.data && <div
          className="ms-3"
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url("${e.data && e.data.resource_details.picture?e.data.resource_details.picture:'/DefaultAvatar.png'}")`,
            backgroundSize: 'cover',
            width: "50px",
            height: "50px",
            marginTop: "3px",
            marginBottom: "3px",
            borderRadius: "50px",
          }}
        />}
        <TextBox
          className="driver-select-field-name"
          placeholder="Select a driver..."
          defaultValue={e.data && e.data.resource_details.name}
          readOnly={true}
        />
          { !available && <i className="fa fa-circle text-warning"></i> }
      </div>
    );
  }, [availableResources])

  const DriverSelectItem = useCallback( (e) => {
    const available = isResourceAvailable(e, availableResources)
    return (
      <div className="d-flex flex-row align-items-center">
        <div
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url("${e.data && e.data.resource_details.picture?e.data.resource_details.picture:'/DefaultAvatar.png'}")`,
            backgroundSize: 'cover',
            width: "50px",
            height: "50px",
            marginTop: "3px",
            marginBottom: "3px",
            borderRadius: "50px",
          }}
        />

        <div className="d-flex align-items-center justify-content-between w-100">
          { e.data && <div className="ms-3">{e.data.resource_details.name}</div> }
          { !available && <i className="fa fa-circle text-warning"></i> }
        </div>

      </div>
    );
  }, [availableResources])

  const EntryTypeSelectField = (data) => {
    return (
      <div className="d-flex flex-row align-items-center">
        { data.data &&
            <div className="ms-3 me-0"><RideTypePill data={data.data} displayTooltip={false} /></div>
        }
        <div style={{marginLeft: data.data && '-13px'}}>
          <TextBox
            className="driver-select-field-name"
            placeholder="Select an entry type ..."
            defaultValue={data.data && data.data.text}
            readOnly={true}
          />
        </div>
      </div>
    );
  }

  const EntryTypeSelectItem = (data) => {
    return (
      <div className={"d-flex flex-row align-items-center"}>
        <RideTypePill data={data.data} displayTooltip={false} />
        {data.data && <div className="ms-1">{data.data.text}
        </div>}
      </div>
    );
  }

  return (
    <React.Fragment>
      <Scheduler
        ref={schedulerRef}
        id="scheduler"
        dataSource={props.appointmentData}
        onAppointmentFormOpening={onAppointmentFormOpening}
        onAppointmentAdding={onAppointmentSaving}
        onAppointmentAdded={onAppointmentSave}
        crossScrollingEnabled={false}
        onAppointmentUpdated={onAppointmentSave}
        onAppointmentUpdating={onAppointmentSaving}
        onAppointmentDeleted={onAppointmentDelete}
        onAppointmentContextMenu={onAppointmentContextMenu}
        onAppointmentDblClick={onAppointmentContextMenu}
        onCellContextMenu={onCellContextMenu}
        appointmentComponent={ScheduleDataCell}
        appointmentTooltipComponent={ScheduleDataTooltip}
        resourceCellComponent={ScheduleResourceCell}
        adaptivityEnabled={true}
        groups={groups}
        defaultCurrentView={devices.current().deviceType === 'phone'?"day":"week"}
        allDayPanelMode="hidden"
        currentDate={currentSchedulerDate}
      >

        <View
          type='day'
          cellDuration={60}
          groupOrientation='vertical'
        />
        <View
          type='week'
          cellDuration={120}
          groupOrientation='vertical'
        />
        <Resource
          label="Vehicle"
          fieldExpr="vehicle_id"
          valueExpr="resource_id"
          displayExpr="resource_details.name"
          dataSource={props.vehicleList}
          allowMultiple={false}
        />
        <Resource
          label="Driver"
          fieldExpr="driver_id"
          valueExpr="resource_id"
          displayExpr="resource_details.name"
          dataSource={props.driverList}
          allowMultiple={false}
        />
        <Resource
          label="Entry Type"
          fieldExpr="appointment_type"
          dataSource={props.appointmentTypes}
          allowMultiple={false}
          useColorAsDefault={true}
        />
        <Editing
          allowAdding={true}
          allowDeleting={true}
          allowDragging={false}
          allowResizing={false}
          allowUpdating={true}
        />
        <Template name="entry_type_select_field" component={EntryTypeSelectField} />
        <Template name="entry_type_select_item" component={EntryTypeSelectItem} />
        <Template name="driver_select_field" component={DriverSelectField} />
        <Template name="driver_select_item" component={DriverSelectItem} />
        <Template name="vehicle_select_field" component={VehicleSelectField} />
        <Template name="vehicle_select_item" component={VehicleSelectItem} />
      </Scheduler>
      <ContextMenu
        ref={contextMenuRef}
        dataSource={contextMenuItems}
        width={200}
        target={appointmentClassName}
        disabled={false}
        onItemClick={onContextMenuItemClick}
        itemComponent={AppointmentMenuTemplate}
      />
    </React.Fragment>
)}
