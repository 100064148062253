import {CognitoUserPool} from "amazon-cognito-identity-js";

 const poolData = {
     UserPoolId: "us-east-1_CuRzUUUvw",
     ClientId: "q88rcso5jvjjp44okgiaflak5",
     storage: window.localStorage
 }
const cognitoUserPool = new CognitoUserPool(poolData);

export default cognitoUserPool;
