import React, { useState, useEffect, useCallback, useRef } from 'react';
import './Billing.scss';
import { useAuth } from '../../contexts/auth';
import { useNavigate, useSearchParams } from "react-router-dom";
import {getCases, getUserDetails, getFormData, createCases, createTemplate} from '../../api/api'
import { LoadIndicator } from 'devextreme-react/load-indicator';
import {RadioGroup} from 'devextreme-react/radio-group';
import TagBox from 'devextreme-react/tag-box';
import { TextBox } from 'devextreme-react/text-box';
import { Autocomplete } from 'devextreme-react';
import { Template } from 'devextreme-react/core/template';
import notify from 'devextreme/ui/notify';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { SubscriptionPlan } from '../../components';

import {
  Validator,
  StringLengthRule,
} from 'devextreme-react/validator';
import {Form,
    SimpleItem,
    Label,
    GroupItem,
    ButtonItem,
    RequiredRule} from 'devextreme-react/form';


export default function Billing() {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { user, refreshSession } = useAuth();
  const [caseData, setCaseData] = useState({case_copies:1, case_date: new Date()});
  const [caseDataAPI, setCaseDataAPI] = useState({case_copies:1, case_date: (new Date()).toLocaleString('sv').replace(' ', 'T').split('T')[0]});
  const [userData, setUserData] = useState(null);
  const [working, setWorking] = useState(null);
  const [currentPlanId, setCurrentPlanId] = useState(null);



  const onError = useCallback((error) => {
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
    notify("We ran into an error. Please try again.", 'error', 3000);
    setWorking(null);
  }, [refreshSession]);

  const onGetUserDetailsSuccess = useCallback((data) => {
    if (userData === null) {
      if (data.subscription) {
        setCurrentPlanId(data.subscription.plan_id)
      }
    }
    setUserData(data)

  }, [userData, currentPlanId])

  const onCloseDialog = () => {
    setWorking(null);
  }

  const onPlanManage = (plan) => {
    setWorking("manage");
  }

  const onPlanSubscribe = (plan) => {
    setWorking("checkout");
  }

  useEffect(() => {
    if (working == "checkout") {
      if ((userData.subscription) && (userData.subscription.plan_id !== currentPlanId) ) {
        setWorking(null)
        setCurrentPlanId(userData.subscription.plan_id)
      } else {
        setTimeout(function(){
          getUserDetails(user, onGetUserDetailsSuccess, onError);
        }, 2000);
      }
    }
    if (working == "manage") {
      setTimeout(function(){
        getUserDetails(user, onGetUserDetailsSuccess, onError);
      }, 2000);
    }
  }, [working, userData, currentPlanId]);

  useEffect(() => {
    if (user&&user.signInUserSession) {
      getUserDetails(user, onGetUserDetailsSuccess, onError);
    }
  }, [user, user&&user.signInUserSession]);


  return (
    <React.Fragment>
      <h2 className={'content-block'}>Billing</h2>

      { userData && (working===null) && <div className="row justify-content-center">
        <div className="col-12 col-sm-6 col-lg-3 pb-2">
          <SubscriptionPlan
            title="Solo"
            price="$80"
            promo="Free for 90 days"
            plan_id="price_1P1dS7DEcG27YvSO9Uuv76bM"
            interval="month"
            userData={userData}
            onBuy={onPlanSubscribe}
            onManage={onPlanManage}
            cta_buy_link="https://buy.stripe.com/28ocN6a7kfiR3CM7su"
            cta_manage_link="https://billing.stripe.com/p/login/dR6dR35P3cL20ow9AA"
            features={[
              <li key="1">Solo Driver Operator</li>,
            ]}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-3 pb-2">
          <SubscriptionPlan
            title="Growing"
            price="$150"
            promo="Free for 90 days"
            plan_id="price_1P1dT0DEcG27YvSOr5aPcBNz"
            interval="month"
            userData={userData}
            onBuy={onPlanSubscribe}
            onManage={onPlanManage}
            cta_buy_link="https://buy.stripe.com/bIY5kEdjw7QpddmaEH"
            cta_manage_link="https://billing.stripe.com/p/login/dR6dR35P3cL20ow9AA"
            features={[
              <li key="1">Up to 3 vehicles</li>,
            ]}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-3 pb-2">
          <SubscriptionPlan
            title="Titan"
            price="$200"
            promo="Free for 90 days"
            plan_id="price_1P1dtfDEcG27YvSOGyTczaB9"
            interval="month"
            userData={userData}
            onBuy={onPlanSubscribe}
            onManage={onPlanManage}
            cta_buy_link="https://buy.stripe.com/9AQ4gAfrE7Qpflu6os"
            cta_manage_link="https://billing.stripe.com/p/login/dR6dR35P3cL20ow9AA"
            features={[
              <li key="1">Up to 6 vehicles</li>,
            ]}
          />
        </div>
        <div className="col-12 col-sm-6 col-lg-3 pb-2">
          <SubscriptionPlan
            title="Enterprise"
            features={[
              <li key="1">More than 6 vehicles</li>,
            ]}
          />
        </div>
      </div>}

      <Popup
        className="text-danger"
        visible={working !== null}
        dragEnabled={false}
        showCloseButton={true}
        showTitle={true}
        onHidden={onCloseDialog}
        title="Checking..."
        container=".dx-viewport"
        width={300}
        height={300}
      >
        <Position at="center" my="center"collision="fit" />
        <div className="text-center">
          <p>Checking you subscription status...</p>
          <LoadIndicator
            visible={true}
            height={40}
            width={40}
          />
        </div>
      </Popup>




    </React.Fragment>
)}


const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1
};

const colCountByScreen2 = {
  xs: 1,
  sm: 2,
  md: 2,
  lg: 2
};
