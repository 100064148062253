import React from "react";
import { Button } from 'devextreme-react/button';
import './Hero.scss'
import {useNavigate, useParams} from 'react-router-dom'
import devices from 'devextreme/core/devices';

export default function Hero(props) {
    const navigate = useNavigate();
    return (
      <div id={props.main_id} className="hero-area overlay" style={props.bg_video_src?{}:{backgroundImage: `url(${props.bg_image_src})` }}>
        {props.bg_video_src && <video autoplay muted loop className="hero-video">
         <source src={props.bg_video_src} type="video/mp4"/>
        </video> }
        <div
          className={devices.current().deviceType === 'phone'?
            "block d-flex flex-column align-items-center justify-content-start mt-5":
            "block d-flex flex-column align-items-center justify-content-center"}
        >
          <h1>{props.title}</h1>
          <p className="mb-50">{props.subtitle}</p>
          <h2 className="text-primary">{props.slogan}</h2>
          <div className={devices.current().deviceType === 'phone'?"py-0":"pt-5"}></div>
          <Button
            id={props.cta_id}
            icon="fab fa-youtube"
            onClick={() => {navigate("/about/peek-inside")}}
            height="50px"
            width={devices.current().deviceType === 'phone'?"200px":"200px"}
            type="default"
            className={devices.current().deviceType === 'phone'?"mt-0":"mt-5"}
            text={props.cta_label}
          />
        </div>
      </div>
    );
}
