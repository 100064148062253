import { HomePage, DriversPage, CustomersPage, ProfilePage, FleetPage, SchedulePage, BillingPage, PricesPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/drivers',
        element: DriversPage
    },
    {
        path: '/fleet',
        element: FleetPage
    },
    {
        path: '/customers',
        element: CustomersPage
    },
    {
        path: '/schedule',
        element: SchedulePage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/billing',
        element: BillingPage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/prices',
        element: PricesPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
