import React from "react";
import { Button } from 'devextreme-react/button';
import './FeatureBox.scss'

export default function FeatureBox(props) {
    return (
      <div id={props.main_id} href={props.cta_link} className="feature-box-block">
        {props.icon && <div className="feature-box">
          {props.icon}
        </div>}
        <div id={"carousel"+props.main_id} className="carousel slide carousel-fade" data-bs-ride="carousel">
          <div className="carousel-inner">
            { props.slides.map( (slide, idx) =>
              <div key={"slide-"+slide} className={idx===0?"carousel-item active":"carousel-item"} style={{textAlign: "center"}}>
                <img src={slide} style={{maxHeight:"300px", height: "300px", maxWidth:"100%"}} alt="..."/>
              </div>
            ) }
          </div>
          <button className="carousel-control-prev text-dark" type="button" data-bs-target={"#"+"carousel"+props.main_id} data-bs-slide="prev">
            <i className="fas fa-chevron-left fa-3x"></i>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next text-dark" type="button" data-bs-target={"#"+"carousel"+props.main_id} data-bs-slide="next">
            <i className="fas fa-chevron-right fa-3x"></i>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="feature-box-content text-center">
          <h4>{props.title}</h4>
          <p className="marketing-list">{props.description}</p>
          <ul className="pt-3 w-100 text-start marketing-list">
            {props.features}
          </ul>
          {props.cta_label && <Button href={props.cta_link} size="lg" variant="outline-primary" className="mt-5">{props.cta_label}</Button>}
        </div>
      </div>
    );
}
