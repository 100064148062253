import {getUser} from './auth'

export const getApiUrl = () => {
  return 'https://fjeqva11t0.execute-api.us-east-1.amazonaws.com/prod'
};

export const getUserDetails = async (user, onSuccess, onError, retries=3) => {
  try {
    let q = '/user'
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      r.user_details.sub = user.signInUserSession.idToken.payload.sub;
      r.user_details.name = user.signInUserSession.idToken.payload.name;
      r.user_details.email = user.signInUserSession.idToken.payload.email;
      r.user_details.phone = user.signInUserSession.idToken.payload.phone_number;
      r.user_details.image = user.signInUserSession.idToken.payload.picture;

      r.user_details.scheduling_settings = (!r.user_details.scheduling_settings)?{}:r.user_details.scheduling_settings;
      //These 2 settings must be completed by the user.
      //r.user_details.scheduling_settings.base_address = (!r.user_details.scheduling_settings.base_address)?null:r.user_details.scheduling_settings.base_address;
      //r.user_details.scheduling_settings.cancelation_policy_text = (!r.user_details.scheduling_settings.cancelation_policy_text)?null:r.user_details.scheduling_settings.cancelation_policy_text;
      r.user_details.scheduling_settings.traffic_model = (!r.user_details.scheduling_settings.traffic_model)?"pessimistic":r.user_details.scheduling_settings.traffic_model;
      r.user_details.scheduling_settings.max_service_radius = (!r.user_details.scheduling_settings.max_service_radius)?100:r.user_details.scheduling_settings.max_service_radius;
      r.user_details.scheduling_settings.route_buffer_percentage = (!r.user_details.scheduling_settings.route_buffer_percentage)?10:r.user_details.scheduling_settings.route_buffer_percentage;
      r.user_details.scheduling_settings.route_buffer_min_time = (!r.user_details.scheduling_settings.route_buffer_min_time)?1:r.user_details.scheduling_settings.route_buffer_min_time;
      r.user_details.scheduling_settings.route_buffer_max_time = (!r.user_details.scheduling_settings.route_buffer_max_time)?10:r.user_details.scheduling_settings.route_buffer_max_time;
      r.user_details.scheduling_settings.passenger_load_time = (!r.user_details.scheduling_settings.passenger_load_time)?10:r.user_details.scheduling_settings.passenger_load_time;
      r.user_details.scheduling_settings.passenger_unload_time = (!r.user_details.scheduling_settings.passenger_unload_time)?5:r.user_details.scheduling_settings.passenger_unload_time;
      r.user_details.scheduling_settings.max_pick_up_wait_time = (!r.user_details.scheduling_settings.max_pick_up_wait_time)?60:r.user_details.scheduling_settings.max_pick_up_wait_time;
      r.user_details.scheduling_settings.timezone = (!r.user_details.scheduling_settings.timezone)?Intl.DateTimeFormat().resolvedOptions().timeZone:r.user_details.scheduling_settings.timezone;
      onSuccess(r.user_details);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const updateUserDetails = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/user'
    const response = await fetch(getApiUrl()+q, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      r.user_details.sub = user.signInUserSession.idToken.payload.sub;
      r.user_details.name = user.signInUserSession.idToken.payload.name;
      r.user_details.email = user.signInUserSession.idToken.payload.email;
      r.user_details.phone = user.signInUserSession.idToken.payload.phone_number;
      r.user_details.image = user.signInUserSession.idToken.payload.picture;
      onSuccess(r.user_details);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getProfile = async (id, onSuccess, onError, retries=3) => {
  try {
    let q = '/public/profile/'+id
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.profile);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getResources = async (user, onSuccess, onError, retries=3) => {
  try {
    let q = '/resource'
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resources);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const updateResource = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/resource'
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resource);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const deleteResource = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/resource'
    const response = await fetch(getApiUrl()+q, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resource);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getPublicAppointmentById = async (id, appointmentId, onSuccess, onError, retries=3) => {
  try {
    let q = '/public/profile/'+id+'/appointment/'+appointmentId
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.appointment);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}

export const updatePublicAppointmentById = async (id, appointmentId, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/public/profile/'+id+'/appointment/'+appointmentId
    let d = Object.assign({}, data);
    if (d['flightDate']) {
      if (!(d['ride_details']['notes'])) {
        d['ride_details']['notes'] = ""
      }
      if (!(d['ride_details']['notes'].includes("flight; Departure time: "))) {
        let flight_type = d['flight_type'].charAt(0).toUpperCase() + d['flight_type'].slice(1);
        d['ride_details']['notes'] = d['ride_details']['notes'] + "; "+ flight_type +" flight; Departure time: "+d['flightDate'].toLocaleString();
      }
      delete d['flight_type'];
      delete d['flightDate'];
    }
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(d)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resource);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}

export const getPricesForAppointment = async (id, appointment, onSuccess, onError) => {
  try {
    let q = '/public/profile/'+id+'/prices'
    appointment.owner_id = id
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(appointment)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.prices);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}


export const getAvailableResources = async (user, appointment, onSuccess, onError) => {
  try {
    appointment['owner_id'] = user.username
    let q = '/resource/available'
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken
      },
      body: JSON.stringify(appointment)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  }
}

export const getAppointments = async (user, resources, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment'
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.appointments, resources);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getAppointment = async (user, appointment_id, resources, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment/'+appointment_id
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.appointment, resources);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const updateAppointment = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment'
    let d = Object.assign({}, data);
    console.log(d);
    delete d['appointment_title'];
    delete d['driver'];
    delete d['vehicle'];
    // delete d['customer_data'];
    if (d['flightDate']) {
      d['ride_details']['notes'] = d['ride_details']['notes'] + ";Flight departure time: "+d['flightDate'].toLocaleString()
      delete d['flightDate'];
    }

    if (d.appointment_type !== 'ride_full_roundtrip') {
      delete d['returnDate'];
      delete d['destinationDate'];
      delete d['destination_address'];
      delete d['ride_details']['destination_address'];
      delete d['ride_details']['return_lenght'];
    }
    console.log(d);

    const response = await fetch(getApiUrl()+q, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(d)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.resource);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const inviteDriversToAppointment = async (user, appointment_id, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment/'+appointment_id+"/invite-drivers"

    const response = await fetch(getApiUrl()+q, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify({})
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess({});
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const deleteAppointment = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/appointment'
    const response = await fetch(getApiUrl()+q, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.appointment);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const getPrices = async (user, onSuccess, onError, retries=3) => {
  try {
    let q = '/prices'
    const response = await fetch(getApiUrl()+q, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      }
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const updatePrice = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/prices'
    const response = await fetch(getApiUrl()+q, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r.price);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}

export const deletePrice = async (user, data, onSuccess, onError, retries=3) => {
  try {
    let q = '/prices'
    const response = await fetch(getApiUrl()+q, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(data)
    });
    const r = await response.json();
    if (response.ok) {
      onSuccess(r);
    } else {
      onError(response);
    }
  } catch (error) {
    onError(error);
  } finally {
  }
}


const dateToLocalTZ = (data) => {
  const dateOffset = (new Date()).getTimezoneOffset();
  var dsd = new Date(data);
  dsd.setTime(dsd.getTime() + dateOffset * 60000);
  return dsd;
}

export const appointmentTypes = [
  {
    text: 'One way with drop-off time',
    id: 'ride_full_arrival_time',
  },
  {
    text: 'One way with pickup time',
    id: 'ride_full_departure_time',
  },
  {
    text: 'Round trip',
    id: 'ride_full_roundtrip',
  },
  {
    text: 'Unavailable',
    id: 'unavailable',
  },
];

export const appointmentStatusValues = [
  {
    text: 'Submitted',
    id: 'pending',
    owner_allowed: true,
    customer_allowed: true,
    requires_vehicle: false,
    requires_existing_appointment: false,
  },
  {
    text: 'Available',
    id: 'available',
    owner_allowed: true,
    customer_allowed: false,
    requires_vehicle: false,
    requires_existing_appointment: false,
  },
  {
    text: 'Rejected',
    id: 'rejected',
    owner_allowed: true,
    customer_allowed: false,
    requires_vehicle: false,
    requires_existing_appointment: true,
  },
  {
    text: 'Canceled',
    id: 'canceled',
    owner_allowed: false,
    customer_allowed: true,
    requires_vehicle: false,
    requires_existing_appointment: true,
  },
  {
    text: 'Confirmed - Paid',
    id: 'payed',
    owner_allowed: true,
    customer_allowed: false,
    requires_vehicle: false,
    requires_existing_appointment: false,
  },
  {
    text: 'Confirmed - Unpaid',
    id: 'unpayed',
    owner_allowed: true,
    customer_allowed: false,
    requires_vehicle: false,
    requires_existing_appointment: false,
  },
  {
    text: 'Completed',
    id: 'completed',
    owner_allowed: true,
    customer_allowed: true,
    requires_vehicle: true,
    requires_existing_appointment: true,
  },
];
