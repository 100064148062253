import { Tooltip } from 'devextreme-react/tooltip';

const RideTypePill = ({data, displayTooltip=false}) => {
     
    let jsx
    const iconClasses = 'text-light'
    const pillStyle = 'm-0 me-2 rounded-pill opacity-75 d-flex align-items-center justify-content-center schedule-type-color '+ data.id
    const popoverId = crypto.randomUUID()

    const popover = (
        <Tooltip
            target={`#${data.id+popoverId}`}
            showEvent="dxclick dxpointerover"
            hideEvent="mouseleave"
            hideOnOutsideClick={false}
            position="top"
        >{data.text}</Tooltip>
    )

    if(data.id === 'ride_full_arrival_time') {
        jsx = (
            <span id={data.id+popoverId} className={`${pillStyle}`} style={{width: '47px'}}>
                <i className={`fas fa-walking ${iconClasses}`}></i> &nbsp;
                <i className={`fa fa-arrow-right ${iconClasses}`}></i>
            </span>
        )
    }
    if(data.id === 'ride_full_departure_time') {
        jsx = (
            <span id={data.id+popoverId} className={`${pillStyle}`} style={{width: '47px'}}>
                <i className={`fa fa-arrow-right ${iconClasses}`}></i> &nbsp;
                <i className={`fas fa-walking ${iconClasses}`}></i>
            </span>
        )

    }
    if(data.id === 'ride_full_roundtrip') {
        jsx = (
            <span id={data.id+popoverId} className={`${pillStyle}`} style={{width: '47px'}}>
                <i className={`fas fa-arrows-alt-h ${iconClasses}`}></i> &nbsp;
                <i className={`fas fa-walking ${iconClasses}`}></i>
            </span>
        )
    }
    if(data.id === 'unavailable') {
      jsx = (
          <span id={data.id+popoverId} className={`${pillStyle}`} style={{width: '47px'}}>
              <i className={`fas fa-car ${iconClasses} text-muted position-absolute`}></i> &nbsp;
              <i className={`fas fa-slash ${iconClasses} text-muted`}></i>
          </span>
      )
  }
    return (
        <div /*key={data.id+popoverId}*/ className="d-inline-block">
            {jsx}
            {displayTooltip && popover}
        </div>
    )
  }

export default RideTypePill