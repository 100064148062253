import React from "react";
import { Button } from 'devextreme-react/button';
import './ScheduleResourceCell.scss'
import {useNavigate} from 'react-router-dom'

export default function ScheduleResourceCell(props) {
    const userData = null;
    const navigate = useNavigate();
    return (
      <>
      {props.data.data.resource_type === "vehicle" &&
        <div className="resource-cell d-flex flex-column align-items-center justify-content-center">
          <div>
          {(props.data.data.resource_details && props.data.data.resource_details.picture) ? <img
            alt={''}
            src={props.data.data.resource_details.picture}
          />:
          <img
            alt={''}
            src={'/DefaultCar.png'}
          />}
          </div>
          <p className="m-0 w-100 resource-cell-name" style={{textWrap: 'warp'}} >{props.data.data.resource_details.name}</p>
        </div>
      }
      </>
    );
}
