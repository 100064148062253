import React from "react";
import { Button } from 'devextreme-react/button';
import './VehicleCard.scss'
import {useNavigate} from 'react-router-dom'
import { confirm } from "devextreme/ui/dialog"

export default function VehicleCard(props) {
    const userData = null;
    const navigate = useNavigate();
    return (
      <div className="dx-card vehicle-card d-flex flex-column align-items-center h-100">
        <div className={'pt-2 d-flex flex-column align-items-center'}>
          <div className={'vehicle-card-image'}>
            {(props.vehicle.resource_details && props.vehicle.resource_details.picture) ? <img
              alt={''}
              src={props.vehicle.resource_details.picture}
            />:
            <img
              alt={''}
              src={'/DefaultCar.png'}
            />}
          </div>
        </div>

        <h5 className="pt-1 ms-5 w-100">{props.vehicle.resource_details.name}</h5>
        <p className="ms-5 w-100 text-muted">Up to {props.vehicle.resource_details.max_passengers} passengers and {props.vehicle.resource_details.max_luggages} luggages</p>
        <hr className="vehicle-card-divider"/>
        <div className="my-2" >
          <Button
            className="mx-2"
            type="normal"
            icon={props.vehicle.resource_details.active?"fas fa-pause":"fas fa-play"}
            stylingMode="outlined"
            text={props.vehicle.resource_details.active?"Suspend":"Resume"}
            disabled={props.loading}
            onClick={() => {props.onSuspendResume(props.vehicle);}}/>
          <Button
            className="mx-2"
            type="normal"
            icon="fas fa-pen"
            stylingMode="outlined"
            text="Edit"
            disabled={props.loading}
            onClick={() => {props.onEdit(props.vehicle);}}/>

          <Button
            className="mx-2"
            type="danger"
            icon="far fa-trash-alt"
            stylingMode="outlined"
            text="Delete"
            disabled={props.loading}
            onClick={(e) => {
                console.log(e)
                let result = confirm("<i>Are you sure?</i>", "Confirm deletion");
                result.then(dialogResult => dialogResult && props.onDelete(props.vehicle) )
              }
            }
            />
        </div>
      </div>
    );
}
