import AWS from 'aws-sdk';

const BUCKET_NAME = 'com.getcarride';
const BUCKET_REGION = 'us-east-1';
var s3 = null;
var userIdentityId = null;
var userSub = null;

AWS.config.region = BUCKET_REGION;

export const setS3CognitoUserCredentials = (creds, sub) => {
  AWS.config.credentials = creds;
  AWS.config.getCredentials(function(err) {
    if (err) console.log(err.stack); // credentials not loaded
    else {
      s3 = new AWS.S3();
      userIdentityId = AWS.config.credentials.identityId;
      userSub = sub;
    }
  })
}

export const uploadFileChunk = (fileData, uploadInfo, destinationDirectory) => {
  let promise = null;
  if (uploadInfo.chunkIndex === 0) {
    const filePath = destinationDirectory.path ? `${destinationDirectory.path}/${fileData.name}` : fileData.name;
    promise = s3
        .createMultipartUpload({
          Bucket: BUCKET_NAME,
          Key: filePath
         })
        .promise()
        .then((response) => {
            uploadInfo.customData.uploadId = response.UploadId;
            uploadInfo.customData.key = response.Key;
        });
  } else {
    promise = Promise.resolve();
  }
  promise = promise.then(() => {
    return s3.uploadPart({
       Body: uploadInfo.chunkBlob,
       Bucket: BUCKET_NAME,
       Key: uploadInfo.customData.key,
       PartNumber: uploadInfo.chunkIndex + 1,
       UploadId: uploadInfo.customData.uploadId
     }).promise()
  })
  promise = promise.then((response) => {
     return response
  });
  if (uploadInfo.chunkIndex === uploadInfo.chunkCount - 1) {
    promise = promise.then(() => {
      return s3.listParts({
       Bucket: BUCKET_NAME,
       Key: uploadInfo.customData.key,
       UploadId: uploadInfo.customData.uploadId
     }).promise()
    });
    promise = promise.then((response) => {
       const parts = []
       response.Parts.forEach((c) => {
         parts.push({
           ETag: c.ETag,
           PartNumber: c.PartNumber
         });
        });
       uploadInfo.customData.parts = parts
       return s3.completeMultipartUpload({
          Bucket: BUCKET_NAME,
          Key: uploadInfo.customData.key,
          MultipartUpload: {Parts: uploadInfo.customData.parts},
          UploadId: uploadInfo.customData.uploadId
        }).promise()
    }).then((response) => {
      }).catch((error) => {
        console.log(error)
      });
  }
  return promise;
}

export const deleteItem = (item) => {
    return s3
        .deleteObject({
            Bucket: BUCKET_NAME,
            Key: item.key,
        })
        .promise()
        .then((response) => {

        });
};

export const createDirectory = (parentDirectory, name) => {
  return s3
      .putObject({
          Bucket: BUCKET_NAME,
          Key: userIdentityId+'/'+parentDirectory.key+name+"/",
      })
      .promise()
      .then((response) => {

      });
}

export const downloadItems = (items) => {
    return s3
        .deleteObject({
            Bucket: BUCKET_NAME,
            Key: items.key,
        })
        .promise()
        .then((response) => {

        });
};

export const getItems = (pathInfo) => {
    return s3
        .listObjectsV2({
            Bucket: BUCKET_NAME,
            Delimiter: '/',
            Prefix: pathInfo.path !== '' ? userIdentityId+'/'+pathInfo.path+'/' : userIdentityId+'/',
        })
        .promise()
        .then((response) => {
            const chonkyFiles = [];
            const s3Objects = response.Contents.filter(element => {return element.Key !== (pathInfo.path !== '' ? pathInfo.path+'/' : '')});
            const s3Prefixes = response.CommonPrefixes.filter(element => {return element.Prefix !== userIdentityId+'/results/' });

            if (s3Objects) {
                chonkyFiles.push(
                    ...s3Objects.map(
                        (object) => ({
                            key: (object.Key),
                            name: (object.Key).split('/').filter(element => element).reverse()[0],
                            modDate: object.LastModified,
                            size: object.Size

                        })
                    )
                );
            }

            if (s3Prefixes) {
                chonkyFiles.push(
                    ...s3Prefixes.map(
                        (prefix): FileData => ({
                            key: prefix.Prefix,
                            name: (prefix.Prefix).split('/').filter(element => element).reverse()[0],
                            isDirectory: true,

                        })
                    )
                );
            }

            return chonkyFiles;
        }).catch((error) => {
          console.log(error);

        });
};
