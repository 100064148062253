import React, { useState, useEffect } from "react";
import { Button } from 'devextreme-react/button';
import './RideQuestionnaire.scss'
import {useNavigate} from 'react-router-dom'

export default function RideQuestionnaire(props) {
    const navigate = useNavigate();
    const airport_mode = props.airport_mode;
    const round_trips_enabled = props.round_trips_enabled
    const [question, setQuestion] = useState()
    const [rideType, setRideType] = useState(null);
    const [flight, setFlight] = useState(null);

    useEffect( () => {
      if(round_trips_enabled) {
        return setQuestion('round_trip')
      }
      if(airport_mode) {
        return setQuestion('airport')
      }
      setQuestion('ride_type')
    }, [props])


    const renderRoundTripQuestion = () => {
      return (<>
        <div className="d-flex flex-column align-items-center h-100">
          <h5 className="pb-5">Are you booking a round trip ride?</h5>
          <div className="d-flex flex-column justify-content-between align-items-center" style={{minWidth: "150px"}}>
            <Button
              className="w-100 my-2"
              type="default"
              stylingMode="outlined"
              text="Yes"
              size="lg"
              onClick={()=>{
                setRideType('ride_full_roundtrip');
                if (airport_mode && round_trips_enabled) {
                  setQuestion("airport");
                } else {
                  props.onValueChanged({ride_type:'ride_full_roundtrip', flight_type:flight})
                }
              }}
            />
            <Button
              className="w-100 my-2"
              type="default"
              stylingMode="outlined"
              text="No"
              size="lg"
              onClick={()=>{
                setRideType(null)
                if (airport_mode && !round_trips_enabled) {
                  setQuestion("ride_type_airport");
                } else if(airport_mode && round_trips_enabled) {
                  setQuestion("airport")
                } else {
                  setQuestion("ride_type");
                }
              }}
            />
            <Button
              className="w-100 my-2"
              type="normal"
              stylingMode="text"
              text="back"
              icon="spinprev"
              size="lg"
              onClick={()=>{
                if(airport_mode && !round_trips_enabled) {
                  setQuestion('airport')
                } else {
                  props.onBack()
                }
              }}
            />

          </div>
        </div>

      </>);
    }

    const renderAirportQuestion = () => {
      return (<>
        <div className="d-flex flex-column align-items-center h-100">
          <h5 className="pb-5">Are you booking an airport ride?</h5>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <Button
              className="w-100 my-2"
              type="default"
              stylingMode="outlined"
              text="Yes - Domestic Flight"
              size="lg"
              onClick={()=>{
                setFlight('domestic');
                if (rideType===null) {
                  if(!round_trips_enabled) {
                    return setQuestion('round_trip')
                  }
                  setQuestion("ride_type_airport");
                } else {
                  props.onValueChanged({ride_type:rideType, flight_type:'domestic'})
                }
              }}
            />
            <Button
              className="w-100 my-2"
              type="default"
              stylingMode="outlined"
              text="Yes - International Flight"
              size="lg"
              onClick={()=>{
                setFlight('international');
                if (rideType===null) {
                  if(!round_trips_enabled) {
                    return setQuestion('round_trip')
                  }
                  setQuestion("ride_type_airport");
                } else {
                  props.onValueChanged({ride_type:rideType, flight_type:'international'})
                }
              }}
            />
            <Button
              className="w-100 my-2"
              type="default"
              stylingMode="outlined"
              text="No"
              size="lg"
              onClick={()=>{
                setFlight(null);
                if (rideType===null) {
                  setQuestion("ride_type");
                } else {
                  props.onValueChanged({ride_type:rideType, flight_type:null})
                }
              }}
            />
            <Button
              className="w-100 my-2"
              type="normal"
              stylingMode="text"
              text="back"
              icon="spinprev"
              size="lg"
              onClick={()=>{
                if(airport_mode && !round_trips_enabled){
                  if(question === 'airport') {
                    props.onBack()
                  }
                } else {
                  setQuestion("round_trip");
                }
              }}
            />
          </div>
        </div>

      </>);
    }

    const renderRideTypeQuestion = () => {
      return (<>
        <div className="d-flex flex-column align-items-center h-100">
          <h5 className="pb-5 text-center">Do you want to be picked up at a specified time or dropped off at a specified time?</h5>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <Button
              className="w-100 my-2"
              type="default"
              stylingMode="outlined"
              text="Pick me up at specified time"
              size="lg"
              onClick={()=>{
                setRideType('ride_full_departure_time')
                props.onValueChanged({ride_type:'ride_full_departure_time', flight_type:flight})
              }}
            />
            <Button
              className="w-100 my-2"
              type="default"
              stylingMode="outlined"
              text="Drop me off at specified time"
              size="lg"
              onClick={()=>{
                setRideType('ride_full_arrival_time')
                props.onValueChanged({ride_type:'ride_full_arrival_time', flight_type:flight})
              }}
            />
            <Button
              className="w-100 my-2"
              type="normal"
              stylingMode="text"
              text="back"
              icon="spinprev"
              size="lg"
              onClick={()=>{
                if (airport_mode) {
                  setQuestion("airport");
                } else if(round_trips_enabled) {
                  setQuestion("round_trip");
                } else {
                  props.onBack()
                }
              }}
            />

          </div>
        </div>

      </>);
    }

    const renderRideTypeAirportQuestion = () => {
      return (<>
        <div className="d-flex flex-column align-items-center h-100">
          <h5 className="pb-5 text-center">Do you want to be picked up or dropped off at the airport?</h5>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <Button
              className="w-100 my-2"
              type="default"
              stylingMode="outlined"
              text="Pick me up"
              size="lg"
              onClick={()=>{
                setRideType('ride_full_departure_time')
                props.onValueChanged({ride_type:'ride_full_departure_time', flight_type:flight})
              }}
            />
            <Button
              className="w-100 my-2"
              type="default"
              stylingMode="outlined"
              text="Drop me off"
              size="lg"
              onClick={()=>{
                setRideType('ride_full_arrival_time')
                props.onValueChanged({ride_type:'ride_full_arrival_time', flight_type:flight})
              }}
            />
            <Button
              className="w-100 my-2"
              type="normal"
              stylingMode="text"
              text="back"
              icon="spinprev"
              size="lg"
              onClick={()=>{
                if(airport_mode && !round_trips_enabled) {
                  setQuestion('round_trip')
                } else {
                  setQuestion("airport");
                }
              }}
            />

          </div>
        </div>

      </>);
    }

    return (<>
      {question==="round_trip" && renderRoundTripQuestion()}
      {question==="airport" && renderAirportQuestion()}
      {question==="ride_type" && renderRideTypeQuestion()}
      {question==="ride_type_airport" && renderRideTypeAirportQuestion()}
    </>);
}
