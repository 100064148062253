import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'devextreme/data/odata/store';
import FileUploader from 'devextreme-react/file-uploader';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Editing,
  Button as DGButton,
  Lookup,
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import {Form,
    Label,
    SimpleItem,
    EmptyItem,
    GroupItem,
    ButtonItem,
    RequiredRule,
    EmailRule} from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';
import {getResources, updateResource, deleteResource} from '../../api/api'
import { addPlusOnePrefix } from '../../utils/miscUtil'
import {uploadFileChunk} from '../../api/s3'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

export default function Drivers() {
  const { user, refreshSession } = useAuth();
  const [driverList, setDriversList] = useState([]);
  const [currentDriver, setCurrentDriver] = useState(null);
  const fileExtensions = ['.jpg', '.jpeg', '.png'];
  const formRef = useRef(null);

  const onSave = async () => {
    setSaveButtonOptions({
      disabled: true,
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });
    
    const updaterObj = currentDriver
    const modifiedPhoneNo = addPlusOnePrefix(updaterObj.resource_details.phone)
    if(modifiedPhoneNo) {
      updaterObj.resource_details.phone = modifiedPhoneNo
    }    

    updateResource(user, updaterObj, onSaveSuccess, onError);
  }

  const onCloseDriverDialog = () => {
    setCurrentDriver(null);
  }

  const onCancel = () => {
    setCurrentDriver(null);
  }

  const [cancelButtonOptions, setCancelButtonOptions] = useState({
    disabled: false,
    text: 'Cancel',
    width: '100%',
    onClick: onCancel,
  });

  const [saveButtonOptions, setSaveButtonOptions] = useState({
    disabled: false,
    text: 'Save',
    type: 'default',
    width: '100%',
    onClick: onSave,
  });

  const onError = useCallback((error) => {
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onSaveSuccess = useCallback((data) => {
    getResources(user, onGetResourcesSuccess, onError);
    setCurrentDriver(null);
  }, [])

  const onGetResourcesSuccess = useCallback((data) => {
    let lst = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].resource_type === 'driver') {
        lst.push(data[i])
      }
    }
    setDriversList(lst);
  }, [])

  const onUploadChunk = (file, uploadInfo) => {
    return uploadFileChunk(file, uploadInfo, {path:'images/'+currentDriver.resource_id});
  }

  const onUploaded = (e) => {
    let data = {...currentDriver};
    if (data === null) {
      data = {}
    }
    data.resource_details.picture = "https://s3.amazonaws.com/com.getcarride/images/"+currentDriver.resource_id+"/"+encodeURIComponent(e.file.name).replace(/%20/g,'+');
    setCurrentDriver(data);
  }

  const onSavingDatagrid = (e) => {
    for (let i = 0; i < e.changes.length; i++) {
      if (e.changes[i].type === "remove") {
        deleteResource(user, e.changes[i].key, onSaveSuccess, onError);
      }
    }
  }

  const cellRender = (data) => {
    return (
      <div
        style={{
          backgroundImage: `url("${data.value?data.value:'/DefaultAvatar.png'}")`,
          backgroundSize: 'cover',
          width: "50px",
          height: "50px",
          borderRadius: "25px",
        }}
      />

    );
  }

  const backupCellRender = (data) => {
    return (
      <div>
        {data.value?"Y":"N"}
      </div>
    );
  }

  useEffect(() => {
    if (user.signInUserSession) {
      getResources(user, onGetResourcesSuccess, onError);
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  useEffect(() => {
    if (currentDriver) {
      setCancelButtonOptions({
        disabled: false,
        text: 'Cancel',
        width: '100%',
        onClick: onCancel,
      });
      setSaveButtonOptions({
        disabled: !(currentDriver.resource_details.name && currentDriver.resource_details.email && currentDriver.resource_details.phone),
        text: 'Save',
        type: 'default',
        width: '100%',
        onClick: onSave,
      });
    }
  }, [currentDriver]);

  const driverBackupOptions = {text: "Backup"};

  const onFormDataChanged = (e) => {
    setSaveButtonOptions({
      disabled: !(currentDriver.resource_details.name && currentDriver.resource_details.email && currentDriver.resource_details.phone),
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });
    //console.log(e);
  }

  const phoneEditorOptions = { mask: '+0 (000) 000-0000' };

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center content-block">
        <h2 className={''}>My Drivers</h2>
        <Button
          type="default"
          text="Add Driver"
          onClick={() => {
            setCurrentDriver(
              {
                resource_id: crypto.randomUUID(),
                resource_details: {},
                resource_type: 'driver',
              });
          }}
        />
      </div>


      <DataGrid
        className={'dx-card wide-card'}
        dataSource={driverList}
        showBorders={false}
        focusedRowEnabled={false}
        defaultFocusedRowIndex={0}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        onRowUpdating={() => console.log('RowUpdating')}
        onRowUpdated={() => console.log('RowUpdated')}
        onSaving={onSavingDatagrid}
      >
        <Paging defaultPageSize={50} />
        <Pager showPageSizeSelector={true} showInfo={true} />
        { false && <FilterRow visible={true} />}

        <Column
          dataField={'resource_details.picture'}
          width={80}
          caption={'Picture'}
          hidingPriority={4}
          cellRender={cellRender}
        />
        <Column
          dataField={'resource_details.name'}
          caption={'Name'}
          hidingPriority={3}
        />
        <Column
          dataField={'resource_details.backup'}
          caption={'Backup'}
          hidingPriority={2}
          cellRender={backupCellRender}
        />
        <Column
          dataField={'resource_details.email'}
          caption={'Email'}
          hidingPriority={1}
        />
        <Column
          dataField={'resource_details.phone'}
          caption={'Phone'}
          hidingPriority={0}
        />
        <Column type="buttons">
          <DGButton
              text="Edit"
              icon="fas fa-pen fs-6"
              hint="Edit"
              onClick={(e) => {
                setCurrentDriver(e.row.data);
              }}
          />
          <DGButton name="delete" />
        </Column>
        <Editing
          allowDeleting={true}
          mode="popup">
        </Editing>
      </DataGrid>

      <Popup
        className="text-danger"
        visible={currentDriver !== null}
        onHidden={onCloseDriverDialog}
        dragEnabled={false}
        showCloseButton={false}
        showTitle={true}
        title={currentDriver&&currentDriver.owner_id?"Edit Driver":"Add Driver"}
        container=".dx-viewport"
        width={380}
        height={720}
      >
        <Position at="center" my="center"collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={saveButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelButtonOptions}
        />

        {currentDriver && <div className="">

          <div className={'d-flex flex-column align-items-center'}>
            <div className={'text-center form-avatar'}>
              {(currentDriver.resource_details && currentDriver.resource_details.picture) ? <img
                alt={''}
                src={currentDriver.resource_details.picture}
              />:
              <img
                alt={''}
                src={'/DefaultAvatar.png'}
              />}
            </div>
            <div className="d-flex text-center" style={{width: "170px"}}>
              <FileUploader
                selectButtonText="Update Image"
                labelText=""
                multiple={false}
                chunkSize={6000000}
                uploadChunk={onUploadChunk}
                onUploaded={onUploaded}
                allowedFileExtensions={fileExtensions}
              />
            </div>

          </div>
          <Form
            ref={formRef}
            id={'form'}
            formData={currentDriver}
            labelLocation={'top'}
            onFieldDataChanged={onFormDataChanged}
          >
            <SimpleItem dataField="resource_details.name">
              <Label text="Driver Name (Publicly Visible)"/>
              <RequiredRule message="Driver name is required" />
            </SimpleItem>
            <SimpleItem dataField="resource_details.email">
              <Label text="Email"/>
              <RequiredRule message="Email is required" />
              <EmailRule message="Email is invalid" />
            </SimpleItem>
            <SimpleItem
                dataField="resource_details.phone"
                editorOptions={phoneEditorOptions}
            >
              <Label text="Phone"/>
              <RequiredRule message="Phone is required" />
            </SimpleItem>
            <SimpleItem dataField="resource_details.backup" editorType="dxCheckBox" editorOptions={driverBackupOptions}>
              <Label visible={false} />
            </SimpleItem>
          </Form>
        </div>}
      </Popup>

    </React.Fragment>
)}
