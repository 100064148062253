import React from "react";
import './Title.scss'

export default function Title(props) {
    return (
      <div className="title text-center">
        <h2>{props.regular_text} <span className="dx-theme-accent-as-text-color">{props.highlighted_text}</span> </h2>
        <div className="fancy-border"></div>
      </div>
    );
}
