import React, { useEffect, useCallback, useState } from 'react';
import Tasks from '../../components/tasks/Tasks'
import { useAuth } from '../../contexts/auth';
import {getUserDetails} from '../../api/api'
import { useNavigate } from 'react-router-dom'
import notify from 'devextreme/ui/notify';
import './home.scss';

export default function Home() {
  const { user, refreshSession } = useAuth();
  const navigate = useNavigate()
  const [advanced_airport_questions, setAdvancedAirportQuestions] = useState(null)

  const onError = useCallback((error) => {
    if (error.status === 401) {
      refreshSession();
    }
    else {
      notify("An error has occured. Please try again later.", 'error', 2000);
      console.log(error);
    }
  }, [refreshSession]);

  const onGetUserDetailsSuccess = useCallback((data) => {
    if (data.max_number_vehicles === 0) {
      navigate('/billing')
    }
    data.scheduling_settings?.advanced_airport_questions ? setAdvancedAirportQuestions(true): setAdvancedAirportQuestions(false);
    console.log(data);

  }, [])


  useEffect(() => {
    if (user.signInUserSession) {
      getUserDetails(user, onGetUserDetailsSuccess, onError);
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  return (
    <React.Fragment>
      {advanced_airport_questions !== null && <Tasks showAirportRoundTripTasks={advanced_airport_questions}/>}
    </React.Fragment>
)}
