import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useCallback, useRef, useEffect } from 'react';

import { SideNavOuterToolbar as SideNavBarLayout, SingleCardBook } from './layouts';
import { Footer } from './components';

export default function Content() {
  let location = useLocation();
  const [skipRender, setSkipRender] = useState(false);
  useEffect(() => {
    if (location.pathname.startsWith("/book/")) {
      setSkipRender(true);
    } else {
      setSkipRender(false);
    }
  }, [location]);

  return (
    <>
    {skipRender?
      <>
        <Routes>
          <Route
            path='/book/:id'
            element={
              <SingleCardBook/>
            }
          />
          <Route
            path='/book/*'
            element={
              <SingleCardBook/>
            }
          />
        </Routes>
      </>:
    <SideNavBarLayout title={appInfo.title}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={element}
          />
        ))}
        <Route
          path='/book/:id'
          element={
            <SingleCardBook/>
          }
        />
        <Route
          path='/book/*'
          element={
            <SingleCardBook/>
          }
        />

        <Route
          path='*'
          element={<Navigate to='/home' />}
        />
      </Routes>
      <Footer>
        Copyright © 2011-{new Date().getFullYear()} {appInfo.title} Inc.
        <br />
        All trademarks or registered trademarks are property of their
        respective owners.
      </Footer>
    </SideNavBarLayout>}
    </>
  );
}
