import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import notify from 'devextreme/ui/notify';
import {Form,
    SimpleItem,
    Item,
    Label,
    GroupItem,
    ButtonItem,
    EmailRule,
    StringLengthRule,
    RequiredRule} from 'devextreme-react/form';
import { TextArea } from 'devextreme-react/text-area';
import './ContactForm.scss'

export default function ContactForm(props) {
  const [message, setMessage] = useState({"customer-id": props.customer_id});
  const [messageSent, setMessageSent] = useState(false);
  const formRef = useRef(null);


  const handleChange = (e) => {
    console.log(e);
    let new_message = Object.assign({}, message);
    new_message[e.dataField] = e.value;
    setMessage(new_message);
    console.log(new_message);
  }

  const onSendMessage = () => {
    sendButtonOptions.disabled = true;
    setSendButtonOptions({...sendButtonOptions});
    sendMessage(message);
  };

  const sendMessage = async (msg) => {
    console.log("sending message");
    console.log(msg);
    try {
      const response = await fetch('https://api.wdttx.com/prod/contact-us', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(msg)
      });
      const r = await response.json();
      console.log(r)
      if (r['statusCode'] === 200) {
        setMessage({"customer-id": props.customer_id})
        notify("Thank you. Your message was delivered successfuly. We will contact you as soon as possible.", 'success', 3000);
      } else {
        sendButtonOptions.disabled = false;
        setSendButtonOptions({...sendButtonOptions});
        notify("We cannot send your message. Please try again later.", 'error', 3000);
      }
    } catch (error) {
      sendButtonOptions.disabled = false;
      setSendButtonOptions({...sendButtonOptions});
      notify("We cannot send your message. Please try again later.", 'error', 3000);
    }
  }

  const [sendButtonOptions, setSendButtonOptions] = useState({
    disabled: false,
    icon: 'fas fa-share',
    text: 'Send',
    type: 'default',
    width: '50%',
    useSubmitBehavior: true,
    onClick: onSendMessage,
  });

  useEffect(() => {
    setSendButtonOptions({
      disabled: false,
      icon: 'fas fa-share',
      text: 'Send',
      type: 'default',
      width: '50%',
      useSubmitBehavior: true,
      onClick: onSendMessage,
    });
  }, [message]);

  return (
    <div className="contact-form">
      <Form id="contact-form" ref={formRef} onFieldDataChanged={handleChange}>
        <SimpleItem cssClass="p-0 m-0" dataField="name" editorType="dxTextBox">
          <Label text="Name" />
          <StringLengthRule min={2} message="Your name must have at least 2 letters" />
          <RequiredRule message="Please provide your name" />
        </SimpleItem>
        <SimpleItem dataField="email" editorType="dxTextBox">
          <Label text="Email" />
          <RequiredRule message="Please provide your email so we know how to reach you" />
          <EmailRule message="Email is invalid" />
        </SimpleItem>
        <SimpleItem dataField="subject" editorType="dxTextBox">
          <Label text="Subject" />
          <StringLengthRule min={2} message="The subject must have at least 5 letters" />
          <RequiredRule message="Please write the reason to contact us here" />
        </SimpleItem>
        <SimpleItem dataField="message" editorType="dxTextArea">
          <Label text="Message"/>
          <StringLengthRule min={2} message="The message must have at least 10 letters" />
          <RequiredRule message="Please write your message to us here" />
        </SimpleItem>
        <Item itemType="button" buttonOptions={sendButtonOptions} />
      </Form>
    </div>
  );
}
