import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { confirmAccount, resendConfirmationCode } from '../../api/auth';
import './ConfirmAccountForm.scss';
import { useAuth } from '../../contexts/auth';

export default function ConfirmAccountForm() {
  const navigate = useNavigate();
  const { signInNewAccount } = useAuth();
  const [loading, setLoading] = useState(false);

  const formData = useRef({ code: ''});

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { code } = formData.current;
    setLoading(true);

    try {
      const result = await confirmAccount(code);
      if (result.isOk) {
        const result2 = await signInNewAccount();
        if (result2.isOk) {
          setLoading(false);
          navigate('/home');
        } else {
          setLoading(false);
          notify(result.message, 'error', 2000);
        }
      } else {
        setLoading(false);
        notify(result.message, 'error', 2000);
      }
    } catch(error) {
        notify(error, 'error', 2000);
        setLoading(false);
    }
  }, [navigate, signInNewAccount]);

  const onResend = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await resendConfirmationCode();
      setLoading(false);

      if (result.isOk) {
        console.log(result);
        notify('Confirmation code resent to '+result.message.CodeDeliveryDetails.Destination, 'success', 2500);
      } else {
        notify(result.message, 'error', 2000);
      }
    } catch(error) {
        notify(error, 'error', 2000);
        setLoading(false);
    }
  }, []);


  return (
    <form className={'confirm-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'code'}
          editorType={'dxTextBox'}
          editorOptions={codeEditorOptions}
        >
          <RequiredRule message="Code is required" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Confirm'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Can't find the code? <Link onClick={onResend}>Resend</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const codeEditorOptions = { stylingMode: 'filled', placeholder: 'Confirmation Code', mode: 'text' };
