import { useState, useEffect, useRef, useCallback } from 'react'
import { LoadIndicator } from 'devextreme-react/load-indicator';
import {
    DataGrid, Column, Editing, Button as DGButton,
} from 'devextreme-react/data-grid';
import { IPopoverOptions, Popover } from 'devextreme-react/popover';
import { Tooltip } from 'devextreme-react/tooltip';
import {Form,
    Label,
    SimpleItem,
    EmptyItem,
    GroupItem,
    ButtonItem,
    RequiredRule,
    EmailRule} from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import TextBox from 'devextreme-react/text-box';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { useAuth } from '../../contexts/auth'
import { getPrices, updatePrice, deletePrice, getUserDetails, appointmentTypes } from '../../api/api';
import { convertRangeArrToFriendlyStr } from '../../utils/miscUtil'
import devices from 'devextreme/core/devices';
import RideTypePill from '../../components/icons/RideTypePill';

function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

const isPhone = devices.current().deviceType === 'phone'


const Prices = () => {
    const [loading, setLoading] = useState(true)
    const [pricesList, setPricesList] = useState([])
    const [currentPrice, setCurrentPrice] = useState(null)
    const [distanceRange, setDistanceRange] = useState()
    const [noOfPassengersRange, setNoOfPassengersRange] = useState([])
    const [luggageRange, setLuggageRange] = useState([])
    const [schedulingSettings, setSchedulingSettings] = useState([])
    const [passengerRangeEditorOptions, setPassengerRangeEditorOptions] = useState({})
    const [luggageRangeEditorOptions, setLuggageRangeEditorOptions] = useState({})
    const [distanceRangeEditorOptions, setDistanceRangeEditorOptions] = useState({})

    const dataGridRef = useRef()
    const formRef = useRef()
    const { user, refreshSession } = useAuth();

    useEffect( () => {
        if(currentPrice !== null){
            setPassengerRangeEditorOptions({
                start: (currentPrice.details && currentPrice.details.passenger_range && currentPrice.details.passenger_range[0]) ? currentPrice.details.passenger_range[0] : 0,
                end: (currentPrice.details && currentPrice.details.passenger_range && currentPrice.details.passenger_range[1]) ? currentPrice.details.passenger_range[1] : 50,
                min: 0,
                max: 50,
                tooltip: {
                    enabled: true,
                    format: function(value) { return value },
                    position: "top",
                    showMode: "onHover"
                },
                onValueChanged: debounce(function(e) {
                    const fromVal = formRef.current?.instance.getEditor('details.passenger_range').option('value')[0]
                    const toVal = formRef.current?.instance.getEditor('details.passenger_range').option('value')[1]
                    setNoOfPassengersRange([fromVal, toVal])
                }, 100)
            })
        }
    }, [currentPrice])

    useEffect( () => {
        if(currentPrice !== null){
            setLuggageRangeEditorOptions({
                start: (currentPrice.details && currentPrice.details.luggage_range && currentPrice.details.luggage_range[0]) ? currentPrice.details.luggage_range[0] : 0,
                end: (currentPrice.details && currentPrice.details.luggage_range && currentPrice.details.luggage_range[1]) ? currentPrice.details.luggage_range[1] : 50,
                min: 0,
                max: 50,
                tooltip: {
                    enabled: true,
                    format: function(value) { return value },
                    position: "top",
                    showMode: "onHover"
                },
                onValueChanged: debounce(function(e) {
                    const fromVal = formRef.current?.instance.getEditor('details.luggage_range').option('value')[0]
                    const toVal = formRef.current?.instance.getEditor('details.luggage_range').option('value')[1]
                    setLuggageRange([fromVal, toVal])
                }, 100)
            })
        }
    }, [currentPrice])

    useEffect( () => {
        if(currentPrice !== null){
            setDistanceRangeEditorOptions({
                start: (currentPrice.details && currentPrice.details.distance_range && currentPrice.details.distance_range[0]) ? currentPrice.details.distance_range[0] : 0,
                end: (currentPrice.details && currentPrice.details.distance_range && currentPrice.details.distance_range[1]) ? currentPrice.details.distance_range[1] : 50,
                min: 0,
                max: schedulingSettings.max_service_radius,
                tooltip: {
                    enabled: true,
                    format: function(value) { return value },
                    position: "top",
                    showMode: "onHover"
                },
                onValueChanged: debounce(function(e) {
                    const fromVal = formRef.current?.instance.getEditor('details.distance_range').option('value')[0]
                    const toVal = formRef.current?.instance.getEditor('details.distance_range').option('value')[1]
                    setDistanceRange([fromVal, toVal])
                    // console.log("details: ", pricesList)
                }, 100)
            })
        }
    }, [currentPrice])

    useEffect(() => {
        if (user.signInUserSession) {
            getPrices(user, (e) => {setPricesList(e.prices); setLoading(false)}, err => console.log('onErr', err))
            getUserDetails(user, d => setSchedulingSettings(d.scheduling_settings))
        }
    }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

    const insertRow = e => {
        if(!formRef.current?.instance.validate().isValid) return // form validation

        if(currentPrice.price_id){
            return updateRow()
        }
        setLoading(true)
        
        let updaterObj = {}
        updaterObj['price_id'] = crypto.randomUUID()
        updaterObj['details'] = currentPrice.details

        // return console.log('updaterObj', updaterObj)
        updatePrice(
            user, 
            updaterObj, 
            r => {
                console.log('on insert success: ', r)
                setLoading(false)
                getPrices(user, (e) => {setPricesList(e.prices); console.log('***********')}, err => console.log('onErr', err))
                setCurrentPrice(false)
            }, 
            err => console.warn('err: ', err)
        )
    }

    const updateRow = e => {
        setLoading(true)
        updatePrice(user, currentPrice, 
            r => {
                getPrices(user, (e) => {setPricesList(e.prices); console.log('***********')}, err => console.log('onErr', err))
                console.log('on update success: ', r)
                setCurrentPrice(null)
                setLoading(false)
            }, 
            err => console.warn('err: ', err))
    }

    const removeRow = e => {
        setLoading(true)
        deletePrice(
            user, e.data, 
            data => {
                console.log('on delete success: ', data)
                setLoading(false)
            }, 
            err => console.warn(err)
        )
    }

    const renderRideTypeDisplayVal =  val => {
            const iconClasses = 'text-muted'
            const pillStyle = 'm-0 mb-2 me-2 px-2 py-1 border border-2 rounded-pill'
            if(val.details.ride_type_list) {
                let customizedVals = []
                for(let x of val.details.ride_type_list){
                    const filteredRT = appointmentTypes.filter(rt => rt.id === x)
                    customizedVals.push(filteredRT[0])
                }

                return (
                    <div>
                       { customizedVals.map( item => <RideTypePill key={item.id+crypto.randomUUID()} data={item} displayTooltip={true} /> ) } 
                    </div>
                )

            } else {
                // Backward compatibility - data from 'val.details.ride_type'
                const filteredRT = appointmentTypes.filter(rt => rt.id === val.details.ride_type)
                if(filteredRT && !filteredRT[0]) return
                return <RideTypePill key={filteredRT[0].id+crypto.randomUUID()} data={filteredRT[0]} displayTooltip={true} />       
            }
            
            
    }

    const renderRideTypeCell = useCallback( cell => {
        const styleClasses = 'fa-sm me-2 fa-fw text-muted'
        if(isPhone){
            return <>
                <div className="m-0">{cell.displayValue}</div>
                <div className="d-flex flex-column gap-1 ms-1 pt-3">
                    <div><i class={`fas fa-users ${styleClasses}`}></i>{convertRangeArrToFriendlyStr(cell.key.details.passenger_range)}</div>
                    <div><i class={`fas fa-ruler ${styleClasses}`}></i>{convertRangeArrToFriendlyStr(cell.key.details.distance_range)}</div>
                    <div><i class={`fas fa-luggage-cart ${styleClasses}`}></i>{convertRangeArrToFriendlyStr(cell.key.details.luggage_range)}</div>
                </div>
            </>
        } else {
            return cell.displayValue
        }
    }, [])


    if(!loading) {
        return (
            <div className="content-block mt-5">
                <div className="d-flex flex-row justify-content-between align-items-center content-block">
                    <h2 className={''}>Prices</h2>
                    <Button
                        type="default"
                        text="Add Price"
                        onClick={() => {
                            setCurrentPrice({})
                        }}
                    />
                </div>
                <DataGrid
                    ref={dataGridRef}
                    dataSource={pricesList}
                    readOnly={true}
                    showSortIndexes={true}
                    allowColumnResizing={true}
                    columnHidingEnabled={true}
                    onRowRemoved={removeRow}
                >  
                    <Column
                       dataField="details.ride_type_list"
                       caption="Ride Type"
                       dataType="object"
                       calculateDisplayValue={renderRideTypeDisplayVal}
                       allowEditing={false}
                       cellRender={renderRideTypeCell}
                    />
                    <Column
                        dataField="details.passenger_range"
                        caption="Passenger Range"
                        dataType="text"
                        calculateDisplayValue={val =>  convertRangeArrToFriendlyStr(val.details.passenger_range)}
                        allowEditing={false}
                        visible={!isPhone}
                    />
                    <Column
                        dataField="details.distance_range"
                        caption="Distance Range"
                        dataType="text"
                        calculateDisplayValue={val =>  convertRangeArrToFriendlyStr(val.details.distance_range)}
                        allowEditing={false}
                        visible={!isPhone}
                    />
                    <Column
                        dataField="details.luggage_range"
                        caption="Luggage Range"
                        dataType="text"
                        calculateDisplayValue={val =>  convertRangeArrToFriendlyStr(val.details.luggage_range)}
                        allowEditing={false}
                        visible={!isPhone}
                    />
                    <Column
                       dataField="details.day_card_price"
                       caption="Day Card Price"
                       dataType="text"
                       allowEditing={false}
                    />
                    <Column
                       dataField="details.day_card_payment_link"
                       caption="Day Card payment link"
                       dataType="text"
                       allowEditing={false}
                    />
                    <Column
                        dataField="details.day_cash_price"
                        caption="Day Cash Price"
                        dataType="text"
                        allowEditing={false}
                        visible={schedulingSettings.cash_prices_enabled}
                    />
                    <Column
                       dataField="details.night_card_price"
                       caption="Night Card Price"
                       dataType="text"
                       allowEditing={false}
                    />
                    <Column
                       dataField="details.night_card_payment_link"
                       caption="Night card payment link"
                       dataType="text"
                       allowEditing={false}
                    />
                    <Column
                        dataField="details.night_cash_price"
                        caption="Night Cash Price"
                        dataType="text"
                        allowEditing={false}
                        visible={schedulingSettings.cash_prices_enabled}
                    />
                    <Column 
                        type="buttons"
                        width={isPhone && '65px'}
                    >
                        <DGButton
                            text="Edit"
                            icon="fas fa-pen fs-6"
                            hint="Edit"
                            onClick={(e) => {
                                const data = structuredClone(e.row.data)
                                setCurrentPrice(data);
                            }}
                        />
                        <DGButton name="delete" />
                    </Column>
                    <Editing
                        allowDeleting={true}
                    >
                    </Editing>
                </DataGrid>

                <Popup
                    visible={currentPrice && currentPrice !== null}
                    title={currentPrice?.owner_id ? 'Edit Price' : 'Add Price'}
                    fullScreen={isPhone}
                    onHidden={() => setCurrentPrice(null)}
                    dragEnabled={false}
                    width={480}
                >
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={{
                            disabled: false,
                            text: 'Save',
                            type: 'default',
                            width: '100%',
                            onClick: insertRow
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            disabled: false,
                            text: 'Cancel',
                            width: '100%',
                            onClick: () => {
                                console.log('currentPrice on cancel: ', currentPrice)
                                setCurrentPrice(null)
                            }
                        }}
                    />
                    { currentPrice && 
                        <Form
                            ref={formRef}
                            id={'form'}
                            formData={currentPrice}
                            labelLocation={'top'}
                            // onFieldDataChanged={onFormDataChanged}
                        >
                            {/* <Position at="center" my="center"collision="fit" /> */}
                            
                            <SimpleItem 
                                dataField="details.ride_type_list"
                                editorType="dxTagBox"
                                editorOptions={{
                                    items: appointmentTypes.filter(item => item.id !== 'unavailable'),
                                    displayExpr: 'text',
                                    valueExpr: 'id',
                                    fieldTemplate: 'rt_select_field',
                                    itemTemplate: 'rt_select_item',
                                }}
                            >
                                <Label text="Ride Type" />
                                <RequiredRule message="Ride type is required" />
                            </ SimpleItem>

                            <SimpleItem
                                dataField="details.passenger_range"
                                editorType="dxRangeSlider"
                                editorOptions={passengerRangeEditorOptions}
                            >
                                <Label>
                                    {`Number of passengers (${noOfPassengersRange[0]} to ${noOfPassengersRange[1]})`}
                                </Label>
                                <RequiredRule />
                            </SimpleItem>

                            <SimpleItem 
                                dataField="details.luggage_range"
                                editorType="dxRangeSlider" 
                                editorOptions={luggageRangeEditorOptions}
                            >
                            <Label>
                                {`Max Luggage Range (${luggageRange[0]} to ${luggageRange[1]})`}
                            </Label>
                            <RequiredRule />
                            </SimpleItem>

                            <SimpleItem 
                                dataField="details.distance_range"
                                editorType="dxRangeSlider"
                                hint="This is a hint"
                                editorOptions={distanceRangeEditorOptions}
                            >
                                <Label>
                                    {`Distance Range (${distanceRange && distanceRange[0]} to ${distanceRange && distanceRange[1]})`}
                                </Label>
                                <RequiredRule />
                            </ SimpleItem>

                            <GroupItem caption="Day Price">
                                <SimpleItem 
                                    dataField="details.day_card_price"
                                    editorType="dxNumberBox" 
                                    editorOptions={{
                                        min: 0,
                                        step: 0
                                    }}
                                >
                                    <Label text="Card Price"/>
                                    <RequiredRule />
                                </ SimpleItem>

                                <SimpleItem 
                                    dataField="details.day_card_payment_link"
                                    editorType="dxTextBox" 
                                >
                                    <Label text="Card payment link"/>
                                    <RequiredRule />
                                </ SimpleItem>

                                <SimpleItem 
                                    dataField="details.day_cash_price"
                                    editorType="dxNumberBox" 
                                    visible={!!schedulingSettings.cash_prices_enabled}
                                    editorOptions={{
                                        min: 0,
                                        step: 0
                                    }}
                                >
                                    <Label text="Cash Price"/>
                                    <RequiredRule />
                                </ SimpleItem>
                            </GroupItem>

                            <GroupItem caption="Night Price">
                                <SimpleItem 
                                    dataField="details.night_card_price"
                                    editorType="dxNumberBox" 
                                    editorOptions={{
                                        min: 0,
                                        step: 0
                                    }}
                                >
                                    <Label text="Card Price"/>
                                    <RequiredRule />
                                </ SimpleItem>

                                <SimpleItem 
                                    dataField="details.night_card_payment_link"
                                    editorType="dxTextBox" 
                                >
                                    <Label text="Card payment link"/>
                                    <RequiredRule />
                                </ SimpleItem>

                                <SimpleItem 
                                    dataField="details.night_cash_price"
                                    editorType="dxNumberBox"
                                    visible={!!schedulingSettings.cash_prices_enabled} 
                                    editorOptions={{
                                        min: 0,
                                        step: 0
                                    }}
                                >
                                    <Label text="Cash Price"/>
                                    <RequiredRule />
                                </ SimpleItem>
                            </GroupItem>

                            <Template name="rt_select_field" component={RideTypeField} />
                            <Template name="rt_select_item" component={RideTypeItem} />
                            
                        </Form>
                    }
                </Popup>
            </div>    
        )
    } else {
        return (
            <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
                <LoadIndicator
                    id="large-indicator"
                    height={60}
                    width={60}
                />
            </div>
        )
    }
}


const RideTypeField = (e) => {
    return (
      <div className="d-flex flex-row align-items-center">
        <TextBox
          placeholder={ e.data.length === 0 && "Select an entry type ..." }
          readOnly={true}
        />
      </div>
    );
}
  
  const RideTypeItem = (e) => {
    return (
      <div className="d-flex align-items-center">
        <RideTypePill key={e.data.id+crypto.randomUUID()} data={e.data} displayTooltip={false} />
        <div>{e.data.text}</div>
      </div>
    );
  }

export default Prices

