export const fetchRoutes =
    async (form, settings, directionsService, ) => {
      if (!form || !directionsService) {
        return;
      }
      let formData = form.option('formData');
      if (!formData.ride_details) {
        return
      }
      let sa = formData.ride_details.start_address;
      if(!sa){
        sa = settings.base_address
        formData.ride_details.start_address = sa
      }
      let pa = formData.ride_details.pickup_address;
      let ea = formData.ride_details.end_address;
      let da = formData.ride_details.destination_address;
      let time = 0
      let distance = 0

      if (['ride_full_departure_time'].includes(formData.appointment_type)) {
        //pickupDate is set by user
        if ((pa === null) || (pa === undefined) || (pa.length < 3)) {
          return
        }
        if (!((sa === null) || (sa === undefined) || (sa.length < 3))) {
          const timeAndDistance = await getDrivingTimeDist(sa, pa, new Date(formData.pickupDate), settings, directionsService)
          time = timeAndDistance.time
          distance = timeAndDistance.distance
          //console.log("Time and distance: ", time, distance)
          if ( time > 0 ) {
            time = addBufferTime(time, settings);
            try {
              let nd = new Date(formData.pickupDate.getTime() - time * 1000);
              form.updateData('startDate', nd);
            } catch {
              return
            }
          } else if (time === 0) {
            form.updateData('startDate', formData.pickupDate);
          }
          if ( distance > 0 ) {
            form.updateData('ride_details.pickup_lenght', distance)
          }
        }
        if (!((ea === null) || (ea === undefined) || (ea.length < 3))) {
          const timeAndDistance = await getDrivingTimeDist(pa, ea, new Date(formData.pickupDate), settings, directionsService) // distance val for Pick-up
          time = timeAndDistance.time
          distance = timeAndDistance.distance
          //console.log("Time and distance: ", time, distance)

          if ( time > 0 ) {
            time = addBufferTime(time, settings);
            time = time + settings.passenger_load_time + settings.passenger_unload_time + settings.max_pick_up_wait_time;
            let nd = new Date(formData.pickupDate.getTime() + time * 1000);
            form.updateData('endDate', nd);
          } else if (time === 0) {
            form.updateData('endDate', formData.pickupDate);
          }
          if ( distance > 0 ) {
            form.updateData('ride_details.dropoff_lenght', distance)
          }
        }
      }
      if (['ride_full_arrival_time'].includes(formData.appointment_type)) {
        //endDate is set by user
        if ((ea === null) || (ea === undefined) || (ea.length < 3)) {
          return
        }
        if ((pa === null) || (pa === undefined) || (pa.length < 3)) {
          return
        }

        const timeAndDistance = await getDrivingTimeDist(pa, ea, formData.endDate, settings, directionsService) // distance val for Drop-off
        time = timeAndDistance.time
        distance = timeAndDistance.distance
        //console.log("Time and distance: ", time, distance)

        if ( time > 0 ) {
          time = addBufferTime(time, settings);
          time = time + settings.passenger_load_time + settings.passenger_unload_time + settings.max_pick_up_wait_time;
          form.updateData('pickupDate', new Date(formData.endDate?.getTime() - time * 1000));
        } else if (time === 0) {
          form.updateData('pickupDate', formData.endDate);
        }
        if ( distance > 0 ) {
          form.updateData('ride_details.dropoff_lenght', distance)
        }

        if (!((sa === null) || (sa === undefined) || (sa.length < 3))) {
          const timeAndDistance2 = await getDrivingTimeDist(sa, pa, formData.endDate, settings, directionsService)
          let time2 = timeAndDistance2.time
          let distance2 = timeAndDistance2.distance
          //console.log("Time and distance: ", time2, distance2)
          if ( time2 > 0 ) {
            time2 = addBufferTime(time2, settings);
            form.updateData('startDate', new Date(formData.pickupDate?.getTime() - time2 * 1000));
          } else if (time2 === 0) {
            form.updateData('startDate', formData.pickupDate);
          }
          if ( distance2 > 0 ) {
            form.updateData('ride_details.pickup_lenght', distance2)
          }
        }
      }
      if (['ride_full_roundtrip'].includes(formData.appointment_type)) {
        //returnDate is set by user
        if ((da === null) || (da === undefined) || (da.length < 3)) {
          return
        }
        if (!((pa === null) || (pa === undefined) || (pa.length < 3))) {
          const timeAndDistance = await getDrivingTimeDist(pa, da, formData.destinationDate, settings, directionsService) // distance val for Drop-off
          time = timeAndDistance.time
          distance = timeAndDistance.distance
          //console.log("Time and distance: ", time, distance)

          if ( time > 0 ) {
            time = addBufferTime(time, settings);
            time = time + settings.passenger_load_time + settings.passenger_unload_time + settings.max_pick_up_wait_time;
            form.updateData('pickupDate', new Date(formData.destinationDate?.getTime() - time * 1000));
          } else if (time === 0) {
            form.updateData('pickupDate', formData.destinationDate);
          }
          if ( distance > 0 ) {
            form.updateData('ride_details.dropoff_lenght', distance)
          }
          if (!((sa === null) || (sa === undefined) || (sa.length < 3))) {
            const timeAndDistance2 = await getDrivingTimeDist(sa, pa, formData.destinationDate, settings, directionsService)
            let time2 = timeAndDistance2.time
            let distance2 = timeAndDistance2.distance
            //console.log("Time and distance: ", time2, distance2)
            if ( time2 > 0 ) {
              time2 = addBufferTime(time2, settings);
              form.updateData('startDate', new Date(formData.pickupDate?.getTime() - time2 * 1000));
            } else if (time2 === 0) {
              form.updateData('startDate', formData.pickupDate);
            }
            if ( distance2 > 0 ) {
              form.updateData('ride_details.pickup_lenght', distance2)
            }
          }
        }
        if (!((ea === null) || (ea === undefined) || (ea.length < 3))) {
          const timeAndDistance = await getDrivingTimeDist(da, ea, formData.returnDate, settings, directionsService) // distance val for Drop-off
          time = timeAndDistance.time
          distance = timeAndDistance.distance
          //console.log("Time and distance: ", time, distance)

          if ( time > 0 ) {
            time = addBufferTime(time, settings);
            time = time + settings.passenger_load_time + settings.passenger_unload_time + settings.max_pick_up_wait_time;
            form.updateData('endDate', new Date(formData.returnDate?.getTime() + time * 1000));
          } else if (time === 0) {
            form.updateData('endDate', formData.returnDate);
          }
          if ( distance > 0 ) {
            form.updateData('ride_details.return_lenght', distance)
          }
        }
      }
    }

const addBufferTime = (time, settings) => {
  let route_buffer_time = time * settings.route_buffer_percentage / 100;
  route_buffer_time = (route_buffer_time < (settings.route_buffer_min_time*60))?settings.route_buffer_min_time*60:route_buffer_time;
  route_buffer_time = (route_buffer_time > (settings.route_buffer_max_time*60))?settings.route_buffer_max_time*60:route_buffer_time;
  time = time + route_buffer_time;
  return time;
}

const getDrivingTimeDist = async (start_address, end_address, estimate_date, settings, directionsService) => {
  let time = -1;
  let distance = 0;
  try {
    let drivingOptions = {trafficModel: settings.traffic_model}
    const response = await directionsService.route({
      origin: start_address,
      destination: end_address,
      travelMode: 'DRIVING',
      provideRouteAlternatives: true,
      drivingOptions: {
        trafficModel: settings.traffic_model,
        departureTime: estimate_date,
      },
      //unitSystem: 1,
    });
    //console.log(response);
    for (let i = 0; i < response.routes.length; i++) {
      if (time < response.routes[i].legs[0].duration.value) {
        time = response.routes[i].legs[0].duration.value
      }
      const d = response.routes[i].legs[0].duration_in_traffic || response.routes[i].legs[0].duration // use 'duration' if 'duration_in_traffic' is undefined
      if (d) {
        if (time < d.value) {
          time = d.value

          const distanceMetres = response.routes[i].legs[0].distance.value
          const distanceMiles = distanceMetres/1609.344
          distance = Math.round(distanceMiles)
        }
      }
    }
  } catch {

  }
  return {time, distance};
}

//export const TRACKING_ID = "G-FB4EJHXRFR"
export const TRACKING_ID = "G-1ESKNEGWXE"
