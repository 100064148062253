import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import 'devextreme/data/odata/store';
import './schedule.scss'
import { Scheduler } from '../../components';
import {APIProvider} from '@vis.gl/react-google-maps';
import Button from 'devextreme-react/button';
import { TagBox } from 'devextreme-react/tag-box';
import { useAuth } from '../../contexts/auth';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import {
  getResources, getUserDetails, appointmentTypes, appointmentStatusValues, updateAppointment,
  getAppointments, deleteAppointment, updateResource, inviteDriversToAppointment,
} from '../../api/api'
import { addPlusOnePrefix } from '../../utils/miscUtil';

export default function Schedule() {
  const no_vehicle = {resource_type: "vehicle", resource_id: "00000000-0000-0000-0000-000000000000", resource_details: {name: 'No Vehicle'}};
  const { user, refreshSession } = useAuth();
  const [vehicleList, setVehicleList] = useState([no_vehicle]);
  const [vehicleFilteredList, setVehicleFilteredList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [driverListOriginal, setDriverListOriginal] = useState([]);
  const [driverFilteredList, setDriverFilteredList] = useState([]);
  const [customerList, setCustomerList] = useState([])
  const [appointmentData, setAppointmentData] = useState([]);
  const [appointmentFilteredData, setAppointmentFilteredData] = useState(null);
  const [callingAPI, setCallingAPI] = useState(false);
  const [showAppointment, setShowAppointment] = useState(null);
  const [userSettings, setUserSettings] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [apptId, setApptId] = useState(null);


  useEffect( () => {
    // refresh frontend data
    if(user && (appointmentData.length>0)){
      setInterval( () => {
        getResources(
            user,
            onRSuccess,
            (err)=>{console.log(err)
          }
        )
      }, 1000*60*60)
    }

    const onRSuccess = (data) => {
      getAppointments(user, data, onASuccess , err => console.log(err))
    }

    const onASuccess = d => {
      let arr1 =[]
      let arr2 =[]
      for(const item of d) {
        const obj = {}
        obj['apptId'] = item['appointment_id']
        obj['apptStatus'] = item['status']
        arr1.push(obj)
      }
      for(const item of appointmentData) {
        const obj2 = {}
        obj2['apptId'] = item['appointment_id']
        obj2['apptStatus'] = item['status']
        arr2.push(obj2)
      }

      const isDataEqual = JSON.stringify(arr1) === JSON.stringify(arr2)
      if(!isDataEqual) {
        console.log("***** stale data - update the react state with new data")
        window.location.reload()
      }else{
        console.log("scheduler data on frontend is up to date")
      }
    }
  }, [user, appointmentData])

  const onError = useCallback((error) => {
    setCallingAPI(false);
    if(error.status === 409) {
      notify('Sorry, we could not update the ride. Please try again!', 'error', 8000)
      getResources(user, onGetResourcesSuccess, onError);
    } else if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onGetAppointmentsSuccess = (apptsData, resources) => {
    let lst1 = [no_vehicle];
    let lst2 = [];
    let lst3 = []

    for (let i = 0; i < apptsData.length; i++) {
      for (let j = 0; j<appointmentTypes.length; j++ ){
        if (apptsData[i].appointment_type === appointmentTypes[j].id) {
          apptsData[i].appointment_title = appointmentTypes[j].text;
        }
      }
    }
    for (let i = 0; i < resources.length; i++) {
      if (resources[i].resource_type === 'vehicle') {
          lst1.push(resources[i]);
        for (let j = 0; j<apptsData.length; j++ ){
          if (apptsData[j].vehicle_id) {
            if (apptsData[j].vehicle_id === resources[i].resource_id) {
              apptsData[j].vehicle = resources[i];
            }
          } else {
            apptsData[j].vehicle_id = "00000000-0000-0000-0000-000000000000";
            apptsData[j].vehicle = no_vehicle;
          }
        }
      }
      if (resources[i].resource_type === 'driver') {
        lst2.push(resources[i]);
        for (let j = 0; j<apptsData.length; j++ ){
          if (apptsData[j].driver_id === resources[i].resource_id) {
            apptsData[j].driver = resources[i];
          }
        }
      }

      if (resources[i].resource_type === 'customer') {
        lst3.push(resources[i]);
        for (let j = 0; j<apptsData.length; j++ ){
          if (apptsData[j].customer_id) {
            if (apptsData[j].customer_id === resources[i].resource_id) {
              apptsData[j].customer_data = resources[i]['resource_details'];
            }
          }
        }
      }
    }
    setVehicleList(structuredClone(lst1));
    setVehicleFilteredList(structuredClone(lst1));
    setDriverList(structuredClone(lst2));
    setDriverFilteredList(structuredClone(lst2));
    setDriverListOriginal(structuredClone(lst2));
    setCustomerList(structuredClone(lst3))
    setAppointmentData(structuredClone(apptsData));
    setAppointmentFilteredData(structuredClone(apptsData));
    setApptId(searchParams.get('apptId'));
    setSearchParams({});
    setCallingAPI(false);
  }

  const onGetResourcesSuccess = (data) => {
    getAppointments(user, data, onGetAppointmentsSuccess, onError);
  }

  const onAppointmentChangedSuccess = (data) => {
    if(data.status === 'pending' && data.vehicle_id && data.driver_id) {
      notify(`We noticed that you assigned a vehicle and a driver but the status of the booking is still "Submitted"; the customer is still waiting for you to accept the ride`, 'warning', 10000);
    }
    var missing_resource = false
    var resource_found = false
    for (let i = 0; i < vehicleList.length; i++) {
      if (data.vehicle_id) {
        if (data.vehicle_id === vehicleList[i].resource_id) {
          data.vehicle = vehicleList[i];
          resource_found = true;
          break;
        }
      } else {
        data.vehicle_id = "00000000-0000-0000-0000-000000000000";
        data.vehicle = no_vehicle;
        resource_found = true;
      }
    }
    if (resource_found === false) {
      missing_resource = true
    }
    resource_found = false
    for (let i = 0; i < driverListOriginal.length; i++) {
      if (data.driver_id) {
        if (data.driver_id === driverListOriginal[i].resource_id) {
          data.driver = driverListOriginal[i];
          resource_found = true;
          break;
        }
      } else {
        resource_found = true;
      }
    }
    if (resource_found === false) {
      missing_resource = true
    }
    resource_found = false
    for (let i = 0; i < customerList.length; i++) {
      if (data.customer_id) {
        if (data.customer_id === customerList[i].resource_id) {
          data.customer_data = customerList[i]['resource_details'];
          resource_found = true;
          break;
        }
      } else {
        resource_found = true;
      }
    }
    if (resource_found === false) {
      missing_resource = true
    }

    if (missing_resource) {
      // reloading everything
      getResources(user, onGetResourcesSuccess, onError);
    } else {
      for (let i = 0; i < appointmentData.length; i++) {
        if (data.appointment_id === appointmentData[i].appointment_id) {
          appointmentData[i]=data;
          setAppointmentData(structuredClone(appointmentData));
        }
      }
      for (let i = 0; i < appointmentFilteredData.length; i++) {
        if (data.appointment_id === appointmentFilteredData[i].appointment_id) {
          appointmentFilteredData[i]=data;
          setAppointmentFilteredData(structuredClone(appointmentFilteredData))
        }
      }
    }



    //setDriverList(structuredClone(driverListOriginal));
  }

  const onAppointmentSave = (data) => {
    const modifiedPhoneNo = addPlusOnePrefix(data.customer_data.phone)
    if(modifiedPhoneNo) {
      data.customer_data.phone = modifiedPhoneNo
    }
    console.log('addPlusOneToPhone: ', modifiedPhoneNo)
    updateAppointment(user, data, onAppointmentChangedSuccess, onError);
  }

  const onAppointmentDelete = (data) => {
    deleteAppointment(user, data, onAppointmentChangedSuccess, onError);
  }

  const onInviteDrivers = (data) => {
    console.log(data);
    inviteDriversToAppointment(user, data.appointment_id,
      data => {
        notify("Invitation sent", 'success', 3000);
      },
      err => {
        notify("Something went wrong. Please refresh and try again.", 'error', 3000);
        console.log(err);
      })
  }

  useEffect(() => {
    if (apptId) {
      setShowAppointment({id: apptId})
    }
  }, [apptId]);

  useEffect(() => {
    if (user.signInUserSession) {
      setCallingAPI(true);
      getResources(user, onGetResourcesSuccess, onError);
      getUserDetails(user, data => {
        if('scheduling_settings' in data) {
          setUserSettings(data.scheduling_settings);
        }
      }, err => {
        console.log(err)
      })
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  const onVehicleFilter = (data) => {
    console.log(data.value);
    if (data.value.length === 0) {
      setVehicleFilteredList(vehicleList);
    } else {
      setVehicleFilteredList(data.value);
    }
  }

  const onDriverFilter = (data) => {
    console.log(data.value);
    if (data.value.length === 0) {
      setDriverFilteredList(driverList);
      setAppointmentFilteredData(appointmentData);
    } else {
      let lst = []
      for (let i = 0; i<data.value.length; i++ ){
        for (let j = 0; j<appointmentData.length; j++ ){
          if (appointmentData[j].driver_id === data.value[i].resource_id) {
            lst.push(appointmentData[j]);
          }
        }
      }
      setDriverFilteredList(data.value);
      setAppointmentFilteredData(lst);
    }
  }

  return (
    <React.Fragment>
      {userSettings && (callingAPI === false) ?
      <>
      <div className="d-flex flex-row justify-content-between align-items-center ">
        <h2 className={''}>My Schedule</h2>
        <Button
          type="default"
          text="Add Entry"
          onClick={() => {
            setShowAppointment({});
          }}
        />
      </div>
      <div className="mb-3">
        <TagBox
          label="Filter Vehicles"
          width="100%"
          dataSource={vehicleList}
          //valueExpr="resource_id"
          itemRender={VehicleSelectItem}
          tagRender={VehicleSelectTag}
          onValueChanged={onVehicleFilter}
        />

      </div>
      <div className="mb-3">
        <TagBox
          label="Filter Drivers"
          width="100%"
          dataSource={driverList}
          //valueExpr="resource_id"
          itemRender={DriverSelectItem}
          tagRender={DriverSelectTag}
          onValueChanged={onDriverFilter}
        />

      </div>

        <APIProvider apiKey={"AIzaSyCl4awgSU-zcSt2sqyNZyIytWbTOTB5H3k"}>
          <Scheduler
            id="scheduler"
            appointmentData={appointmentFilteredData}
            vehicleList={vehicleFilteredList}
            driverList={driverList}
            customerList={customerList}
            appointmentStatusValues={appointmentStatusValues.filter(val => { return val.owner_allowed; })}
            appointmentTypes={appointmentTypes}
            settings={userSettings}
            onAppointmentSave={onAppointmentSave}
            onAppointmentDelete={onAppointmentDelete}
            showAppointment={showAppointment}
            onInvite={onInviteDrivers}
          >
          </Scheduler>
        </APIProvider>
      </>:
      <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
        <LoadIndicator
            id="large-indicator"
            height={60}
            width={60}
          />
          </div>
        }

    </React.Fragment>
)}

const VehicleSelectItem = (data2) => {
  const data = { data: data2 };
  return (
    <div className="d-flex flex-row align-items-center">
      <div
        style={{
          backgroundColor: "#fff",
          backgroundImage: `url("${data.data && data.data.resource_details.picture?data.data.resource_details.picture:'/DefaultCar.png'}")`,
          backgroundSize: 'contain',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "50px",
          height: "50px",
          marginTop: "3px",
          marginBottom: "3px",
          borderRadius: "2px",
        }}
      />
      {data.data && <div className="ms-3">{data.data.resource_details.name}
      </div>}
    </div>
  );
}

const VehicleSelectTag = (data) => {
  console.log(data);
  return (
    <React.Fragment>
      <div className={`dx-tag-content`} >
        <div
          className="me-1"
            style={{
              backgroundColor: "#fff",
              backgroundImage: `url("${data.resource_details.picture?data.resource_details.picture:'/DefaultCar.png'}")`,
              backgroundSize: 'contain',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "30px",
              height: "30px",
              marginTop: "3px",
              marginBottom: "3px",
              borderRadius: "2px",
            }}
        />
        <span>{data.resource_details.name}</span>
        <div className="dx-tag-remove-button"></div>
      </div>
    </React.Fragment>

  );
}

const DriverSelectItem = (data2) => {
  const data = { data: data2 };
  return (
    <div className="d-flex flex-row align-items-center">
      <div
        style={{
          backgroundColor: "#fff",
          backgroundImage: `url("${data.data && data.data.resource_details.picture?data.data.resource_details.picture:'/DefaultAvatar.png'}")`,
          backgroundSize: 'contain',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "50px",
          height: "50px",
          marginTop: "3px",
          marginBottom: "3px",
          borderRadius: "50px",
        }}
      />
      {data.data && <div className="ms-3">{data.data.resource_details.name}
      </div>}
    </div>
  );
}

const DriverSelectTag = (data) => {
  console.log(data);
  return (
    <React.Fragment>
      <div className={`dx-tag-content`} >
        <div
          className="me-1"
            style={{
              backgroundColor: "#fff",
              backgroundImage: `url("${data.resource_details.picture?data.resource_details.picture:'/DefaultAvatar.png'}")`,
              backgroundSize: 'contain',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "30px",
              height: "30px",
              marginTop: "3px",
              marginBottom: "3px",
              borderRadius: "50px",
            }}
        />
        <span>{data.resource_details.name}</span>
        <div className="dx-tag-remove-button"></div>
      </div>
    </React.Fragment>

  );
}
