import React, { useState, useEffect, useRef, useCallback } from 'react';
import {Form,
    Label,
    SimpleItem,
    EmptyItem,
    GroupItem,
    ButtonItem,
    RequiredRule,
    EmailRule} from 'devextreme-react/form';
import { Switch } from "devextreme-react/switch";
import { useAuth } from '../../../contexts/auth';
import {getUserDetails, updateUserDetails} from '../../../api/api'
import { parseHours } from '../../../utils/miscUtil';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import {useMap, useMapsLibrary} from '@vis.gl/react-google-maps';


const SchedulingSettings = () => {
  const [schedulingSettings, setSchedulingSettings] = useState({})
  const [formEditData, setFormEditData] = useState()
  const [showPopupEditSettings, setShowPopupEditSettings] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const formRef = useRef(null);
  const { user } = useAuth();

  const map = useMap();
  const places = useMapsLibrary('places');
  const routes = useMapsLibrary('routes');

  const [predictionResults, setPredictionResults] = useState([])
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [sessionToken, setSessionToken] = useState();

  useEffect( () => {
    getUserDetails(user, data => {
      if('scheduling_settings' in data) {
        setSchedulingSettings(data.scheduling_settings);
        setFormEditData(new Object(data.scheduling_settings));
        console.log('data.scheduling_settings: ', data.scheduling_settings)
      }
      setLoaded(true)
    }, err => {
      console.log(err)
      setLoaded(true)
    })
  }, [])

  useEffect(() => {
    if (!places || !routes) return;
    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());
  }, [map, places, routes]);


  const onSaveScheduling = (e) => {
    let result = formRef.current?.instance.validate()
    if(result.isValid){
      console.log('e-onSaveScheduling: ', e)
      setIsBtnDisabled(true)
      const updaterObj = {}
      updaterObj['scheduling_settings'] = formRef.current.instance.option().formData
      if(!('traffic_model' in updaterObj['scheduling_settings'])) {
        updaterObj['scheduling_settings']['traffic_model'] = "pessimistic"
      }
      updateUserDetails(
        user,
        updaterObj,
        (data) => {
          onCancel()
          setSchedulingSettings(data.scheduling_settings)
          formRef.current?.instance.reset(data.scheduling_settings)
        },
        (err) => console.log('err: ', err)
      )
    } else {
      notify("Please correct the invalid fields before proceeding", 'error', 2000);
    }


  }

  const saveSettingsButtonOptions = {
    text: 'Save',
    type: 'default',
    width: '100%',
    onClick: onSaveScheduling,
  }

  const onCancel = () => {
    setShowPopupEditSettings(false)
    formRef.current?.instance.reset(schedulingSettings)
  }

  const cancelButtonOptions = {
    disabled: false,
    text: 'Cancel',
    width: '100%',
    onClick: onCancel
  }

  const renderSettingVal = (varName) => {
      if(schedulingSettings[varName] && ( schedulingSettings[varName] !== null || schedulingSettings[varName] !== '' ) ) {
        return schedulingSettings[varName]
      }
      return "n/a"
  }

  const addressEditorOptions = {
    showDropDownButton: true,
    valueExpr: 'description',
    dataSource: predictionResults,
    onSelectionChanged(args) {
      console.log('onChange', args)
      // if (args.selectedItem) {
      //   e.form.updateData('ride_details.start_address', args.selectedItem.description);
      //   fetchRoutes(e.form);
      // }
    },
    onInput(e) {
      console.log(formRef);
      console.log(e);
      fetchPredictions(e.event.currentTarget.value, formRef);
    },
  }

  const fetchPredictions = useCallback(
    async (inputValue, form) => {
      if (!autocompleteService || !inputValue) {
        while (predictionResults.length > 0) {
          predictionResults.pop();
        }
        return;
      }

      const request = {input: inputValue, sessionToken};
      const response = await autocompleteService.getPlacePredictions(request);

      //TODO: This code doesn't seem right but it works

      /*let items = form.option('items');
      for (let i = 0; i < items.length; i++) {
        if (["ride_details.start_address", "ride_details.end_address"].includes(items[i].dataField)) {
          items[i].editorOptions.dataSource = response.predictions;
        }
      }
      form.option('items', items);*/
      while (predictionResults.length > 0) {
        predictionResults.pop();
      }
      for (let i = 0; i < response.predictions.length; i++) {
        predictionResults.push(response.predictions[i]);
      }
    }, [autocompleteService, sessionToken]
  )


  if(loaded) {
    return (
      <div className="content-block mt-5">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h2 className="">Settings</h2>
          <Button
              type="default"
              text="Edit"
              onClick={() => {
                setIsBtnDisabled(false)
                setShowPopupEditSettings(true);
              }}
          />
          </div>
          <hr className="mt-0 mb-5"></hr>
          <div className="">
            <div className="dx-fieldset">
              <div className="dx-fieldset-header">Basic settings</div>
              <div className="dx-field" id="base_address" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Base Address</div>
                <div className="dx-field-value w-100">{ renderSettingVal('base_address', '-') }</div>
              </div>

              <div className="dx-field" id="max_service_radius" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Maximum Service Radius (miles)</div>
                <div className="dx-field-value w-100">{ renderSettingVal('max_service_radius', 200) }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Cancelation Policy Text</div>
                <div className="dx-field-value w-100">{ renderSettingVal('cancelation_policy_text', '100% refund for canceling more than 24 hours in advance') }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Time Zone</div>
                <div className="dx-field-value w-100">{ renderSettingVal('timezone', '') }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Advanced Airport Questions</div>
                <div className="dx-field-value w-100">{ schedulingSettings['advanced_airport_questions'] ? 'Enabled' : 'Disabled' }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Auto-accept Bookings</div>
                <div className="dx-field-value w-100">{ schedulingSettings['auto_accept'] ? 'Enabled' : 'Disabled' }</div>
              </div>
            </div>

            <div className="dx-fieldset">
              <div className="dx-fieldset-header">Add extra time to the Google Maps calculated route time</div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Driving Buffer (%)</div>
                <div className="dx-field-value w-100">{ renderSettingVal('route_buffer_percentage', 10) }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Minimum Buffer Time (minutes)</div>
                <div className="dx-field-value w-100">{ renderSettingVal('route_buffer_min_time', 1) }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Maximum Buffer Time (minutes)</div>
                <div className="dx-field-value w-100">{ renderSettingVal('route_buffer_max_time', 10) }</div>
              </div>
            </div>

            <div className="dx-fieldset">
              <div className="dx-fieldset-header">Other Buffer Times</div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Passenger Load Time (minutes)</div>
                <div className="dx-field-value w-100">{ renderSettingVal('passenger_load_time', 10) }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Passenger Unload Time (minutes)</div>
                <div className="dx-field-value w-100">{ renderSettingVal('passenger_unload_time', 5) }</div>
              </div>
              <div className="dx-field" id="max_pick_up_wait_time" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Maximum Pick-Up Late Time (minutes)</div>
                <div className="dx-field-value w-100">{ renderSettingVal('max_pick_up_wait_time', 60) }</div>
              </div>
            </div>

            <div className="dx-fieldset">
              <div className="dx-fieldset-header">Other</div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Same Day Roundtrips</div>
                <div className="dx-field-value w-100">{ schedulingSettings['same_day_round_trips_enabled'] ? 'Enabled' : 'Disabled' }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Cash Prices</div>
                <div className="dx-field-value w-100">{ schedulingSettings['cash_prices_enabled'] ? 'Enabled' : 'Disabled' }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Payment Link for Cash Booking</div>
                <div className="dx-field-value w-100">{ renderSettingVal('booking_without_full_payment_link', 10) }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Payment Link for Tips</div>
                <div className="dx-field-value w-100">{ renderSettingVal('tips_payment_link', 10) }</div>
              </div>
              <div className="dx-field" style={{flexDirection:"column"}}>
                <div className="dx-field-label w-100">Night hours range</div>
                { (schedulingSettings.night_hours_start && schedulingSettings.night_hours_end) ?
                  <div className="dx-field-value w-100">{ parseHours(renderSettingVal('night_hours_start'))} - { parseHours(renderSettingVal('night_hours_end')) }</div>
                  :
                  <div className="dx-field-value w-100">n/a</div>
                }
              </div>
            </div>
          </div>

          <Popup
            className="text-danger"
            visible={showPopupEditSettings}
            // onHidden={onCloseEditSettingsDialog}
            dragEnabled={false}
            showCloseButton={false}
            showTitle={true}
            title="Edit Settings"
            container=".dx-viewport"
            width={"auto"}
            maxHeigth={"700px"}
          >
          <Position at="center" my="center"collision="fit" />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="before"
                options={saveSettingsButtonOptions}
                disabled={isBtnDisabled}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={cancelButtonOptions}
            />

            <Form
                ref={formRef}
                colCount={1}
                id="settingsForm"
                width="100%"
                formData={formEditData}
                showRequiredMark={true}
            >
                <GroupItem caption="Basic settings">
                  <SimpleItem dataField="base_address"
                    editorType="dxAutocomplete"
                    editorOptions={addressEditorOptions}
                    helpText="Where will the vehicles be parked"
                  >
                    <Label text="Base Address"/>
                    <RequiredRule message="Base Address is required" />
                  </SimpleItem>
                  <SimpleItem dataField="max_service_radius"
                    editorType="dxNumberBox"
                    editorOptions={{min: 0}}
                    helpText="How far from the base are you willing to accept rides. It only impacts customer requests."
                  >
                    <RequiredRule message="Maximum Service Radius is required" />
                    <Label text="Maximum Service Radius (miles)"/>
                  </SimpleItem>
                  <SimpleItem dataField="cancelation_policy_text"
                    editorType="dxTextBox"
                    helpText="Brief description of your cancelation policy. Visible to customer when booking rides."
                  >
                    <Label text="Cancelation Policy Text"/>
                  </SimpleItem>
                  <SimpleItem dataField="advanced_airport_questions"
                    editorType="dxSwitch"
                    helpText="If enabled, additional question 'Are you booking an airport ride' is displayed. Options to choose are: 'Yes - Domestic Flight', 'Yes - International Flight' and 'No' "
                  >
                    <Label text="Advanced Airport Questions"/>
                  </SimpleItem>

                  <SimpleItem dataField="auto_accept"
                    editorType="dxSwitch"
                    helpText="If enabled, we will auto accept bookings for you when a vehicle is available and there are no other bookings 3 hours before or after. "
                  >
                    <Label text="Auto-accept Bookings"/>
                  </SimpleItem>
                </GroupItem>

                <GroupItem caption="Add extra time to the Google Maps calculated route time">
                  <SimpleItem dataField="route_buffer_percentage"
                    editorType="dxNumberBox"
                    editorOptions={{min: 0, max: 50}}
                    helpText="Increase the estimated time calculated by Google Maps"
                  >
                    <Label text="Driving Buffer (%)"/>
                  </SimpleItem>
                  <SimpleItem dataField="route_buffer_min_time"
                    editorType="dxNumberBox"
                    editorOptions={{min: 0}}
                    helpText="Minimum time added to each ride"
                  >
                    <Label text="Minimum Buffer Time (minutes)"/>
                  </SimpleItem>
                  <SimpleItem dataField="route_buffer_max_time"
                    editorType="dxNumberBox"
                    editorOptions={{min: 0}}
                    helpText="Maximum time added to each ride"
                  >
                    <Label text="Maximum Buffer Time (minutes)"/>
                  </SimpleItem>
                </GroupItem>

                <GroupItem caption="Other Buffer Times">
                  <SimpleItem dataField="passenger_load_time"
                    editorType="dxNumberBox"
                    editorOptions={{min: 0}}
                    helpText="Extra time added to each ride for passengers loading"
                  >
                    <Label text="Passenger Load Time (minutes)"/>
                  </SimpleItem>
                  <SimpleItem dataField="passenger_unload_time"
                    editorType="dxNumberBox"
                    editorOptions={{min: 0}}
                    helpText="Extra time added to each ride for passengers unloading"
                  >
                    <Label text="Passenger Unload Time (minutes)"/>
                  </SimpleItem>
                  <SimpleItem dataField="max_pick_up_wait_time"
                    editorType="dxNumberBox"
                    editorOptions={{min: 0}}
                    helpText="Extra time added to each ride that you are waiting for late passengers"
                  >
                    <Label text="Maximum Pick-Up Late Time (minutes)"/>
                  </SimpleItem>
                </GroupItem>
                <GroupItem caption="Other">
                  <SimpleItem dataField="same_day_round_trips_enabled"
                    editorType="dxSwitch"
                    helpText="Turning on same day roundtrip setting will allow customers to book same day roundtrips that include a wait time before returning to the drop off destination"
                  >
                    <Label text="Same Day Roundtrips"/>
                  </SimpleItem>
                  <SimpleItem dataField="cash_prices_enabled"
                    editorType="dxSwitch"
                    helpText="Turning on cash prices will allow customers to book a ride and pay cash later"
                  >
                    <Label text="Cash Prices"/>
                  </SimpleItem>
                  <SimpleItem dataField="booking_without_full_payment_link"
                    editorType="dxTextBox"
                    editorOptions={{min: 0}}
                    // helpText="Link for ride confirmation with unpayed status"
                  >
                    <Label text="Payment Link for Cash Booking"/>
                  </SimpleItem>
                  <SimpleItem dataField="tips_payment_link"
                    editorType="dxTextBox"
                    editorOptions={{min: 0}}
                    // helpText="Link for ride confirmation with unpayed status"
                  >
                    <Label text="Payment Link for Tips"/>
                  </SimpleItem>
                </GroupItem>
                <GroupItem colCount={2}>
                    <SimpleItem 
                        colSpan={1}
                        dataField="night_hours_start"
                        editorType="dxDateBox"
                        editorOptions={{
                            type: 'time',
                            onOptionChanged(e){
                              console.log('on selection ch:', e)
                            }
                        }}
                    >
                        <Label text="Night Hours Start"/>
                        {/* <RequiredRule /> */}
                    </ SimpleItem>
                    <SimpleItem 
                        colSpan={1}
                        dataField="night_hours_end"
                        editorType="dxDateBox"
                        editorOptions={{
                            type: 'time',
                        }}
                    >
                        <Label text="Night Hours End"/>
                        {/* <RequiredRule /> */}
                    </ SimpleItem>
                </GroupItem>
            </Form>
          </Popup>

      </div>

    )
  }
}

export default SchedulingSettings
