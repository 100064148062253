import Profile from './Profile/Profile'
import SchedulingSettings from './SchedulingSettings/SchedulingSettings'
import { APIProvider } from '@vis.gl/react-google-maps';


const Settings = () => {
  return <>
    <Profile />
    <APIProvider apiKey={"AIzaSyCl4awgSU-zcSt2sqyNZyIytWbTOTB5H3k"}>
      <SchedulingSettings />
    </APIProvider>
  </>
}

export default Settings