import React, { useState, useCallback, useRef, useEffect } from 'react';
import Drawer from 'devextreme-react/drawer';
import appInfo from '../../app-info';
import ScrollView from 'devextreme-react/scroll-view';
import { Header, SideNavigationMenu, Footer, Hero, IconBox,
  Title, ContactForm, SubscriptionPlan, FeatureBox } from '../../components';
import {useNavigate, useParams} from 'react-router-dom'
import { useScreenSize } from '../../utils/media-query';
import devices from 'devextreme/core/devices';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';
import { InView } from "react-intersection-observer";
import { hotjar } from 'react-hotjar';
import './marketing.scss';


export default function Marketing({ title, description, children }) {
  const scrollViewRef = useRef(null);
  const navigate = useNavigate();
  const contactUsRef = useRef(null);
  const featuresRef = useRef(null);
  const aboutUsRef = useRef(null);
  const priceRef = useRef(null);
  const peekInsideRef = useRef(null);



  let { id } = useParams();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(MenuStatus.Closed);
  const [scrollOffset, setScrollOffset] = useState(null);
  const [fadeInAnimation, setFadeInAnimation] = useState("animate__animated animate__fadeIn animate__slow");
  const [fadeOutAnimation, setFadeOutAnimation] = useState("animate__animated animate__fadeOut animate__slow");

  const hotjarOptions = {id: 5046818, sv: 6, debug: false}


  useEffect(() => {
    (id === "contact-us") && contactUsRef.current.scrollIntoView(true);
    (id === "features") && featuresRef.current.scrollIntoView(true);
    (id === "about-us") && aboutUsRef.current.scrollIntoView(true);
    (id === "price") && priceRef.current.scrollIntoView(true);
    (id === "peek-inside") && peekInsideRef.current.scrollIntoView(true);
  }, [id])

  useEffect(() => {
    hotjar.initialize(hotjarOptions);

    // Check if Hotjar has been initialized before calling its methods
    if (hotjar.initialized()) {
      console.log('hotjar initialized')
    }
  }, []);

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
    return menuStatus === MenuStatus.Closed ? true : false;
  }, [isLarge, menuStatus]);

  const onNavigationChanged = useCallback(({ itemData, event, node }) => {
    if (menuStatus === MenuStatus.Closed || !itemData.path || node.selected) {
      event.preventDefault();
      return;
    }

    if (itemData.external) {
      window.open(itemData.path, '_blank').focus();
    } else {
      navigate(itemData.path);
    }

    scrollViewRef.current.instance.scrollTo(0);

    if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
      setMenuStatus(MenuStatus.Closed);
      event.stopPropagation();
    }
  }, [navigate, menuStatus, isLarge]);

  const onScroll = useCallback((options) => {
    /*if (scrollOffset < options.scrollOffset.top) {
      console.log("going up");
      setFadeInAnimation("animate__animated animate__fadeInUp");
      setFadeOutAnimation("animate__animated animate__fadeOutUp");
    } else {
      console.log("going down");
      setFadeInAnimation("animate__animated animate__fadeInDown");
      setFadeOutAnimation("animate__animated animate__fadeOutDown");
    }*/
    setScrollOffset(options.scrollOffset.top);
  }, [scrollOffset]);

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Header
        menuToggleEnabled
        toggleMenu={toggleMenu}
        title={title}
      />
      <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'before'}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={isXSmall ? 0 : 0}
        maxSize={250}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={'menu'}
      >
        <div className={'container p-0'} style={{maxWidth: "100%"}}>
          <ScrollView ref={scrollViewRef} className={'layout-body with-footer'} onScroll={onScroll}>
            <Hero
              title="Limousine and Car Transportation Technologies"
              subtitle="Automate quoting, booking and dispatching rides so you can concentrate on growing the business"
              slogan=""
              bg_image_src="/hero.jpg"
              cta_link="/about/peek-inside"
              cta_label="View Demo"
              main_id="hero"
              cta_id="link_get_started"
            />

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div className={"section about-2 p-0 pt-5 "+(inView?fadeInAnimation:fadeOutAnimation)} id="aboutFeatures">
                    <div className="container p-0" >
                      <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                        <div className="p-0" style={{maxWidth:"600px", minWidth:"200px"}}>
                          <img className="img-fluid" src="/about.jpg" alt=""/>
                        </div>

                        <div className="content p-3" style={{maxWidth:"600px", minWidth:"400px"}}>
                          <div className="row py-3">
                            <Title
                              regular_text="Exclusive"
                              highlighted_text="Value Features"
                            />
                          </div>
                          <ul className="marketing-list">
                            <li>Save time with intelligent, automated quotes</li>
                            <li>Secure more deals with intelligent availability check</li>
                            <li>Effortlessly manage rides with customized task list</li>
                            <li>Customer feedback and online tips</li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )}
            </InView>

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div ref={featuresRef} id="features" className={"section-bg section "+(inView?fadeInAnimation:fadeOutAnimation)}>
                    <div className="container py-5">
                      <div className="row py-5">
                        <div className="col-12">
                          <Title
                            regular_text="We Focus"
                            highlighted_text="On You"
                          />
                        </div>
                      </div>
                      <div className="row py-5">
                        <div className={"col-12 col-md-6 col-lg-6 text-center mb-2 "+(inView?fadeInAnimation:fadeOutAnimation)+" "}>
                          <FeatureBox
                            main_id="about_customer_flow"
                            title="Customer Flow"
                            slides={["/image11.png", "/image12.png","/image13.png","/image14.png"]}
                            features={[
                              <li key="1">Customizable cancellation policy</li>,
                              <li key="2">Guided customer booking flow</li>,
                              <li key="3">Real time quote based on number of passengers, luggage and distance</li>,
                              <li key="4">Automated confirmation & reminders</li>,
                              <li key="5">Online payments (Stripe)</li>,
                              <li key="6">Tip and feedback opportunity</li>,
                            ]}
                          />
                        </div>
                        <div className={"col-12 col-md-6 col-lg-6 text-center mb-2 "+(inView?fadeInAnimation:fadeOutAnimation)+" animate__delay-1s"}>
                          <FeatureBox
                            main_id="about_dispatch_flow"
                            title="DispatchFlow"
                            slides={["/image21.png", "/image22.png","/image23.png","/image24.png"]}
                            features={[
                              <li key="1">Task list to easily manage incoming rides</li>,
                              <li key="2">Fleet and Driver management</li>,
                              <li key="3">Customer database</li>,
                              <li key="4">Customized pricing module powering auto quotes</li>,
                              <li key="5">Fleet Calendar</li>,
                              <li key="6">Immediate funds access; no added fees</li>,
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </InView>

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div ref={peekInsideRef} id="features" className={"section-bg section "+(inView?fadeInAnimation:fadeOutAnimation)}>
                    <div className="row py-5">
                      <div className="col-12">
                        <Title
                          regular_text="Peek"
                          highlighted_text="Inside"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center py-5">
                      {devices.current().deviceType === 'phone'?
                        <div className="px-1 w-100">
                        <div className="iframe-container">
                          <iframe className="responsive-iframe" width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?si=1eJNey78wL3lvyAH&amp;list=UUaOD6ANDXHdWWvzOMqKDXIA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div></div>:
                        <div className="px-5 w-100">
                        <div className="iframe-container">
                          <iframe className="responsive-iframe" width="100%" height="100%" src="https://www.youtube.com/embed/videoseries?si=1eJNey78wL3lvyAH&amp;list=UUaOD6ANDXHdWWvzOMqKDXIA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}
            </InView>

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div ref={aboutUsRef} className={"section about-2 p-0 pt-3 "+(inView?fadeInAnimation:fadeOutAnimation)} id="aboutCompany">
                    <div className="container p-0" >
                      <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                        <div className="p-0" style={{maxWidth:"600px", minWidth:"200px"}}>
                          <img className="img-fluid" src="/aboutus.png" alt=""/>
                        </div>
                        <div className="content p-3" style={{maxWidth:"600px", minWidth:"400px"}}>
                          <div className="row py-3">
                            <Title
                              regular_text="About"
                              highlighted_text="Us"
                            />
                          </div>
                          <p className="marketing-list p-3">At GetCarRide.com, we're committed to revolutionizing the car and limousine service industry through intelligent scheduling solutions. Our value driven approach sets new standards for efficiency, empowering transportation businesses to deliver unparalleled service while optimizing operations like never before. Join us in shaping the future of transportation, one smart ride at a time.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </InView>

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div id="chooseUs" className="section-bg section">
                    <div className={"container py-5 "+(inView?fadeInAnimation:fadeOutAnimation)}>
                      <div className="row py-5">
                        <div className="col-12">
                          <Title
                            regular_text="Why"
                            highlighted_text="Choose Us"
                          />
                        </div>
                      </div>
                      <div className="row py-5">
                        <div className="col-12 col-md-6 col-lg-4 text-center mb-2">
                          <IconBox
                            main_id="link_about_websites"
                            icon={<i className="fas fa-headset"></i>}
                            title="Dedicated Support"
                            description="Instead of sales, we focus on customer support. Count on our dedicated customer success team to help you quickly onboard and maximize operational efficiency and customers. We're committed to your success every stepof the way."
                          />
                        </div>
                        <div className={"col-12 col-md-6 col-lg-4 text-center mb-2 "+(inView?fadeInAnimation:fadeOutAnimation)+" animate__delay-1s"}>
                          <IconBox
                            main_id="link_about_ai"
                            icon={
                              <span>
                                <i className="fas fa-laptop"></i>
                                <i className="m-1 fas fa-mobile-alt" style={{maxHeight:"50%"}}></i>
                              </span>

                            }
                            title="Parity Mobile & Web"
                            description="Tired of Mobile apps not working the same way as Web experiences? Because GetCarRide is designed as a responsive web app, your experience is guaranteed to be identical whether you access it on your phone or computer."
                          />
                        </div>
                        <div className={"col-12 col-md-6 col-lg-4 text-center mb-2 "+(inView?fadeInAnimation:fadeOutAnimation)+" animate__delay-2s"}>
                          <IconBox
                            main_id="link_about_ai"
                            icon={ <i className="fas fa-server"></i>}
                            title="No Outage Peace of Mind"
                            description="Some of the legacy solution providers in this space have been experiencing prolonged down times due to scalability issues. GetCarRide AWS native development ensures infinite scalability which ensures your piece of mind."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </InView>

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div ref={priceRef} id="price" className={"pb-5 section "+(inView?fadeInAnimation:fadeOutAnimation)}>
                    <div className="container">
                      <div className="row py-5">
                        <div className="col-12">
                          <Title
                            regular_text="Our"
                            highlighted_text="Pricing"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-6 col-lg-3 pb-2">
                          <SubscriptionPlan
                            title="Solo"
                            price="$49"
                            strikeout_price="$80"
                            promo="Limited offer. Email us below for coupon code. Free for 90 days"
                            interval="month"
                            features={[
                              <li key="1">Solo Driver Operator</li>,
                            ]}
                          />
                        </div>
                        <div className={"col-6 col-lg-3 pb-2 "+(inView?fadeInAnimation:fadeOutAnimation)+" animate__delay-1s"}>
                          <SubscriptionPlan
                            title="Growing"
                            price="$150"
                            promo="Free for 90 days"
                            interval="month"
                            features={[
                              <li key="1">Up to 3 vehicles</li>,
                            ]}
                          />
                        </div>
                        <div className={"col-6 col-lg-3 pb-2 "+(inView?fadeInAnimation:fadeOutAnimation)+" animate__delay-2s"}>
                          <SubscriptionPlan
                            title="Titan"
                            price="$200"
                            promo="Free for 90 days"
                            interval="month"
                            features={[
                              <li key="1">Up to 6 vehicles</li>,
                            ]}
                          />
                        </div>
                        <div className={"col-6 col-lg-3 pb-2 "+(inView?fadeInAnimation:fadeOutAnimation)+" animate__delay-3s"}>
                          <SubscriptionPlan
                            title="Enterprise"
                            features={[
                              <li key="1">More than 6 vehicles</li>,
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </InView>

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div ref={contactUsRef} id="contact-us" className={"contact-us dx-theme-background-color "+(inView?fadeInAnimation:fadeOutAnimation)}>
                  	<div className="container">
                      <div className="row py-5">
                        <div className="col-12">
                          <Title
                            regular_text="Get In"
                            highlighted_text="Touch"
                          />
                        </div>
                      </div>
                  		<div className="row justify-content-center">
                  			<div className="col-12 col-lg-6">
                          <ContactForm customer_id='60cedac6-9835-461f-9955-b5addf1356be'/>
                  			</div>
                  		</div>
                  	</div>
                  </div>
                </div>
              )}
            </InView>



            <div className="px-4">
              <Footer>
                Copyright © 2021-{new Date().getFullYear()} {appInfo.company}.
                <br />
                All trademarks or registered trademarks are property of their
                respective owners.
                <br />
                Version {appInfo.version}
              </Footer>
            </div>
          </ScrollView>
        </div>
        <Template name={'menu'}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          >
          </SideNavigationMenu>
        </Template>
      </Drawer>
    </div>
  );

}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};
