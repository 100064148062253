import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import FileUploader from 'devextreme-react/file-uploader';
import './fleet.scss'
import devices from 'devextreme/core/devices';
import { VehicleCard } from '../../components';
import { NumberBox } from 'devextreme-react/number-box';

import Button from 'devextreme-react/button';
import {Form,
    Label,
    SimpleItem,
    RequiredRule} from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';
import {getResources, updateResource, deleteResource, getUserDetails} from '../../api/api'
import {uploadFileChunk} from '../../api/s3'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

export default function Fleet() {
  const { user, refreshSession } = useAuth();
  const navigate = useNavigate();
  const [fleetList, setFleetList] = useState([]);
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [maxVehicles, setMaxVehicles] = useState(0);

  const [callingAPI, setCallingAPI] = useState(false);
  const fileExtensions = ['.jpg', '.jpeg', '.png'];
  const formRef = useRef(null);
  const onSave = async () => {
    setSaveButtonOptions({
      disabled: true,
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });
    setCallingAPI(true);
    updateResource(user, currentVehicle, onSaveSuccess, onError);
  }

  const onCloseDriverDialog = () => {
    setCurrentVehicle(null);
  }

  const onCancel = () => {
    setCurrentVehicle(null);
  }

  const [cancelButtonOptions, setCancelButtonOptions] = useState({
    disabled: false,
    text: 'Cancel',
    width: '100%',
    onClick: onCancel,
  });

  const [saveButtonOptions, setSaveButtonOptions] = useState({
    disabled: false,
    text: 'Save',
    type: 'default',
    width: '100%',
    onClick: onSave,
  });

  const onError = useCallback((error) => {
    console.log(error);
    setCallingAPI(false);
    if (error.status === 401) {
      refreshSession();
    } else if (error.status === 402) {
      notify("We cannot add the vehicle. Please upgrade your plan to continue", 'error', 5000);
      navigate('/billing');
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onSaveSuccess = useCallback((data) => {
    getResources(user, onGetResourcesSuccess, onError);
    setCurrentVehicle(null);
  }, [])

  const onGetResourcesSuccess = useCallback((data) => {
    let lst = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].resource_type === 'vehicle') {
        if (!(data[i].resource_details.prices)) {
          data[i].resource_details.prices = [];
        }
        lst.push(data[i])
      }
    }
    setCallingAPI(false);
    setFleetList(lst);
  }, [])

  const onUploadChunk = (file, uploadInfo) => {
    return uploadFileChunk(file, uploadInfo, {path:'images/'+currentVehicle.resource_id});
  }

  const onUploaded = (e) => {
    let data = {...currentVehicle};
    if (data === null) {
      data = {}
    }
    data.resource_details.picture = "https://s3.amazonaws.com/com.getcarride/images/"+currentVehicle.resource_id+"/"+encodeURIComponent(e.file.name).replace(/%20/g,'+');
    setCurrentVehicle(data);
  }

  const onGetUserDetailsSuccess = useCallback((data) => {
    if (data.max_number_vehicles === 0) {
      navigate('/billing');
    } else {
      setMaxVehicles(data.max_number_vehicles);
    }
    console.log(data);

  }, [])

  useEffect(() => {
    if (user.signInUserSession) {
      getResources(user, onGetResourcesSuccess, onError);
      getUserDetails(user, onGetUserDetailsSuccess, onError);
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  useEffect(() => {
    if (currentVehicle) {
      setCancelButtonOptions({
        disabled: false,
        text: 'Cancel',
        width: '100%',
        onClick: onCancel,
      });
      setSaveButtonOptions({
        disabled: !(currentVehicle.resource_details.name),
        text: 'Save',
        type: 'default',
        width: '100%',
        onClick: onSave,
      });
    }
  }, [currentVehicle]);

  const maxPassengersOptions = {
    min: 1,
    max: 50,
    step: 0,
  };

  const onVehicleEdit = (vehicle) => {
    setCurrentVehicle(vehicle);
  }

  const onVehicleDelete = (vehicle) => {
    setCallingAPI(true);
    deleteResource(user, vehicle, onSaveSuccess, onError);
  }

  const onVehicleSuspendResume = (vehicle) => {
    if (vehicle.resource_details.active) {
      vehicle.resource_details.active = false;
    } else {
      vehicle.resource_details.active = true;
    }
    setCallingAPI(true);
    updateResource(user, vehicle, onSaveSuccess, onError);
  }


  const onFormDataChanged = useCallback(
    (e) => {
      if (currentVehicle) {
        setSaveButtonOptions({
          disabled: !(currentVehicle.resource_details.name),
        });
      }

    },
    [currentVehicle],
  );

  const onAddVehicle = () => {
    if (fleetList.length >= maxVehicles) {
      notify("We cannot add the vehicle. Please upgrade your plan to continue", 'error', 5000);
      navigate("/billing")
    } else {
      setCurrentVehicle(
        {
          resource_id: crypto.randomUUID(),
          resource_details: {
            max_passengers: 2,
            max_luggages: 2,
            prices: [],
          },
          resource_type: 'vehicle',
        });
    }
  }

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center content-block">
        <h2 className={''}>My Fleet</h2>
        <Button
          type="default"
          text="Add Vehicle"
          onClick={onAddVehicle}
        />
      </div>
      <div className="row">
      { fleetList.map( (vehicle) =>
        <div className="col-12 col-lg-6 col-xl-4 mb-3" key={vehicle.resource_id} >
          <VehicleCard
            vehicle={vehicle}
            onEdit={onVehicleEdit}
            onDelete={onVehicleDelete}
            onSuspendResume={onVehicleSuspendResume}
            loading={callingAPI}
          />
        </div>) }
      </div>

      <Popup
        visible={currentVehicle !== null}
        onHidden={onCloseDriverDialog}
        dragEnabled={false}
        showCloseButton={false}
        showTitle={true}
        fullScreen={devices.current().deviceType === 'phone'}
        title={currentVehicle&&currentVehicle.owner_id?"Edit Vehicle":"Add Vehicle"}
      >
        <Position at="center" my="center"collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={saveButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelButtonOptions}
        />

        {currentVehicle && <div className="">

          <div className={'d-flex flex-column align-items-center'}>
            <div className={'text-center car-image'}>
              {(currentVehicle.resource_details && currentVehicle.resource_details.picture) ? <img
                alt={''}
                src={currentVehicle.resource_details.picture}
              />:
              <img
                alt={''}
                src={'/DefaultCar.png'}
              />}
            </div>
            <div className="d-flex text-center" style={{width: "170px"}}>
              <FileUploader
                selectButtonText="Update Image"
                labelText=""
                multiple={false}
                chunkSize={6000000}
                uploadChunk={onUploadChunk}
                onUploaded={onUploaded}
                allowedFileExtensions={fileExtensions}
              />
            </div>

          </div>
          <Form
            ref={formRef}
            id={'form'}
            formData={currentVehicle}
            labelLocation={'top'}
            onFieldDataChanged={onFormDataChanged}
          >
            <SimpleItem dataField="resource_details.name">
              <Label text="Car Name (Publicly Visible)"/>
              <RequiredRule message="Car name is required" />
            </SimpleItem>
            <SimpleItem dataField="resource_details.max_passengers" editorType="dxNumberBox" editorOptions={maxPassengersOptions}>
              <Label text="Maximum number of passengers" />
            </SimpleItem>
            <SimpleItem dataField="resource_details.max_luggages" editorType="dxNumberBox" editorOptions={maxPassengersOptions}>
              <Label text="Maximum pieces of luggage" />
            </SimpleItem>

          </Form>

        </div>}
      </Popup>

    </React.Fragment>
)}
