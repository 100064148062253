export function customizeNewDate (additionInHours=0) {
    let current = new Date()
    current.setHours(current.getHours()+additionInHours)
    const ct = new Date(current)
    return ct
}

export const parseHours = date => {
    const hours = new Date(date)
    return removeSeconds(hours.toLocaleString()).split(',')[1]
}

export const removeSeconds = (datestr) => {
    let s = datestr.split(":");
    let s2 = s[2]
    s2 = s2.split(" ")[1]
    return s[0]+":"+s[1]+" "+s2
}

export const convertRangeArrToFriendlyStr = arrayWithTwoNumbers => {
    return arrayWithTwoNumbers.toString().replace(',', '-')
}

export const addPlusOnePrefix = phoneNo => {
    let updatedPhoneNo = phoneNo
    if(updatedPhoneNo) {
      if(updatedPhoneNo.length === 10) {
        console.log("has 10 digits")
        updatedPhoneNo = '+1' + updatedPhoneNo
      } else if(updatedPhoneNo.length >= 11) {
        console.log("has more than 10 digits")
        if(!updatedPhoneNo.includes('+')) {
          updatedPhoneNo = '+' + phoneNo
        }
      }

      return updatedPhoneNo
    }
    return false
}