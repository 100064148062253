import React from "react";
import { Button } from 'devextreme-react/button';
import './ScheduleDataTooltip.scss'
import {useNavigate} from 'react-router-dom'
import {appointmentTypes} from '../../api/api'


export default function ScheduleDataTooltip(props) {
    const userData = null;
    const navigate = useNavigate();

    function prettyDate2(time) {
      var date = new Date(time);
      return date.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute:'2-digit'
      });
    }

    const pricing = props.data.appointmentData.pricing || []
    console.log('pricing: ', pricing)

    return (
        <div className="p-2 d-flex flex-column align-items-start justify-content-between schedule-data-tooltip">
          <h5>{props.data.appointmentData.appointment_title}</h5>
          {props.data.appointmentData.appointment_type === 'unavailable' ?
            <p className=""><b>{prettyDate2(props.data.appointmentData.startDate)}</b> - <b>{prettyDate2(props.data.appointmentData.endDate)}</b></p>
          : <>
            <p className=""><i className="fas fa-map-marker-alt pe-1"></i> <b>{prettyDate2(props.data.appointmentData.startDate)}</b> - {props.data.appointmentData.ride_details && props.data.appointmentData.ride_details.start_address}</p>
            <p className=""><i className="fas fa-street-view pe-1"></i><b>{prettyDate2(props.data.appointmentData.pickupDate)}</b> - {props.data.appointmentData.ride_details && props.data.appointmentData.ride_details.pickup_address}</p>
            {props.data.appointmentData.appointment_type === 'ride_full_roundtrip' && <p className=""><i className="fas fa-walking pe-1"></i> <b>{prettyDate2(props.data.appointmentData.destinationDate)}</b> - {props.data.appointmentData.ride_details && props.data.appointmentData.ride_details.destination_address}</p>}
            {props.data.appointmentData.appointment_type === 'ride_full_roundtrip' && <p className=""><i className="fas fa-hourglass-half pe-1"></i> <b>{prettyDate2(props.data.appointmentData.returnDate)}</b> - {props.data.appointmentData.ride_details && props.data.appointmentData.ride_details.destination_address}</p>}

            <p className=""><i className="fas fa-flag-checkered pe-1"></i><b>{prettyDate2(props.data.appointmentData.endDate)}</b> - {props.data.appointmentData.ride_details && props.data.appointmentData.ride_details.end_address}</p>
            {props.data.appointmentData.ride_details && <h5 className="pt-2"><i className="fas fa-users pe-1"></i>{props.data.appointmentData.ride_details.passengers} - <i className="fas fa-baby pe-1"></i>{props.data.appointmentData.ride_details.childern} - <i className="fas fa-suitcase-rolling pe-1"></i>{props.data.appointmentData.ride_details.luggages}</h5>}
            {props.data.appointmentData.ride_details && <p className="pb-2"><i className="fas fa-info-circle pe-1"></i> {props.data.appointmentData.ride_details.notes}</p>}
            {props.data.appointmentData.customer_data && <p className="py-2">
              <i className="far fa-address-card pe-1"></i><b>{props.data.appointmentData.customer_data.name}</b> <span className="mx-2"> - </span> {props.data.appointmentData.customer_data.email} <span className="mx-2"> - </span> {props.data.appointmentData.customer_data.phone} <span className="mx-2"> - </span> {props.data.appointmentData.customer_data.notes}
            </p>}
          </>}
          <div className="d-flex flex-row">
            {['ride_full_arrival_time', 'ride_full_departure_time'].includes(props.data.appointmentData.appointment_type) && <div
              style={{
                backgroundColor: "#fff",
                backgroundImage: `url("${props.data.appointmentData.driver && props.data.appointmentData.driver.resource_details.picture?props.data.appointmentData.driver.resource_details.picture:'/DefaultAvatar.png'}")`,
                backgroundSize: 'cover',
                width: "50px",
                height: "50px",
                borderRadius: "50px",
              }}
            />}
            <div
              className="ms-1"
              style={{
                backgroundColor: "#fff",
                backgroundImage: `url("${props.data.appointmentData.vehicle && props.data.appointmentData.vehicle.resource_details.picture?props.data.appointmentData.vehicle.resource_details.picture:'/DefaultCar.png'}")`,
                backgroundSize: 'contain',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "50px",
                height: "50px",
                borderRadius: "2px",
              }}
            />
          </div>

          {false && props.data.data.resource_type === "vehicle" &&
            <div className="d-flex flex-row align-items-center justify-content-start">
              <div className={'vehicle-card-image-xs'}>
                {(props.data.data.resource_details && props.data.data.resource_details.picture) ? <img
                  alt={''}
                  src={props.data.data.resource_details.picture}
                />:
                <img
                  alt={''}
                  src={'/DefaultCar.png'}
                />}
              </div>
              <p className="ps-1 m-0">{props.data.data.resource_details.name}</p>
            </div>
          }
          { pricing.amount_quoted && (pricing.amount_due || pricing.amount_due === 0) &&
            <div className="pt-3">
              <p>The quoted price was <b>${pricing.amount_quoted}</b>, and the amount due is <b>${pricing.amount_due}</b>.</p>
            </div>
          }
        </div>
    )
}
